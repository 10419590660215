import React, { useContext } from 'react'
import { useParams } from 'react-router-dom';

import { Col, Row, Button, Badge, OverlayTrigger, Tooltip, Spinner, Dropdown } from 'react-bootstrap'

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { USER } from '../constants/omp_ui_text_message';

import { useCustomNavigate } from '../hooks/useCustomNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import "./dataInfo.css"

const DataInfo = ({ isAdminPortal, dsdInfo, dataDict, loggedInUser, updateCart, cart, versionDisplay, setVersionDisplay }) => {
    const params = useParams();
    const navigate = useCustomNavigate();

    const handleDownload = (item) => {
        updateCart(item)
    }

    const handleChangeVersion = (version) => {
        setVersionDisplay(preValue => parseInt(version))
        const data_version_name = params.data_name?.replace(params.data_name.split("_")[params.data_name.split("_").length - 1], "v" + version)

        navigate("/data/" + encodeURIComponent(data_version_name) + "?pageSize=10&climit=5&page=1", {
            state: {
                data: dsdInfo.data,
                dataset: dsdInfo.dataset,
                user: loggedInUser,
                dataVersioned: dsdInfo.dataVersioned,
                versionDisplay: dsdInfo.versionDisplay
            },
        });
    }

    /* Handle download button */
    const DownloadButton = ({ viewing_data }) => {
        if (loggedInUser === null) {
            //No login user            
            return (
                <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">{dataDict?.message}</Tooltip>}>
                    <span className="d-inline-block">
                        <Button
                            style={{ border: "none" }}
                            className="d-flex align-items-center"
                            size="lg"
                            variant="outline-dark"
                        >
                            {/*<img src={prohibited_icon} alt="download prohibited" height="20px" width="20px" />*/}
                            <FontAwesomeIcon size="1x" icon={icon({ name: 'lock' })} />
                        </Button>
                    </span>
                </OverlayTrigger>
            )

        } else if (loggedInUser?.auth === false) {
            //Session expired
            return (
                <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">{loggedInUser?.message}</Tooltip>}>
                    <span className="d-inline-block">
                        <Button
                            style={{ border: "none" }}
                            className="d-flex align-items-center"
                            size="lg"
                            variant="outline-dark"
                            disabled={!loggedInUser?.token}
                        >
                            {/*<img src={prohibited_icon} alt="download prohibited" height="20px" width="20px" />*/}
                            <FontAwesomeIcon size="1x" icon={icon({ name: 'lock' })} />
                        </Button>
                    </span>
                </OverlayTrigger>
            )

        } else if (loggedInUser && !viewing_data[versionDisplay - 1]?.allowed_access && !isAdminPortal) {
            //Access Denied            
            return (
                <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">
                        {viewing_data[versionDisplay - 1]?.message}
                    </Tooltip>}>
                    <span className="d-inline-block">
                        <Button
                            style={{ border: "none" }}
                            className="d-flex align-items-center"
                            size="lg"
                            variant="outline-dark"
                            disabled={!viewing_data[versionDisplay - 1]?.allowed_access}
                            id={viewing_data[versionDisplay - 1]?.data_name}
                        ><FontAwesomeIcon size="1x" icon={icon({ name: 'lock' })} />
                            {/*<img src={prohibited_icon} alt="download prohibited" height="20px" width="20px" />*/}
                        </Button>
                    </span>
                </OverlayTrigger>
            )

        } else if (loggedInUser && (viewing_data[versionDisplay - 1]?.allowed_access || isAdminPortal)) {
            //Show data            
            return (
                <Button
                    style={{ border: "none" }}
                    className="d-flex align-items-center"
                    size="lg"
                    variant=""
                    disabled={!(viewing_data[versionDisplay - 1]?.allowed_access || isAdminPortal)}
                    id={viewing_data[versionDisplay - 1]?.data_name}
                    onClick={() => handleDownload({ ID: viewing_data[versionDisplay - 1]?.data_id, supp_info: viewing_data[versionDisplay - 1] })}
                >
                    {
                        cart.find(cartItem => cartItem.ID === viewing_data[versionDisplay - 1]?.data_id)
                            ? <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">
                                    Remove {viewing_data[versionDisplay - 1]?.data_name} from download cart
                                </Tooltip>}>
                                <FontAwesomeIcon size="lg" icon={icon({ name: 'circle-minus' })} />
                            </OverlayTrigger>
                            : <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">
                                    Add {viewing_data[versionDisplay - 1]?.data_name} from download cart
                                </Tooltip>}>
                                <FontAwesomeIcon size="lg" icon={icon({ name: 'circle-plus' })} />
                            </OverlayTrigger>
                    }
                </Button>
            )
        }
    }

    if ((!!dsdInfo?.dataset) && (dsdInfo?.dataVersioned?.length > 0) && (!!versionDisplay === true)) {
        return (
            <>
                {
                    /* Show data ID and status in admin portal */
                    isAdminPortal
                        ? <>
                            <Row className='mb-2'>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex align-items-center justify-content-sm-start justify-content-md-end' >
                                    <code className='om-red-text'>Dataset:&nbsp;{dsdInfo?.dataset[0]?.title + " (" + dsdInfo?.dataset[0]?.status?.trim() + ")"}</code>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex align-items-center justify-content-sm-start justify-content-md-end' >
                                    <code className='om-red-text'>Data ID:&nbsp;
                                        {dsdInfo.dataVersioned[versionDisplay - 1]?.data_id}&nbsp;({dsdInfo.dataVersioned[versionDisplay - 1]?.status})
                                    </code>
                                </Col>
                            </Row>
                        </>
                        : <></>
                }

                {/* 2024-04-03 Inculded Version in data viewing */}
                <Row>
                    <Col xs={10} sm={11} md={11} lg={11} xl={11} className='d-flex align-items-center justify-content-start dataInfo-title' >
                        {dsdInfo.dataVersioned[versionDisplay - 1]?.title
                            ? dsdInfo.dataVersioned[versionDisplay - 1]?.title
                            : dsdInfo.dataVersioned[versionDisplay - 1]?.data_name}
                    </Col>
                    <Col xs={2} sm={1} md={1} lg={1} xl={1} className='d-flex align-items-center justify-content-end' >
                        <Dropdown onSelect={(e) => handleChangeVersion(e)} className="om-dropdown" align={"end"} >
                            <Dropdown.Toggle
                                disabled={dsdInfo?.dataVersioned?.length === 1}
                                variant="outline-dark"
                                className='border-0 rounded-5'
                                size='sm'
                                style={{ fontSize: "0.9rem" }}
                            >
                                <span>v{dsdInfo?.dataVersioned[versionDisplay - 1]?.version}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu as={"div"} align={"end"} className="om-dropdown text-center">
                                {
                                    dsdInfo?.dataVersioned?.map((d, index) => {
                                        return (
                                            <Dropdown.Item key={index} eventKey={d.version} >
                                                {`v${d.version}`}
                                            </Dropdown.Item>
                                        )
                                    })
                                }
                            </Dropdown.Menu>

                        </Dropdown>
                    </Col>
                </Row >

                <Row className='mb-2'>
                    <Col xs={12} sm={12} md={3} lg={3} xl={3} className='d-flex align-items-center justify-content-start dataInfo-publish-date' >
                        <div >
                            <FontAwesomeIcon size="1x" icon={icon({ name: 'calendar', style: 'regular' })} />
                            &nbsp;<span>{dsdInfo?.dataVersioned[versionDisplay - 1]?.publish_date?.slice(0, 10)}</span>
                        </div>
                    </Col>

                    <Col xs={12} sm={12} md={5} lg={5} xl={5} className='d-flex align-items-center justify-content-start' >
                        <a href={`mailto:${dsdInfo.dataVersioned[versionDisplay - 1]?.contact}`} className='datasetInfo-contact'>
                            <FontAwesomeIcon size="1x" icon={icon({ name: "envelope", style: 'regular' })} />
                            &nbsp;<span>{dsdInfo?.dataVersioned[versionDisplay - 1]?.contact}</span>
                        </a>
                    </Col>
                </Row>

                <Row className='pt-2'>
                    <Col xs={10} sm={10} md={11} lg={11} className='text-start pt-1 pb-1'>
                        {/*Data description*/}
                        <ReactMarkdown
                            className='dataInfo-md-content'
                            remarkPlugins={[remarkGfm]}
                            children={dsdInfo?.dataVersioned[versionDisplay - 1]?.data_desc}
                        >
                        </ReactMarkdown>
                    </Col>

                    <Col xs={2} sm={2} md={1} lg={1} className='d-flex justify-content-center align-items-center'>
                        <DownloadButton viewing_data={dsdInfo.dataVersioned} />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        {
                            dsdInfo?.dataVersioned[versionDisplay - 1]?.tag?.split(",")?.map((t, index) => {
                                return (<Badge className="me-2 mt-1 ps-2 pe-2" key={t + "_" + index} bg="dark">{t}</Badge>)
                            })
                        }
                    </Col>
                </Row>
            </>
        )
    }
    else if (((!!dsdInfo?.dataset === false) || (dsdInfo?.dataVersioned?.length <= 0) || (!!versionDisplay === false))) {
        return (

            <>
                <div className="d-flex justify-content-center" style={{ marginTop: "50px" }}>
                    <Spinner animation="border" role="status" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </>

        )
    } else {
        return (<></>)
    }
}

export default DataInfo