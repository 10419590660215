// useCustomNavigate.js
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { contextIsAdminPortal, contextPortalUser } from '../App.js'

export function useCustomNavigate() {
    const navigate = useNavigate();
    const location = useLocation();

    const [isAdminPortal] = useContext(contextIsAdminPortal);
    const [portalUser] = useContext(contextPortalUser);

    return (path, options) => {
        if (typeof path === 'number') { //handle -1 as goBack
            navigate(path);
        } else if (isAdminPortal && portalUser?.role?.includes("admin") && location.pathname.includes('/admin')) {
            navigate('/admin' + path, options);
        } else {
            path.replace("/admin", "");
            navigate(path, options);
        }
    };
}