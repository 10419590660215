/* Page : About */
export const about_text = "Our mission is to empower science for improving global health with the world's information on mortality - helping the living study the dead.\n\n### Closing the Global Mortality Divide\n\nThe causes for a majority of deaths around the world are unknown. These deaths occur at home without medical attention. We collect and release data on deaths outside medical settings to improve measures of mortality - providing the foundation for large-scale evidence-based decisions and policies.\n\n### Building Healthier Futures\n\nDeath in old age is inevitable - death before old age is not. Premature deaths worldwide are preventable with lifestyle changes, treatment, and better access to healthcare. The Open Mortality project provides universal and accessible digital tools that enable doctors, scientists, researchers, and even the public, to uncover innovative interventions for reducing premature deaths and improving the quality of life for everyone.\n\n### Making it Happen\n\nThe Open Mortality project is led by the Centre for Global Health Research (CGHR) at Unity Health Toronto and the University of Toronto. It is currently released as a web prototype to a select community of users for pilot testing. We have plans to release Open Mortality to academic and research institutions, collaborators, partners, and eventually, the world.\n\n";

/* Page : View Dataset By Name  */
export const no_data_in_dataset = ""
export const no_dataset_detail = "No Dataset detail"

/* Component : Dataset card*/
export const input_msg_ds_title = "Please enter title";
export const input_msg_ds_title_abbr = "Please enter title abbreivation";
export const input_msg_ds_publish_date = "Please enter publish date";
export const input_msg_ds_description = "Please enter description";

/* Component : Data card*/
export const input_msg_d_tag = "Please enter tag";
export const input_msg_d_title = "Please enter title";
export const input_msg_d_description = "Please enter description";
export const input_msg_d_permssion = "Please enter permission";
export const input_msg_d_publish_date = "Please enter publish date";
export const input_msg_d_contact = "Please enter contact";
export const upload_file_msg = "Please upload file"
export const status_error_msg = "Please select status"
export const category_error_msg = "Please select category"
