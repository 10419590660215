import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import { useCustomNavigate } from '../hooks/useCustomNavigate';

//import OmLogoStatic from '../Assets/images/OM_Logo_Cropped_Static.png';
import OmLogoStatic from '../Assets/images/openmortality-logo.svg';

const Header = () => {
    const navigate = useCustomNavigate();

    const handleClick = () => {
        navigate("/")
    }

    return (
        <>
            <Row className="d-flex justify-content-center align-items-center">
                <Col className="d-flex mb-3 mt-3" xs={6} sm={5} md={4} lg={3} xl={2}>
                    <img className="img-fluid mx-auto d-block" src={OmLogoStatic} style={{ cursor: "pointer" }} onClick={() => handleClick()} />
                </Col>
            </Row>
        </>
    )
}

export default Header;
