//constants 
module.exports = {
    /* Data publish status */
    DATA_STATUS: {
        PUBLISHED: 'published',
        DRAFT: "draft"
    },

    USER_STATUS: {
        ACTIVE: 'active',
        PENDING: 'pending',
        DISABLED: 'disabled'
    },

    /* User permission */
    PERMISSION: {
        ADMINISTRATOR: 'admin',
        USER: 'user',
        POWER_USER: 'pwuser',
        GUEST: 'guest'
    },

    /* data upload folder */
    DATA_UPLOAD_FOLDERS: {
        METADATA: 'metadata',
        DATA_DICTIONARY: 'dictionary',
        DATA: 'data'
    },

    DATA_DOWNLOAD: {
        REFRESH_INTERVAL: 2000
    }
};