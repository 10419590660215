//Landing page of the OMP
import React, { useEffect, useContext, useState } from 'react'

import { Col, Row, Container, Button, Placeholder } from 'react-bootstrap';


import OmLogoAnimated from '../Assets/images/OM_Logo_Cghr_Web.gif';
import MainLogoAnimated from '../components/MainLogoAnimated'
import OmpSlogan from '../components/OmpSlogan'
import SubFooter from '../components/SubFooter';
import ExploreOurDataset from '../components/ExploreOurDataset';
import TsParticles from "../components/ParticlesBackground";

import { api_config } from "../components/api_url";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useCustomNavigate } from '../hooks/useCustomNavigate';
import { contextPortalUser } from '../App';

import '../components/MainLogoAnimated.css';

const api_url = api_config.url.API_URL

const Home = () => {
  const [portalUser, setPortalUser] = useContext(contextPortalUser)

  const [gifSize, setGifSize] = useState({ width: 0, height: 0 });
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useCustomNavigate();

  const handleGoToAbout = () => {
    navigate('/about')
  }

  const handleGoToTeam = () => {
    navigate('/team')
  }

  const handleExplore = () => {
    if (!!portalUser?.uid === true) {
      navigate("/dataset")
    } else
      navigate("/login")
  }

  //Set metadata, header
  useEffect(() => {
    //Include Google Analytics Tag
    const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
    // Google Analytics tracking code
    const head = document.querySelector("head");
    const script1 = document.createElement("script");
    script1.async = true;
    script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
    head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerText =
      `window.dataLayer = window.dataLayer || [];\
          function gtag(){dataLayer.push(arguments);}\
          gtag('js', new Date()); \
          gtag('config', '${trackingID}');\
          gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
    head.appendChild(script2);

    // Update the document's meta tags when the component mounts
    document.title = 'Open Mortality | Help the Living, Study the Dead';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - Home');

    // Clean up the meta tag modifications when the component unmounts
    return () => {
      document.title = '';
      document.querySelector('meta[name="description"]').setAttribute('content', '');
      head.removeChild(script1);
      head.removeChild(script2);
    };
  }, []);

  useEffect(() => {
    const img = new Image();
    img.src = OmLogoAnimated;
    img.onload = () => {
      setGifSize({ width: img.naturalWidth, height: img.naturalHeight });
      setIsLoading(false);
    }
  }, [])

  return (
    <>
      <TsParticles />

      <Container fluid className='d-flex flex-column justify-content-between fade-in' style={{ height: "100%", width: "auto", zIndex: 3 }}>

        {isLoading === true
          ? <>
            <Placeholder sm={12} md={5} lg={3} xl={3} alt="OMP Logo" bg="light" animation="glow" className="about-button" />
            {/* Main content */}
            <Row className='m-0 p-2 d-flex justify-content-center align-items-center' style={{ zIndex: 3 }}>
              <Placeholder sm={12} md={5} lg={3} xl={3} size="xs" animation='wave' bg='light' className='me-2' style={{ width: "350px", height: "80px" }} ></Placeholder>
              <Placeholder sm={12} md={5} lg={3} xl={3} size="xs" animation='wave' bg='light' className='me-2' style={{ width: "350px", height: "80px" }} ></Placeholder>
              <Placeholder sm={12} md={5} lg={3} xl={3} size="xs" animation='wave' bg='light' className='me-2' style={{ width: "350px", height: "80px" }} ></Placeholder>
            </Row>
          </>
          : <>
            <Row className="mt-3 ps-3 d-flex align-items-start justify-content-start" style={{ zIndex: 3 }}>
              <Col className="d-flex justify-content-start" >
                <a onClick={handleGoToAbout} className='about-button about-button-hover-effect' style={{ cursor: 'pointer' }}>About</a>
                <a onClick={handleGoToTeam} className='about-button about-button-hover-effect' style={{ cursor: 'pointer' }}>Team</a>
              </Col>
            </Row>

            {/* Main content */}
            <Row className='m-0 p-2 d-flex justify-content-center align-items-center' style={{ zIndex: 3 }}>
              <Col as="div" xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex justify-content-center'>
                <img alt="OMP Logo" className="animated-logo-main img-fluid" src={OmLogoAnimated} />
              </Col>

              <Col classname="" xs={12} sm={12} md={12} lg={12} xl={12}>
                <h4 className="p-2 m-0 slogan-font slogan">
                  <span className="slogan-color1">Help the living</span>
                  <span className="slogan-color2"> Study the dead</span>
                </h4>
              </Col>

              <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex justify-content-center">
                <Button
                  variant='dark'
                  className="explore-dataset red-gradient-hover-effect"
                  id="button-explore-dataset"
                  onClick={handleExplore}
                >
                  <div className='m-1 d-flex align-items-center explore-dataset-button'>
                    <span className='m-2'>Explore Our Datasets </span>
                    <FontAwesomeIcon className='fa-flip-horizontal me-2' size="1x" icon={icon({ name: 'magnifying-glass' })} />
                  </div>
                </Button>
              </Col>
            </Row>

            {/* Footer */}
            <Row style={{ zIndex: 3 }}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <SubFooter />
              </Col>
            </Row>

          </>
        }
      </Container>
    </>
  )
}

export default Home