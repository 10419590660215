import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form"
import MainLogoStatic from '../components/MainLogoStatic'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { PASSWORD_REGEX } from '../constants/regex';

import { api_config } from "../components/api_url";

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useCustomNavigate } from '../hooks/useCustomNavigate';

const ResetPassword = () => {
  //Import API URL
  const api_url = api_config.url.API_URL

  const [message, setMessage] = useState("");
  const [resetPasswordCheck, setresetPasswordCheck] = useState("");

  const [resetDone, setResetDone] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [validated, setValidated] = useState(false);

  const navigate = useCustomNavigate();
  const search = useLocation().search;

  const passwordInputElement = useRef();
  const password2InputElement = useRef();

  useEffect(() => {
    //Include Google Analytics Tag
    const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
    // Google Analytics tracking code
    const head = document.querySelector("head");
    const script1 = document.createElement("script");
    script1.async = true;
    script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
    head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerText =
      `window.dataLayer = window.dataLayer || [];\
          function gtag(){dataLayer.push(arguments);}\
          gtag('js', new Date()); \
          gtag('config', '${trackingID}');\
          gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
    head.appendChild(script2);

    // Update the document's meta tags when the component mounts
    document.title = 'Open Mortality | Reset Password';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality | Reset Password');

    // Clean up the meta tag modifications when the component unmounts
    return () => {
      document.title = '';
      document.querySelector('meta[name="description"]').setAttribute('content', '');
      head.removeChild(script1);
      head.removeChild(script2);
    };
  }, []);

  const handleGoBack = () => {
    navigate("/")
  }

  const ResetPassword = () => {
    navigate("/forgetpassword")
  }

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null
      })
  }
  const validateUserPassword = () => {
    console.log(passwordInputElement.current?.value, password2InputElement.current?.value)

    const newErrors = {}

    if (!passwordInputElement.current?.value || passwordInputElement.current?.value === '') newErrors.password = 'Please enter valid password'
    else if (!PASSWORD_REGEX.test(passwordInputElement.current?.value)) newErrors.password = 'Please enter valid password'

    if (!password2InputElement.current?.value || password2InputElement.current?.value === '') newErrors.password2 = 'Please enter valid password for confirmation'
    else if (!PASSWORD_REGEX.test(password2InputElement.current?.value)) newErrors.password2 = 'Please enter valid password for confirmation'
    else if (passwordInputElement.current?.value !== password2InputElement.current?.value) newErrors.password2 = 'Password not match'

    return newErrors
  }

  const clearError = () => {
    setMessage("")
    setErrors("")
    setValidated()
  }
  const handleInput = (e, key) => {
    setField(key, e.target.value)
  }
  const resetPassword = (updating_form) => {
    fetch(api_url + "/user/resetpassword", {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updating_form),
    })
      .then((res) => res.json())
      .then(result => {
        //console.log(result)        
        setMessage(result.pw_reset_req ? "Password reset successfully - redirecting to home page..." : "Password reset fail")
        setResetDone(result.pw_reset_req)
      }
      )
      .catch((err) => {
        setMessage("Error on user information updating - " + err)
      })

  }

  //Check request and get password change token
  const passwordResetReqCheck = (code) => {
    return new Promise((resolve, reject) => {
      try {
        fetch(api_url + "/user/resetpasswordcheck?code=" + code, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          }
        })
          .then((res) => res.json())
          .then((data) => {
            if (!data.pw_reset_req_check) {
              setMessage("There is an error in resetting your password. Please try resetting your password again.")
            } else {
              setresetPasswordCheck(data)
            }
            resolve(data)

          })
          .catch((err) => {
            reject(err)
          });
      }
      catch (err) {
        reject(err)
      }
    })
  }

  const processPasswordUpdate = (e) => {
    setMessage("")
    e.preventDefault();
    e.stopPropagation();

    const formsErrors = validateUserPassword()

    if (Object.keys(formsErrors).length > 0) {
      setErrors(formsErrors)
    }
    else {
      resetPassword({ password: form.password, change_code: resetPasswordCheck.change_code })

      setTimeout(() => {
        navigate("/")
      }, 3000)
    }
  }

  useEffect(() => {
    const code = new URLSearchParams(search).get('code');
    passwordResetReqCheck(code)
  }, [search])

  return (
    <>
      {(() => {
        if (resetPasswordCheck.pw_reset_req_check === true) {
          return (
            <>
              <div className="d-flex align-items-center" style={{ height: "80vh" }}>
                <Row className='d-flex align-items-center justify-content-center'>
                  <MainLogoStatic />
                  <Form as={Row} className="justify-content-center" validated={validated} noValidate>
                    <Col xs={10} sm={10} md={10} lg={10} xl={10} >

                      <Row className="text-center">
                        <Col as="h4" >Reset Password</Col>
                      </Row>

                      <Form.Group as={Row} controlId="password" className='justify-content-center mt-1'>
                        <Form.Label size="sm" column xs={10} sm={10} md={4} lg={3} xl={3} className="d-flex justify-content-md-end justify-content-sm-start" >New Password</Form.Label>

                        <Col xs={10} sm={10} md={5} lg={4} xl={4} className="justify-content-start">
                          <Form.Control
                            size="sm"
                            ref={passwordInputElement}
                            type="password"
                            name="password"
                            value={form.password}
                            isInvalid={!!errors.password}
                            onFocus={clearError}
                            onChange={e => handleInput(e, "password")}
                            required
                          />

                          <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} controlId="password2" className='justify-content-center mt-1'>

                        <Form.Label column size="sm" xs={10} sm={10} md={4} lg={3} xl={3} className="d-flex justify-content-md-end justify-content-sm-start">Confirm New Password</Form.Label>

                        <Col xs={10} sm={10} md={5} lg={4} xl={4} >
                          <Form.Control
                            size="sm"
                            ref={password2InputElement}
                            type="password"
                            name="password2"
                            value={form.password2}
                            isInvalid={!!errors.password2}
                            onFocus={clearError}
                            onChange={e => handleInput(e, "password2")}
                            required
                          />

                          <Form.Control.Feedback type="invalid">{errors.password2}</Form.Control.Feedback>
                        </Col>
                      </Form.Group>

                      <Form.Text as={Row} id="passwordHelpBlock" muted className="text-center justify-content-center mt-2">
                        <Col xs={10} sm={10} md={8} lg={7} xl={6} size="xs" style={{ color: "black", fontSize: "small" }}>
                          Your password must be 8-20 characters long and contains at least one uppercase letter, one lowercase letter, and one number.
                          You may also include one of the following symbols: <span style={{ color: "blue" }}>!@#$%^&*</span><br />Spaces, special characters, and emojis are not allowed.
                        </Col>
                      </Form.Text>

                      <Row className='mt-2 mb-2 justify-content-center'>
                        <Col xs={10} sm={10} md={11} lg={11} xl={11} className="d-flex justify-content-center">
                          <Button className="om-button" disabled={resetDone} variant="dark" onClick={e => processPasswordUpdate(e)}>Reset</Button>
                        </Col>

                      </Row>

                      <Form.Text as={Row}>
                        <Col className="d-flex text-center mt-2 mb-5 justify-content-center">
                          {message}
                        </Col>
                      </Form.Text>
                    </Col>
                  </Form>
                </Row>
              </div>
            </>)
        }
        else {
          return (
            <>
              <div className="d-flex justify-content-center align-items-center" style={{ height: "80vh" }}>
                <Row className='d-flex align-items-center justify-content-center'>
                  <MainLogoStatic />
                  <Col xs={10} sm={10} md={10} lg={10} xl={10} className='text-center d-flex justify-content-center align-items-center p-2' >
                    <div className='d-flex align-items-center'>
                      <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-exclamation' })} />
                      <span className='font-weight-bold ms-3' style={{ fontSize: "1.5em" }}>
                        <strong>Reset Password Error</strong>
                      </span>
                    </div>
                  </Col>

                  <Form.Text as={Row} className='d-flex justify-content-center m-2'>
                    <Col xs={10} sm={10} md={10} lg={10} xl={10} className='d-flex justify-content-center text-center mt-1' style={{ fontSize: "1.1em" }}>
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        children={message}
                      />
                    </Col>
                  </Form.Text>

                  <Row className='d-flex justify-content-center '>
                    <Col xs={10} sm={10} md={4} lg={3} xl={2} className='d-flex justify-content-center mt-2'>
                      <Button className="om-button" variant="dark" onClick={ResetPassword}>Reset Password</Button>
                    </Col>
                  </Row>

                  <div className="text-center mt-5">
                    <hr style={{ width: "85%", margin: "0 auto" }} />
                  </div>

                  <div className="d-flex justify-content-center align-items-center" style={{ fontSize: "0.9em" }}>
                    <Button size="sm" className="p-1 m-1 om-link" variant="link" onClick={handleGoBack} style={{ textDecoration: "none" }}>Back</Button>
                  </div>
                </Row>
              </div>
            </>
          )
        }
      })()}
    </>
  )
}

export default ResetPassword