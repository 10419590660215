import React, { useEffect, useState } from 'react'

import { Col, Row, Placeholder } from 'react-bootstrap';

import UOfTLogo from '../Assets/images/logo-uoft-resized.png'
import StMichaelsUTLogo from '../Assets/images/logo-uht-resized.png'
import CghrLogo from '../Assets/images/logo-cghr-resized.png'

import './SubFooter.css';


const Footer = () => {

  const [isUOfTLoading, setUOfTLoading] = useState(true);
  const [isStMichaelsUTLoading, setStMichaelsUTLoading] = useState(true);
  const [isCGHRLoading, setCGHRLoading] = useState(true);

  useEffect(() => {
    const imgUOfTLogo = new Image();
    imgUOfTLogo.src = UOfTLogo;
    imgUOfTLogo.onload = () => {
      setUOfTLoading(false);
    }

    const imgStMichaelsUTLogo = new Image();
    imgStMichaelsUTLogo.src = StMichaelsUTLogo;
    imgStMichaelsUTLogo.onload = () => {
      setStMichaelsUTLoading(false);
    }

    const imgCghrLogo = new Image();
    imgCghrLogo.src = CghrLogo;
    imgCghrLogo.onload = () => {
      setCGHRLoading(false);
    }
  }, [])

  return (
    <>
      {isUOfTLoading || isStMichaelsUTLoading || isCGHRLoading
        ? <>
          <Row className="mt-1 mb-1 justify-content-center align-items-end">
            <Placeholder sm={12} md={5} lg={3} xl={3} size="xs" animation='wave' bg='light' className='me-2' style={{ width: "350px", height: "80px" }} ></Placeholder>
            <Placeholder sm={12} md={5} lg={3} xl={3} size="xs" animation='wave' bg='light' className='ms-1 me-1' style={{ width: "340px", height: "80px" }} ></Placeholder>
            <Placeholder sm={12} md={10} lg={3} xl={3} size="xs" animation='wave' bg='light' className='ms-2' style={{ width: "350px", height: "80px" }} ></Placeholder>
          </Row>
        </>
        : <>
          <Row className="mt-1 mb-1 justify-content-center fade-in">
            <Col sm={12} md={5} lg={3} xl={3} className='d-flex align-items-center justify-content-center' >
              <a href='https://www.utoronto.ca/' target="_blank" rel="noreferrer">
                <img className="sub-footer-uoft-logo" src={UOfTLogo} alt="university of Toronto" />
              </a >
            </Col >

            <Col sm={12} md={5} lg={3} xl={3} className='d-flex align-items-center justify-content-center'>
              <a href='https://unityhealth.to/' target="_blank" rel="noreferrer">
                <img className="sub-footer-uht-logo" src={StMichaelsUTLogo} alt="St Michael's UnityHealth Logo" />
              </a>
            </Col>

            <Col sm={12} md={10} lg={3} xl={3} className='d-flex align-items-center justify-content-center mt-md-3'>
              <a href='https://www.cghr.org/' target="_blank" rel="noreferrer">
                <img className='sub-footer-cghr-logo' src={CghrLogo} alt="Centre for Global Health Research" />
              </a>
            </Col>
          </Row >
        </>
      }
    </>
  )
}

export default Footer