let prod = {}
let dev = {}
let dev_local = {}

// If the port is 3002 or URL is admin.openmortality.org or adev.openmortality.org
if (window.location.href.includes('/admin')) {
    prod = {
        url: {
            API_URL: 'https://openmortality.org/adminapi'
        }
    };

    dev = {
        url: {
            API_URL: 'https://dev.openmortality.org/adminapi'
        }
    };

    dev_local = {
        url: {
            API_URL: 'http://localhost:3030'
        }
    };
}
else {
    prod = {
        url: {
            API_URL: 'https://openmortality.org/api'
        }
    };

    dev = {
        url: {
            API_URL: 'https://dev.openmortality.org/api'
        }
    };

    dev_local = {
        url: {
            API_URL: 'http://localhost:3000'
        }
    };
}

export const api_config =
    process.env.NODE_ENV === "development"
        ? dev_local
        : process.env.REACT_APP_DEV_ENVIRONMENT === "staging"
            ? dev
            : prod;