import React, { useState, useRef, useContext, useEffect, useCallback, version } from "react";
import { useLocation } from "react-router-dom";
import { Col, Row, Button, OverlayTrigger, Tooltip, Card, Stack, Badge, Form, Spinner, Dropdown, Modal, Table } from "react-bootstrap";

import { permission_admin } from '../constants/omp_constant.js';

import { USER } from '../constants/omp_ui_text_message';

import { input_msg_d_title, input_msg_d_description, input_msg_d_permssion, input_msg_d_publish_date, input_msg_d_tag, input_msg_d_contact, upload_file_msg, category_error_msg, status_error_msg } from "../constants/omp_ui_text.js";

import { TEMP_EDIT_ID_REGEX } from "../constants/regex";

import { contextIsAdminPortal } from '../App.js'
import { useCustomNavigate } from '../hooks/useCustomNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
//import { toast } from "react-toastify";

import meta_data_csv_template from '../Assets/data_upload_templates/metadata_template.csv';
import data_dict_csv_template from '../Assets/data_upload_templates/ddict_template.csv';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const DataCard = ({ data, dataset, data_meta, handleDownload, cart, setCart, accessToken, loggedInUser,
    setIsEditing, isEditing, editdid, set_edit_session_id, edit_session_id, batchUploadFiles, uploadResponse,
    handleSaveData, handleEditData, handleCancelEditData, handleDeleteData, uploading, editversion, loading }) => {

    const [isAdminPortal] = useContext(contextIsAdminPortal);
    const navigate = useCustomNavigate();
    const location = useLocation();

    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);

    const [dataFile, setDataFile] = useState();
    const [dataDict, setDataDict] = useState();

    const [metadataFile, setMetadataFile] = useState();

    const [d_category, set_d_category] = useState();
    const [d_status, set_d_status] = useState();
    //parseInt(data?.find(d => d.version === data.length)?.version)
    const [versionDisplay, setVersionDisplay] = useState();
    const [versionEdit, setVersionEdit] = useState();

    const [showVersionHistoryModal, setShowVersionHistoryModal] = useState(false);

    const d_publish_date_input_element = useRef(null);
    const d_contact_input_element = useRef(null);
    const d_tag_input_element = useRef(null);
    const d_title_input_element = useRef(null);
    const d_desc_input_element = useRef(null);
    const d_permission_input_element = useRef();
    const d_data_file_sel_element = useRef(null);
    const d_data_dict_sel_element = useRef(null);
    const d_meta_data_file_sel_element = useRef(null);

    const d_upload_overwrite_element = useRef(null);

    const [d_row, set_d_row] = useState();
    const [d_col, set_d_col] = useState();
    const [d_data_name, set_d_data_name] = useState();
    const [d_custom_data_name, set_d_custom_data_name] = useState(null);
    const [d_format, set_d_format] = useState();
    const [d_is_spatial, set_d_is_spatial] = useState(false);

    const validateForm = () => {
        const newErrors = {}
        if (!uploadResponse && !dataFile && !dataDict) newErrors.uploadfiles = upload_file_msg
        if (d_category === 'category') newErrors.category = category_error_msg
        if (d_status === 'status') newErrors.status = status_error_msg
        if (!d_title_input_element.current?.value || d_title_input_element.current?.value === '') newErrors.title = input_msg_d_title
        if (!d_desc_input_element.current?.value || d_desc_input_element.current?.value === '') newErrors.data_desc = input_msg_d_description
        if (!d_permission_input_element.current?.value || d_permission_input_element.current?.value === '') newErrors.permission = input_msg_d_permssion
        if (!d_tag_input_element.current?.value || d_tag_input_element.current?.value === '') newErrors.tag = input_msg_d_tag
        if (!d_publish_date_input_element.current?.value || d_publish_date_input_element.current?.value === '') newErrors.publish_date = input_msg_d_publish_date
        if (!d_contact_input_element.current?.value || d_contact_input_element.current?.value === '') newErrors.contact = input_msg_d_contact
        if (Object.keys(newErrors).length > 0) {
            console.log("newErrors", newErrors)
            setErrors(newErrors)
            setValidated(false)
        } else {
            handleSaveData(
                data[editversion - 1].data_id,
                d_tag_input_element.current?.value,
                d_desc_input_element.current?.value,
                data[editversion - 1]?.data_name ? data[editversion - 1].data_name : uploadResponse?.status ? d_data_name : "",
                d_custom_data_name,
                d_is_spatial,
                d_format,
                d_permission_input_element.current?.value,
                d_publish_date_input_element.current?.value,
                d_status,
                d_contact_input_element.current?.value,
                d_category,
                d_title_input_element.current?.value,
                d_col,
                d_row
            )
        }
    }

    const clearError = () => {
        setErrors("")
        setValidated()
    }

    const handleDataNameChange = (e) => {
        set_d_custom_data_name(e.target.value)
    }

    const handleCategoryChange = (e) => {
        clearError()
        set_d_category(e.target.value)
    }

    const handleStatusChange = (e) => {
        clearError()
        set_d_status(e.target.value)
    }

    const handleDataDictChange = (e) => {
        setDataDict(e.target.files[0]);
    }

    const handleDataFileChange = (e) => {
        if (e.target.files.length === 1) {
            setDataFile(e.target.files[0]);
        } else {
            setDataFile(e.target.files);
        }
    }

    const handleMetaDataFileChange = (e) => {
        setMetadataFile(e.target.files[0]);
    }

    const handleNextSave = (e) => {
        validateForm()
    }

    const setTodayasPublishDate = () => {
        const today = new Date(Date.now());
        const current_date_time =
            today.getFullYear().toString() + "-" +
            (today.getMonth() + 1).toString().padStart(2, '0') + "-" +
            today.getDate().toString().padStart(2, '0');
        d_publish_date_input_element.current.value = current_date_time
    }

    const handleUpload = async () => {
        batchUploadFiles(metadataFile, dataDict, dataFile, data[versionDisplay - 1]?.data_id, edit_session_id, d_upload_overwrite_element?.current.checked)
            .then((result => {
                //console.log(data)                
            }))
            .catch(err => {
                return
            })
    }

    //Single file
    const handleReUpload = async () => {

    }

    const handleViewPreviousVersion = useCallback((version) => {
        setVersionDisplay(preValue => parseInt(version))
    }, [setVersionDisplay])

    const viewDataDetail = async (data_viewing) => {
        //Handle the data version might not be correct e.g. Only one version exist, but the version put is 2
        setVersionDisplay(preValue => { return parseInt(data_viewing?.dataVersioned?.length < data_viewing?.versionDisplay ? data_viewing?.dataVersioned?.length : data_viewing?.versionDisplay) })
        navigate("/data/" + data_viewing?.dataVersioned[data_viewing?.dataVersioned?.length < data_viewing?.versionDisplay ? data_viewing?.dataVersioned?.length - 1 : data_viewing?.versionDisplay - 1]?.data_name + "?pageSize=10&climit=5&page=1", {
            state: {
                data: data_viewing?.data,
                dataVersioned: data_viewing?.dataVersioned,
                versionDisplay: data_viewing?.dataVersioned?.length < data_viewing?.versionDisplay ? data_viewing?.dataVersioned?.length : data_viewing?.versionDisplay,
                dataset: location.state?.dataset ? location.state?.dataset : dataset,
                user: loggedInUser
            },
        });
    };

    /* Download button rendering */
    const DownloadButton = () => {
        if (!loggedInUser) {
            return (
                <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">{USER.login_reminder}</Tooltip>}>
                    <span className="d-inline-block">
                        <Button
                            className="add-to-cart-button"
                            id={data[versionDisplay - 1]?.data_name}
                            disabled={!loggedInUser}
                        >
                            <FontAwesomeIcon size="1x" icon={icon({ name: 'lock' })} />
                        </Button>
                    </span>
                </OverlayTrigger>
            )
        }
        else if (loggedInUser && !accessToken.token) {
            return (
                <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">{accessToken.message}</Tooltip>}>
                    <span className="d-inline-block">
                        <Button className="add-to-cart-button"
                            variant="outline secondary"
                            id={data[versionDisplay - 1]?.data_name}
                            disabled={!accessToken.token}>
                            <FontAwesomeIcon size="1x" icon={icon({ name: 'lock' })} />
                        </Button>
                    </span>
                </OverlayTrigger>
            )
        }
        else if (loggedInUser && !data[versionDisplay - 1]?.allowed_access && !isAdminPortal) {
            return (
                <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">
                        {data[versionDisplay - 1]?.message}
                    </Tooltip>}>
                    <span className="d-inline-block">
                        <Button
                            className="add-to-cart-button"
                            id={data[versionDisplay - 1]?.data_name}
                            disabled={!data[versionDisplay - 1]?.allowed_access}
                        >
                            <FontAwesomeIcon size="1x" icon={icon({ name: 'lock' })} />
                        </Button>
                    </span>
                </OverlayTrigger>
            )
        }
        else if (loggedInUser && (data[versionDisplay - 1]?.allowed_access || isAdminPortal)) {
            return (
                <Button
                    className="add-to-cart-button"
                    id={data[versionDisplay - 1]?.data_name}
                    onClick={() => handleDownload({ ID: data[versionDisplay - 1]?.data_id, supp_info: data[versionDisplay - 1] })}
                    disabled={!(data[versionDisplay - 1]?.allowed_access || isAdminPortal)}>{
                        cart.find(cartItem => cartItem.ID === data[versionDisplay - 1]?.data_id)
                            ?
                            <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">
                                    Remove {data[versionDisplay - 1]?.data_name} from download cart
                                </Tooltip>}>
                                <FontAwesomeIcon size="lg" icon={icon({ name: 'circle-minus' })} />
                            </OverlayTrigger>
                            : <OverlayTrigger
                                overlay={<Tooltip id="tooltip-disabled">
                                    Add {data[versionDisplay - 1]?.data_name} to download cart
                                </Tooltip>}>
                                <FontAwesomeIcon size="lg" icon={icon({ name: 'circle-plus' })} />
                            </OverlayTrigger>
                    }
                </Button>
            )
        }
        else {
            return (
                <></>
            )
        }
    }

    useEffect(() => {
        //Set version, If more than 1 version
        if (data.length === 1) {
            setVersionDisplay(parseInt(data?.find(d => d.version === data.length)?.version) || 1)
            //If there is more than 1 verison, display the latest version
        } else {
            setVersionDisplay(parseInt(data?.find(d => d.version === data.length)?.version))
        }

        if (isAdminPortal) {
            if (isEditing && (editversion !== null || editversion !== undefined)) {
                setVersionEdit(parseInt(editversion))
                set_d_category(data[editversion - 1]?.category ? data[editversion - 1]?.category : 'category')
                set_d_status(data[editversion - 1]?.status ? data[editversion - 1]?.status : 'status')
                setDataFile(data[editversion - 1]?.data_name ? data[editversion - 1]?.data_name + '.csv' : null)
                setDataDict(data[editversion - 1]?.data_name ? data[editversion - 1]?.data_name + '_datadict.csv' : null)
            }
            else {
                set_d_category(data[versionDisplay - 1]?.category ? data[versionDisplay - 1]?.category : 'category')
                set_d_status(data[versionDisplay - 1]?.status ? data[versionDisplay - 1]?.status : 'status')
                setDataFile(data[versionDisplay - 1]?.data_name ? data[versionDisplay - 1]?.data_name + '.csv' : null)
                setDataDict(data[versionDisplay - 1]?.data_name ? data[versionDisplay - 1]?.data_name + '_datadict.csv' : null)
            }
        } else
            return
    }, [])

    /* Case 1 - Admin - Edit existing data */
    /* Case 2 - Admin - Add New data (batch upload) */
    /* Case 3 - Admin/User - Display */
    if (isAdminPortal && loggedInUser?.role.split(",")?.includes(permission_admin) && isEditing && editdid === data[editversion - 1]?.data_id &&
        !!editdid === true && typeof (versionEdit) === 'number' && TEMP_EDIT_ID_REGEX.test(data[versionEdit - 1]?.data_id.toString()) === false) {
        /*DataCard Case 1 - Admin - Edit existing data*/
        return (
            <>
                <Card key={data[editversion - 1]?.data_id} className="ms-0 me-0 mt-3 mb-3 p-0 data-listing-card shadow-sm" >
                    <Card.Body className="data-listing-card-body align-items-center">
                        <Row className="mt-4 mb-2 ms-0 me-0 p-0 d-flex align-items-center justify-content-start" >
                            {/* Data Name */}
                            <Col xs={5} sm={5} md={5} lg={5} xl={5} className="d-flex justify-content-start">
                                <Form.Label><strong>Data Name (Table & File Name)</strong></Form.Label>
                            </Col>

                            <Col xs={5} sm={5} md={5} lg={5} xl={5} className="d-flex justify-content-start">
                                <Form.Control
                                    required
                                    disabled={TEMP_EDIT_ID_REGEX.test(data[versionEdit - 1]?.data_id.toString()) === true && !!uploadResponse?.status === false}
                                    size="sm"
                                    type="text"
                                    placeholder="Data Name (File name/Table name)"
                                    name="data_name"
                                    isInvalid={!!errors.title}
                                    onFocus={clearError}
                                    onChange={(e) => handleDataNameChange(e)}
                                    defaultValue={data[versionEdit - 1]?.data_name ? data[versionEdit - 1]?.data_name : ""}
                                    //value={data?.data_name ? data.data_name : uploadResponse?.status ? `${d_custom_data_name}` : ""}
                                    value={d_custom_data_name}
                                />
                            </Col>

                            {/* Version */}
                            <Col xs={2} sm={2} md={2} lg={2} xl={2} className="d-flex align-items-center justify-content-end" >
                                <span className="text-monospace om-red-text text-end">
                                    Version:&nbsp;<strong>{data[versionEdit - 1]?.version}</strong>
                                </span>
                            </Col>


                            {/* No. of Rows and No. of Column */}
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className=' d-flex align-items-center'>
                                <Stack direction="horizontal" gap={2}>
                                    <div className="text-center" style={{ fontSize: "0.9rem" }}><strong>Rows:&nbsp;</strong><span className="font-monospace">{d_row ? d_row : data[versionDisplay - 1]?.data_rows ? data[versionDisplay - 1]?.data_rows : "N/A"}</span></div>
                                    <div className="text-center" style={{ fontSize: "0.9rem" }}><strong>Columns:&nbsp;</strong><span className="font-monospace">{d_col ? d_col : data[versionDisplay - 1]?.data_columns ? data[versionDisplay - 1]?.data_columns : "N/A"}</span></div>
                                    <div className="text-center" style={{ fontSize: "0.9rem" }}><strong>Spatial:&nbsp;</strong><span className="font-monospace">{data[versionDisplay - 1]?.is_spatial ? "Yes" : d_is_spatial ? "Yes" : "No"}</span></div>
                                </Stack>
                            </Col>
                        </Row>

                        {/* Category */}
                        <Row className="mt-4 mb-2 ms-0 me-0 p-0 d-flex align-items-center justify-content-start" >
                            < Col xs={6} sm={6} md={3} lg={3} xl={3} className="justify-content-end">
                                <Form.Select
                                    onChange={e => handleCategoryChange(e)}
                                    isInvalid={!!errors.category}
                                    className="text-capitalize"
                                    size="sm"
                                    defaultValue={data[versionEdit - 1]?.category ? data[versionEdit - 1]?.category : "category"}
                                >
                                    <option value="category">category</option>
                                    <option value="main">main</option>
                                    <option value="supplementary" >supplementary</option>
                                </Form.Select>
                                <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.category}</Form.Control.Feedback>
                            </Col>

                            <Col xs={6} sm={6} md={3} lg={3} xl={3} className="justify-content-end">
                                <Form.Select
                                    disabled={data[versionEdit - 1]?.data_id.toString().match(/[A-Za-z]/g)?.length > 0 && !!uploadResponse?.status === false}
                                    onChange={e => handleStatusChange(e)}
                                    isInvalid={!!errors.status}
                                    className="text-capitalize"
                                    size="sm"
                                    defaultValue={data[versionEdit - 1]?.status || "status"}
                                >
                                    <option value="status">status</option>
                                    <option value="published">published</option>
                                    <option value="draft">draft</option>
                                    <option value="archived">archived</option>
                                </Form.Select>
                                <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.status}</Form.Control.Feedback>
                            </Col>
                        </Row>
                        {/* Title */}
                        <Row className="mt-0 mb-0 ms-0 me-0 p-0">
                            <Card.Title className="data-listing-card-title mb-3" >
                                <Form.Control
                                    required
                                    disabled={data[versionEdit - 1]?.data_id.toString().match(/[A-Za-z]/g)?.length > 0 && !!uploadResponse?.status === false}
                                    ref={d_title_input_element}
                                    size="sm"
                                    type="text"
                                    placeholder="Title (Name shown in headline)"
                                    name="data_title"
                                    isInvalid={!!errors.title}
                                    onFocus={clearError}
                                    defaultValue={data[versionEdit - 1]?.title ? data[versionEdit - 1]?.title : d_title_input_element ? d_title_input_element.current?.value : ""}
                                />
                                <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.title}</Form.Control.Feedback>
                            </Card.Title>
                        </Row>

                        <Row className="mt-0 mb-0 ms-0 me-0 p-0">
                            {/* Data Description*/}
                            <Col xs={10} sm={10} md={9} lg={9} xl={9} >
                                <Form.Control
                                    required
                                    disabled={data[versionEdit - 1]?.data_id.toString().match(/[A-Za-z]/g)?.length > 0 && !!uploadResponse?.status === false}
                                    as="textarea"
                                    rows={10}
                                    ref={d_desc_input_element}
                                    size="sm"
                                    type="text"
                                    placeholder="Description"
                                    name="data_desc"
                                    isInvalid={!!errors.data_desc}
                                    onFocus={clearError}
                                    defaultValue={data[versionEdit - 1]?.data_desc ? data[versionEdit - 1]?.data_desc : ""}
                                />
                                <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.data_desc}</Form.Control.Feedback>
                            </Col>

                            {/* Download Button / Permission assignment*/}
                            <Col xs={2} sm={2} md={3} lg={3} xl={3} className="">
                                <div className="">
                                    Please separated permission by comma
                                </div>

                                <div className="mt-2">
                                    <Form.Control
                                        required
                                        disabled={data[versionEdit - 1]?.data_id.toString().match(/[A-Za-z]/g)?.length > 0 && !!uploadResponse?.status === false}
                                        as="textarea"
                                        rows={7}
                                        ref={d_permission_input_element}
                                        size="sm"
                                        type="text"
                                        placeholder="Data Permission"
                                        name="data_permission"
                                        isInvalid={!!errors.permission}
                                        onFocus={clearError}
                                        defaultValue={data[versionEdit - 1]?.permission ? data[versionEdit - 1]?.permission : ""}
                                    />
                                    <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.permission}</Form.Control.Feedback>
                                </div>
                            </Col>
                        </Row>
                        {/* Contact */}
                        <Row className="mt-3 mb-0 ms-0 me-0 p-0">
                            <Col>
                                <Form.Control
                                    required
                                    disabled={data[versionEdit - 1]?.data_id.toString().match(/[A-Za-z]/g)?.length > 0 && !!uploadResponse?.status === false}
                                    ref={d_contact_input_element}
                                    size="sm"
                                    type="text"
                                    placeholder="Contact"
                                    name="data_contact"
                                    isInvalid={!!errors.contact}
                                    onFocus={clearError}
                                    defaultValue={data[versionEdit - 1]?.contact ? data[versionEdit - 1]?.contact : ""}
                                />
                                <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.contact}</Form.Control.Feedback>
                            </Col>
                        </Row>

                        {/* Publish Date */}
                        <Row className="mt-2 mb-0 ms-0 me-0 p-0 d-flex align-items-center">
                            <Col xs={9} sm={9} md={9} lg={9} xl={9} className='justify-content-center'>
                                <Form.Control
                                    required
                                    disabled={data[versionEdit - 1]?.data_id.toString().match(/[A-Za-z]/g)?.length > 0 && !!uploadResponse?.status === false}
                                    ref={d_publish_date_input_element}
                                    size="sm"
                                    type="text"
                                    placeholder="Publish Date"
                                    name="data_publish_date"
                                    isInvalid={!!errors.publish_date}
                                    onFocus={clearError}
                                    defaultValue={data[versionEdit - 1]?.publish_date ? data[versionEdit - 1]?.publish_date : ""}
                                />
                                <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.publish_date}</Form.Control.Feedback>
                            </Col>
                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='pe-4 d-flex justify-content-end'>
                                <Button
                                    disabled={data[versionEdit - 1]?.data_id.toString().match(/[A-Za-z]/g)?.length > 0 && !!uploadResponse?.status === false}
                                    className='om-admin-button-narrow border-0'
                                    size='sm'
                                    onFocus={clearError}
                                    onClick={setTodayasPublishDate}>Today</Button>
                            </Col>
                        </Row>

                        {/* Tag */}
                        <Row className="mt-2 mb-0 ms-0 me-0 p-0 d-flex align-items-center">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="justify-content-start" >
                                <Form.Control
                                    required
                                    disabled={data[versionEdit - 1]?.data_id.toString().match(/[A-Za-z]/g)?.length > 0 && !!uploadResponse?.status === false}
                                    as="textarea"
                                    rows={2}
                                    ref={d_tag_input_element}
                                    size="sm"
                                    type="text"
                                    placeholder="Tags"
                                    name="data_tag"
                                    isInvalid={!!errors.tag}
                                    onFocus={clearError}
                                    defaultValue={data[versionEdit - 1]?.tag ? data[versionEdit - 1]?.tag : ""}
                                />
                                <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.tag}</Form.Control.Feedback>
                            </Col>
                        </Row>
                        {
                            //Admin portal, User has Admin right, not editing, non new dataset
                            isEditing === false
                                ?
                                <>
                                    <Row className='p-2 d-flex align-items-center justify-content-end'>
                                        <Col xs={12} sm={12} md={3} lg={2} xl={2} className='mt-2 d-flex justify-content-end'>
                                            <Button
                                                className='om-admin-button border-0'
                                                size="sm"
                                                onClick={() => handleEditData(data[versionDisplay - 1]?.data_id, versionDisplay)}
                                            >
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'pen' })} />&nbsp;Edit
                                            </Button>
                                        </Col>
                                        <Col xs={12} sm={12} md={3} lg={2} xl={2} className='mt-2 d-flex justify-content-end'>
                                            <Button className='om-admin-button border-0' size="sm" onClick={() => handleDeleteData(data[versionDisplay - 1]?.data_id)}>
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'trash' })} />&nbsp;Delete
                                            </Button>
                                        </Col>


                                    </Row>
                                </>
                                : <></>
                        }
                        {
                            //Admin portal, User has Admin right, show when a new/exisiting dataset is editing
                            isAdminPortal && loggedInUser?.role.split(",")?.includes(permission_admin) && isEditing &&
                                data[versionEdit - 1]?.data_id === editdid &&
                                TEMP_EDIT_ID_REGEX.test(data[versionEdit - 1]?.data_id.toString()) === false
                                ?
                                <Row className='p-2 d-flex align-items-center justify-content-end'>
                                    <Col xs={12} sm={12} md={3} lg={2} xl={2} className='mt-2 d-flex justify-content-end'>
                                        <Button
                                            disabled={data[versionEdit - 1]?.data_id.toString().match(/[A-Za-z]/g)?.length > 0 && !!uploadResponse?.status === false}
                                            className='om-admin-button border-0'
                                            size="sm"
                                            onClick={(e) => handleNextSave(e)}
                                        ><FontAwesomeIcon size="1x" icon={icon({ name: 'floppy-disk' })} />&nbsp;Save
                                        </Button>
                                    </Col>
                                    <Col xs={12} sm={12} md={3} lg={2} xl={2} className='mt-2 d-flex justify-content-end'>
                                        <Button className='om-admin-button border-0' size="sm" onClick={() => handleCancelEditData(data[versionEdit - 1]?.data_id, data[versionEdit - 1]?.data_name ? data[versionEdit - 1]?.data_name : uploadResponse?.upload_success ? uploadResponse.filelist[0].replace(".csv", "") : "", edit_session_id)}>
                                            <FontAwesomeIcon size="1x" icon={icon({ name: 'circle-xmark' })} />&nbsp;Cancel
                                        </Button>
                                    </Col>
                                </Row>
                                : isAdminPortal && loggedInUser?.role.split(",")?.includes(permission_admin) && isEditing && data[versionEdit - 1]?.data_id === editdid && !!data[versionEdit - 1]?.data_id.toString().match(/[A-Za-z]/g) === true
                                    ? <></>
                                    : <></>
                        }
                    </Card.Body>
                </Card>
            </>
        )
    } else if (isAdminPortal && loggedInUser?.role.split(",")?.includes(permission_admin) && isEditing && editdid === data[editversion - 1]?.data_id &&
        !!editdid === true && typeof (versionEdit) === 'number' && TEMP_EDIT_ID_REGEX.test(data[versionEdit - 1]?.data_id.toString()) === true) {
        /*DataCard Case 2 - Admin - Add New Data*/
        return (
            <>
                <Card key={data[editversion - 1]?.data_id} className="ms-0 me-0 mt-3 mb-3 p-0 data-listing-card shadow-sm" >
                    <Card.Body className="data-listing-card-body align-items-center">
                        <Row >
                            {/* Title for create/edit data*/}
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex justify-content-start">
                                <div className='pt-2 pb-2 font-monospace flex-grow-1' style={{ color: "#A52323" }}>
                                    {`Add new data in ${dataset?.title} (${dataset?.title_abbr.toUpperCase()})`}
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className='mt-2 d-flex align-items-start'>
                                <span style={{ fontWeight: 'bolder' }}>Batch Upload</span>
                            </Col>
                        </Row>
                        <hr className="pt-0 mt-0" />
                        <Row>
                            {/* Metadata File */}
                            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='mt-2 d-flex align-items-start'>
                                <Form.Group controlId="formFileData" className="justify-content-start">
                                    <Form.Label className="d-flex align-items-center justify-content-start">
                                        1. Metadata File (.csv)&nbsp;
                                        <a className="" size="sm" href={meta_data_csv_template} download="metadata_template.csv">
                                            Get template</a>
                                    </Form.Label>
                                    <Form.Control
                                        size="sm"
                                        ref={d_meta_data_file_sel_element}
                                        type="file"
                                        accept=".csv"
                                        onChange={(e) => handleMetaDataFileChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                            {/* Data dictionary upload */}
                            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='mt-2 d-flex align-items-start'>
                                <Form.Group controlId="formFileDataDict">
                                    <Form.Label className="d-flex align-items-center justify-content-start">
                                        2. Data Dictionary (consolidated; .csv)&nbsp;
                                        <a href={data_dict_csv_template} download="datadict_template.csv">Get template</a>
                                    </Form.Label>
                                    <Form.Control
                                        size="sm"
                                        ref={d_data_dict_sel_element}
                                        type="file"
                                        accept=".csv"
                                        onChange={(e) => handleDataDictChange(e)}
                                    />
                                </Form.Group>
                            </Col>
                            {/* Data Files upload */}
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className='mt-4 d-flex align-items-start'>
                                <Form.Group controlId="formFileDataDict">
                                    <Form.Label>
                                        3. Data File(s) - multiple file supported (.csv /.geojson)
                                    </Form.Label>
                                    <Form.Control
                                        size="sm"
                                        ref={d_data_file_sel_element}
                                        type="file"
                                        accept=".csv,.geojson,.json"
                                        multiple
                                        onChange={(e) => handleDataFileChange(e)}
                                    />
                                    <Form.Text>
                                        {!uploadResponse?.status && !uploadResponse?.upload_success && !uploading && (
                                            <>
                                                <div className="mt-3" style={{ color: 'red', fontWeight: 'bold' }}>No file uploaded yet</div>
                                            </>)
                                        }

                                        {uploadResponse?.status && !uploadResponse?.success && !uploading && (
                                            <>
                                                <div className="mt-3" style={{ color: 'red', fontWeight: 'bold' }}>{uploadResponse?.message}</div>
                                            </>)
                                        }

                                        {
                                            !uploadResponse?.upload_success && uploading && (
                                                <>
                                                    <div className="mt-3 m-1 d-flex align-items-center">
                                                        <Spinner className="me-1 mt-1 mb-1" size="sm" animation="border" role="status" variant="danger" />
                                                        <span className="ps-1" style={{ color: 'red', fontWeight: 'bold' }}>Uploading files...</span>
                                                    </div>
                                                </>)
                                        }

                                        {uploadResponse?.upload_success && uploadResponse?.processed_file.length > 0 && (
                                            <>
                                                <div className="mt-2" style={{ color: 'red', fontWeight: 'bold' }}>
                                                    File(s) processed
                                                </div>
                                                <div>
                                                    {

                                                        uploadResponse.processed_file.map((uploaded_file, index) => (
                                                            <div key={index}>{uploaded_file}</div>
                                                        ))

                                                    }
                                                </div>
                                            </>
                                        )}
                                        {uploadResponse?.upload_success && uploadResponse?.overwritten_file.length > 0 && (
                                            <>
                                                <div className="mt-2" style={{ color: 'red', fontWeight: 'bold' }}>
                                                    File(s) overwritten
                                                </div>
                                                <div>
                                                    {
                                                        uploadResponse.overwritten_file.map((ofile, index) => (
                                                            <div key={index}>{ofile}</div>
                                                        ))
                                                    }
                                                    {
                                                        Object.keys(uploadResponse.file_affected_ds)
                                                            ?.filter(key => key !== (location.state ? location.state?.dataset[0]?.title_abbr : dataset?.title_abbr))
                                                            ?.map((key) => {
                                                                return <span key={key} className="om-red-text">
                                                                    <br />Please note that the file(s) you are attempting to upload have already been found within the dataset:&nbsp;<strong>{key}</strong>
                                                                </span>
                                                            })
                                                    }

                                                </div>
                                            </>
                                        )}
                                        {uploadResponse?.upload_success && uploadResponse?.skipped_file.length > 0 && (
                                            <>
                                                <div className="mt-2" style={{ color: 'red', fontWeight: 'bold' }}>File(s) skipped</div>
                                                <div style={{ color: 'red' }}>
                                                    {
                                                        uploadResponse.skipped_file.map((sfile, index) => (
                                                            <div key={index}>{sfile}</div>
                                                        ))
                                                    }
                                                    {
                                                        Object.keys(uploadResponse.file_affected_ds)
                                                            ?.filter(key => key !== (location.state ? location.state?.dataset[0]?.title_abbr : dataset?.title_abbr))
                                                            ?.map((key) => {
                                                                return <span key={key} className="om-red-text">
                                                                    <br />Please note that the file(s) you are attempting to upload have already been found within the dataset:&nbsp;<strong>{key}</strong>
                                                                </span>
                                                            })
                                                    }
                                                </div>
                                            </>
                                        )}
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            {/* Overwrite files */}
                            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="d-flex align-items-end justify-content-end mt-2">
                                <Form.Check // prettier-ignore
                                    type='checkbox'
                                    id='overwrite-checkbox'
                                    label='Overwrite'
                                    ref={d_upload_overwrite_element}
                                />
                            </Col>
                            {/* Upload button (batch) */}
                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className="d-flex align-items-end justify-content-end mt-2">
                                {
                                    data[versionDisplay - 1]?.data_name
                                        ? <Button
                                            size="sm"
                                            disabled={!(d_meta_data_file_sel_element.current?.value && d_data_file_sel_element.current?.value && d_data_dict_sel_element.current?.value)}
                                            className="om-admin-button border-0"
                                            onClick={(e) => handleUpload(e)}>
                                            <FontAwesomeIcon size="1x" icon={icon({ name: 'cloud-arrow-up' })} />&nbsp;Re-upload
                                        </Button>
                                        : uploadResponse?.status
                                            ? <Button
                                                size="sm"
                                                disabled={!(d_meta_data_file_sel_element.current?.value && d_data_file_sel_element.current?.value && d_data_dict_sel_element.current?.value)}
                                                className="om-admin-button border-0"
                                                onClick={(e) => handleUpload(e)}>
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'cloud-arrow-up' })} />&nbsp;Re-upload
                                            </Button>
                                            : <Button
                                                size="sm"
                                                disabled={!(d_meta_data_file_sel_element.current?.value && d_data_file_sel_element.current?.value && d_data_dict_sel_element.current?.value)}
                                                className="om-admin-button border-0"
                                                onClick={(e) => handleUpload(e)}>
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'cloud-arrow-up' })} />&nbsp;Upload
                                            </Button>
                                }
                            </Col>
                            {/* Batch Upload - close button */}
                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='mt-3 d-flex align-items-end justify-content-end'>
                                <Button className='om-admin-button border-0' size="sm" onClick={() => handleCancelEditData(data[0]?.data_id)}>
                                    <FontAwesomeIcon size="1x" icon={icon({ name: 'circle-xmark' })} />&nbsp;Close
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </>
        )
    } else if (data.length > 0 && typeof (versionDisplay) === 'number') {
        /*DataCard Case 3 - Admin/User - Display*/
        return (
            <>
                {/* Version History Modal */}
                <Modal
                    key={data[versionDisplay - 1]?.title_abbr}
                    className="text-start"
                    show={showVersionHistoryModal}
                    onHide={() => setShowVersionHistoryModal(false)}
                    animation={true}
                    size="xl"
                    centered
                >
                    <Modal.Header closeButton className="m-1">
                        <Modal.Title className="text-capitalize">{data[versionDisplay - 1]?.title}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="m-0 p-3" scrollable={true}>
                        <Table size="sm" bordered responsive hover style={{ cursor: 'pointer' }}>
                            <thead >
                                <tr >
                                    <th className="text-center"><code className="om-red-text text-bolder">Version</code></th>
                                    <th className="text-center"><code className="om-red-text">Data ID</code></th>
                                    <th className="text-center"><code className="om-red-text">Last Updated Date</code></th>
                                    <th className="text-center"><code className="om-red-text">Last Updated By</code></th>
                                    <th className="text-center"><code className="om-red-text">Data File & Dictionary</code></th>
                                    <th className="text-center"><code className="om-red-text">Version Notes</code></th>
                                </tr>
                            </thead>

                            <tbody >
                                {
                                    data?.slice()?.reverse()?.map((d, index) => {
                                        return (
                                            <tr
                                                key={index}
                                                onClick={() => viewDataDetail({ data: data[d.version - 1], dataVersioned: data, versionDisplay: d.version })}
                                            >
                                                <td className="text-center text-monospace om-red-text align-items-center" ><code className="om-red-text">
                                                    {
                                                        d?.version <= data?.length
                                                            ? d?.version
                                                            : <>
                                                                <OverlayTrigger
                                                                    delay={{ show: 50, hide: 400 }}
                                                                    overlay={
                                                                        <Tooltip id="tooltip-disabled" >
                                                                            <code className="text-light">
                                                                                Please verify if the version entered is correct, as it does not match the No. of versions stored in the database.
                                                                                <br />({data.length} version(s) is/are stored in the database.)
                                                                            </code>
                                                                        </Tooltip>}>
                                                                    <FontAwesomeIcon size="1x" icon={icon({ name: 'triangle-exclamation' })} />
                                                                </OverlayTrigger>
                                                                &nbsp;{d?.version}
                                                            </>
                                                    }
                                                </code></td>
                                                <td className="text-center text-monospace om-red-text"><code className="om-red-text">{d?.data_id}</code></td>
                                                <td className="text-center text-monospace om-red-text"><code className="om-red-text">{d?.last_updated_date?.slice(0, 19)?.replace("T", " ")}</code></td>
                                                <td className="text-center text-monospace om-red-text">
                                                    <code className="om-red-text">
                                                        {d?.created_by && data_meta[d?.created_by] !== undefined && data_meta[d?.created_by] !== null
                                                            ? <a href={`mailto:${data_meta[d?.created_by]?.email}`}>
                                                                {`${data_meta[d?.created_by]?.firstname} ${data_meta[d?.created_by]?.lastname}`}
                                                            </a>
                                                            : d?.created_by !== null && d?.created_by !== undefined && d?.created_by !== ""
                                                                ? `${d?.created_by} (uid)`
                                                                : "--"
                                                        }
                                                    </code>
                                                </td>
                                                <td className="text-center text-monospace">
                                                    <code className="om-red-text me-2">
                                                        {`${d?.data_name}.${d?.format?.toLowerCase()}`}<br />
                                                        {`${d?.data_name}_datadict.csv`}
                                                    </code>
                                                </td>
                                                <td className="text-center text-monospace om-red-text"><code className="om-red-text">{d?.version_notes}</code></td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>

                        {/*Created By*/}
                        <Row className='d-flex align-items-center mb-3' >
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className='mt-1 mb-1 text-start'>
                                <code className='om-red-text ms-1'>Created By: </code>
                                <code className='om-red-text'><strong>{
                                    data[versionDisplay - 1]?.created_by && data_meta[data[versionDisplay - 1]?.created_by] !== undefined && data_meta[data[versionDisplay - 1]?.created_by] !== null
                                        ? <a href={`mailto:${data_meta[data[versionDisplay - 1]?.created_by]?.email}`}>
                                            {`${data_meta[data[versionDisplay - 1]?.created_by]?.firstname} ${data_meta[data[versionDisplay - 1]?.created_by]?.lastname}`}
                                        </a>
                                        : data[versionDisplay - 1]?.created_by !== null && data[versionDisplay - 1]?.created_by !== undefined && data[versionDisplay - 1]?.created_by !== ""
                                            ? `${data[versionDisplay - 1]?.created_by} (uid)`
                                            : "--"
                                }</strong></code>
                                <code className='om-red-text ms-3'>Date: </code>
                                <code className='om-red-text'><strong>
                                    {data[versionDisplay - 1]?.create_date ? data[versionDisplay - 1]?.create_date?.slice(0, 19)?.replace("T", " ") : "--"}
                                </strong>
                                </code>
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal >

                {/* Data Card */}
                < Card key={data[editversion - 1]?.data_id} className="ms-0 me-0 mt-3 mb-3 p-0 data-listing-card shadow-sm" >
                    <Card.Body className="data-listing-card-body">
                        <Row className="mt-0 mb-0 ms-0 me-0 p-0">
                            {/* Title / data description*/}
                            <Col xs={10} sm={11} md={11} lg={11} xl={11} className="p-1">
                                <Card.Title className="data-listing-card-title" >
                                    {!!data[versionDisplay - 1]?.title ? data[versionDisplay - 1]?.title : data[versionDisplay - 1]?.data_name}
                                </Card.Title>
                            </Col>

                            {/* Version selection */}
                            <Col xs={2} sm={1} md={1} lg={1} xl={1} className="d-flex justify-content-end align-items-center">
                                <Dropdown onSelect={e => handleViewPreviousVersion(e)} className="" >
                                    <Dropdown.Toggle
                                        key={data[versionDisplay - 1]?.data_id}
                                        disabled={data.length === 1 || isEditing}
                                        variant="outline-dark"
                                        className='border-0 rounded-5'
                                        size='sm'
                                        style={{ fontSize: "0.9rem" }}
                                    >
                                        <span className="font-monospace">
                                            v{data[versionDisplay - 1]?.version}
                                        </span>

                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={"div"} align={"end"} className="om-dropdown text-center"  >
                                        {
                                            data?.length > 0
                                                ?
                                                data?.map((data_versioned, index) => {
                                                    return (
                                                        <Dropdown.Item eventKey={data_versioned.version}>
                                                            {`v${data_versioned.version}`}
                                                        </Dropdown.Item>
                                                    )
                                                })
                                                : <></>
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>

                            {/* Data Description*/}
                            <Col xs={10} sm={10} md={11} lg={11} xl={11} >
                                <ReactMarkdown
                                    remarkPlugins={[remarkGfm]}
                                    children={data[versionDisplay - 1]?.data_desc}
                                    className="om-text"
                                />
                            </Col>

                            {/* Download Button */}
                            <Col xs={2} sm={2} md={1} lg={1} xl={1} className="d-flex align-items-center justify-content-md-center justify-content-sm-end">
                                <DownloadButton />
                            </Col>

                            {/* View Data Button */}
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="pt-1 pb-2">
                                <Button
                                    className="om-button black-gradient-hover-effect"
                                    variant="dark"
                                    onClick={() => viewDataDetail({
                                        data: data[versionDisplay - 1],
                                        dataVersioned: data,
                                        versionDisplay: versionDisplay
                                    })}
                                >
                                    <span className="ps-1 pe-1 ms-1">View Data </span>
                                    <FontAwesomeIcon size="1x" icon={icon({ name: 'arrow-right' })} />
                                </Button>
                            </Col>
                        </Row>

                        {
                            isAdminPortal
                                ? <>
                                    <Row className='p-2 d-flex align-items-center justify-content-end'>

                                        <Col xs={12} sm={12} md={6} lg={8} xl={8} className='mt-2 d-flex justify-content-end'>
                                            <Button className='om-admin-button border-0 text-capitalize' size="sm" onClick={() => setShowVersionHistoryModal(true)}>
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'circle-info' })} />&nbsp;View version details/history
                                            </Button>
                                        </Col>

                                        <Col xs={12} sm={12} md={3} lg={2} xl={2} className='mt-2 d-flex justify-content-end'>
                                            <Button
                                                className='om-admin-button border-0 text-capitalize'
                                                size="sm"
                                                onClick={() => handleEditData(data[versionDisplay - 1]?.data_id, versionDisplay)}
                                            >
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'pen' })} />&nbsp;Edit
                                            </Button>
                                        </Col>

                                        <Col xs={12} sm={12} md={3} lg={2} xl={2} className='mt-2 d-flex justify-content-end'>
                                            <Button className='om-admin-button border-0 text-capitalize' size="sm" onClick={() => handleDeleteData(data[versionDisplay - 1]?.data_id)}>
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'trash' })} />&nbsp;Delete
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                                : <></>
                        }

                    </Card.Body>
                </Card >
            </>
        )
    } else {
        return
    }
}

export default DataCard