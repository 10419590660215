import React, { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom';

import { Col, Row, Badge, Button, Form, InputGroup, Dropdown, Stack, Placeholder, DropdownButton, SplitButton, ButtonGroup } from 'react-bootstrap'

import { useCustomNavigate } from '../hooks/useCustomNavigate';
import { KEYWORDSEARCH_REGEX } from '../constants/regex'
import { DATA_DOWNLOAD } from '../constants/omp_ui_constant'

import ui_message from '../constants/omp_ui_text_message';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import OmLogoStaticSvg from '../Assets/images/openmortality-logo.svg';

import { api_config } from "./api_url";

import { contextIsAdminPortal, contextPortalUser } from '../App';

import './HeaderNew.css'

const HeaderNew = ({ isLoading, CartCount, dataset, setDatasets, data, keyword, setKeyword }) => {
    const api_url = api_config.url.API_URL

    const navigate = useCustomNavigate();
    const location = useLocation();

    const [isAdminPortal, setIsAdminPortal] = useContext(contextIsAdminPortal);
    const [portalUser, setPortalUser] = useContext(contextPortalUser);

    let localCart;

    //const [keyword, setKeyword] = useState("");
    const [cart, setCart] = useState([]);
    const [isDownloading, setIsDownloading] = useState(localStorage.getItem('isDownloading') === 'true' ? true : false);
    const [validated, setValidated] = useState(false);
    const [validkeyword, setValidKeyword] = useState(true)

    const handleKeywordChange = (e) => {
        if (e.target.value === "") {
            setKeyword("")
            navigate("/dataset");
        }
        setValidKeyword(true)  //clear invalid message when inputting     
        setKeyword(e.target.value)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleDatasetSearch() //datasetSearch()            
        }
    }

    const handleDatasetSearch = async () => {
        if (!keyword || !KEYWORDSEARCH_REGEX.test(keyword)) {
            setValidKeyword(false)
            setValidated(false);
        }
        else {
            setValidated(true);
            setValidKeyword(true)
            navigate("/dataset?keyword=" + keyword.trim());
        }
    }

    const handleClearSearch = () => {
        setKeyword("")
        navigate("/dataset");
    }

    const handleLogoClick = async () => {
        navigate("/")
    }

    const handleClick = (e) => {
        switch (e.target.value) {
            case "register":
                navigate("/Register");
                break;
            case "cart":
                navigate("/DownloadCart");
                break;
            default:
        }
    }

    const handleCartImageClick = (e) => {
        navigate("/DownloadCart", {
            state:
            {
                dataset: location.state ? location.state.dataset : "",
                data: location.state ? location.state.data : "",
                user: location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))
            }
        });
    }

    const logout = () => {
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/user/logout", {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => res.json())
                .then(async (data) => {
                    resolve(data)
                    //redirect to home page
                    setIsAdminPortal(null)
                    setPortalUser(null)
                    navigate("/")
                })
                .catch((err) => {
                    reject(err)
                });
        });
    }

    const handleLoginOut = async () => {
        if (!!portalUser) {
            logout()
                .then((logoutstatus) => {
                    localStorage.clear();
                    setPortalUser(null)
                    navigate("/");
                })
                .catch((err) => {
                    console.log(err?.message)
                })
        }
        else {
            navigate("/Login");
        }
    }

    const handleGoback = () => {
        navigate(-1)
    }

    const handleViewUseInfo = () => {
        navigate("/userinfo");
    }

    const handleChangePassword = () => {
        navigate("/changepassword");
    }

    const gotoAdminPortal = () => {
        setIsAdminPortal(true)
        //navigate("/admin" + window.location.pathname, { replace: true });
        window.location.href = window.location.protocol + "//" + window.location.host + "/admin" + window.location.pathname + window.location.search;
    }

    const gotoUserPortal = () => {
        let UserPortalPage = ""
        setIsAdminPortal(false)
        if (window.location.pathname.includes('dataset/')) {
            if (dataset?.length > 0 && dataset[0]?.status?.trim() === "draft") {
                const confirmProceed = window.confirm(ui_message.ADMIN_PORTAL.back_to_user_portal_draft_dataset_warning);
                if (confirmProceed)
                    UserPortalPage = `${window.location.protocol}//${window.location.host}/dataset`
                //UserPortalPage = "/dataset"
                else
                    return
            } else {
                UserPortalPage = window.location.pathname.replace('/admin', '')
            }
        } else if (window.location.pathname.includes('data/')) {
            if (data?.status?.trim() === "draft" || (dataset?.length > 0 && dataset[0]?.status?.trim() === "draft")) {
                const confirmProceed = window.confirm(ui_message.ADMIN_PORTAL.back_to_user_portal_draft_data_warning);
                if (confirmProceed)
                    UserPortalPage = `${window.location.protocol}//${window.location.host}/dataset`
                //UserPortalPage = "/dataset"
                else
                    return
            } else {
                UserPortalPage = `${window.location.protocol}//${window.location.host}${window.location.pathname.replace('/admin', '')}${window.location.search}`
                //UserPortalPage = window.location.pathname.replace('/admin', '')
            }
        } else {
            UserPortalPage = window.location.pathname.replace('/admin', '') + window.location.search
        }
        window.location.href = UserPortalPage
        //navigate(UserPortalPage, { replace: true });
    }

    useEffect(() => {
        //turn it into js
        localCart = localStorage.getItem("cart");

        if (localCart !== null) {
            const local_Cart_Json = JSON.parse(localCart);
            setCart(local_Cart_Json)
            //setDownloadlist(local_Cart_Json)
        }

        if (localStorage.getItem('isDownloading') === "true") {
            const checkDownloading = setInterval(() => {
                if (localStorage.getItem('isDownloading') === "false" || localStorage.getItem('isDownloading') === null || localStorage.getItem('isDownloading') === "undefined") {
                    clearInterval(checkDownloading);
                    //setIsLoading(false)
                    setIsDownloading(false)
                }
            }, DATA_DOWNLOAD.REFRESH_INTERVAL); // Check every 2 seconds
        }
    }, [localCart])

    useEffect(() => {
        setIsDownloading(localStorage.getItem('isDownloading') === "true" ? true : false)
    }, [isDownloading])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const keyword = searchParams.get("keyword");
        setKeyword(keyword || "");
    }, [location.search])

    return (
        <>
            <Row className='ms-2 me-2 p-1 border-bottom border-dark border-3 shadow-sm'>
                <Col xs={3} sm={3} md={4} lg={4} xl={4} className='ps-md-3 pt-1 pb-1 d-flex text-start justify-content-start'>
                    <img className="om-header-logo" src={OmLogoStaticSvg} onClick={() => handleLogoClick()} />
                </Col>

                <Form noValidate xs={6} sm={5} md={4} lg={4} xl={4} as={Col} className="d-flex align-items-center" id="FrmKeySearch" >
                    <InputGroup>
                        <Form.Control
                            className='om-header-search'
                            placeholder={`Search Dataset`}
                            onChange={handleKeywordChange}
                            onKeyDown={handleKeyDown}
                            value={keyword}
                            isInvalid={!validkeyword}
                        ></Form.Control>
                        {/*
                            keyword ?

                                <Button
                                    id="header-button-clear-search-button"
                                    variant='outline-dark'
                                    className="d-flex align-items-center border-0 ms-0 ps-0"
                                    onClick={(e) => handleClearSearch()}
                                    size='sm'
                                    style={{ background: 'transparent', cursor: 'pointer' }}
                                >
                                    <FontAwesomeIcon size='sm' className='fa-flip-horizontal om-header-search-icon' icon={icon({ name: 'circle-xmark' })} />
                                </Button>

                                : <></>
                        */}

                        <Button
                            id="header-button-clear-search-button"
                            variant='dark'
                            className="d-flex align-items-center justify-content-center om-header-search-button red-gradient-hover-effect"
                            onClick={(e) => handleDatasetSearch()}
                        >
                            <FontAwesomeIcon className='fa-flip-horizontal om-header-search-icon' icon={icon({ name: 'magnifying-glass' })} />
                        </Button>

                        <Form.Control.Feedback type='invalid'>
                            <span className="om-header-search-response-text" >Please input keyword</span>
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form>

                {
                    !!portalUser && portalUser !== "" && portalUser !== null
                        ? <>
                            {/* Download Cart*/}
                            <Col xs={3} sm={4} md={4} lg={4} xl={4} className='p-0 d-flex align-items-center justify-content-end'>
                                <Stack direction='horizontal' gap={1} className="p-2 p-md-1">
                                    <div className="download-cart-item d-flex align-items-center justify-content-center" onClick={handleCartImageClick} style={{ cursor: "pointer" }}>

                                        {
                                            isDownloading
                                                ? <>
                                                    <FontAwesomeIcon size="1x" icon={icon({ name: 'cart-arrow-down' })} />
                                                </>
                                                : !isDownloading && CartCount === 0
                                                    ? <>
                                                        <FontAwesomeIcon className="pt-1 download-cart-icon" icon={icon({ name: 'cart-shopping' })} />
                                                    </>
                                                    : <>
                                                        <FontAwesomeIcon className="pt-1 download-cart-icon" icon={icon({ name: 'cart-shopping' })} />
                                                        <Badge pill bg="dark">{CartCount}</Badge>
                                                    </>
                                        }
                                    </div>

                                    {/* User profile */}
                                    {

                                        <Dropdown className='user-profile-dropdown'>
                                            <Dropdown.Toggle className='ps-1 pe-1 pb-1 d-flex align-items-center' variant="" id="lg-dropdown-profile" >
                                                <FontAwesomeIcon className="profile-icon" icon={icon({ name: 'circle-user', style: 'solid' })} />
                                                <span className='profile-name'>
                                                    {portalUser ? portalUser?.firstname || "Login" : "Logout"}
                                                </span>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu size="sm">
                                                <Dropdown.Item size="sm" eventKey="1" onClick={(e) => handleViewUseInfo(e)} >View Profile</Dropdown.Item>
                                                <Dropdown.Item size="sm" eventKey="2" onClick={(e) => handleChangePassword(e)} >Change password</Dropdown.Item>
                                                {
                                                    portalUser?.role?.split(',').map(r => r.trim()).includes("admin") && isAdminPortal === false
                                                        ? <><Dropdown.Item size="sm" eventKey="3" onClick={(e) => gotoAdminPortal()} >Admin Portal</Dropdown.Item></>
                                                        : portalUser?.role?.split(',').map(r => r.trim()).includes("admin") && isAdminPortal === true
                                                            ? <><Dropdown.Item size="sm" eventKey="3" onClick={(e) => gotoUserPortal()} >User Portal</Dropdown.Item></>
                                                            : <></>
                                                }
                                                <Dropdown.Divider />
                                                <Dropdown.Item size="sm" eventKey="4" onClick={(e) => handleLoginOut(e)} >
                                                    {portalUser ? "Logout" : "Login"}
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>

                                    }

                                </Stack>
                            </Col>
                        </>
                        : !!portalUser === false //&& (isLoading === undefined || isLoading === false)
                            ? <>
                                <Col xs={3} sm={4} md={4} lg={4} xl={4} className='d-flex align-items-center justify-content-end pe-3'>
                                    <Button className="om-button black-gradient-hover-effect" variant="dark" id="login-button" value="register" onClick={(e) => handleLoginOut(e)} >
                                        {portalUser ? "Logout" : "Login"}
                                    </Button>
                                </Col>
                            </>
                            : <></>
                }
            </Row >
        </>
    )
}

export default HeaderNew