import React, { useEffect, useState, useRef, useContext } from 'react'
import { Col, Row, Badge, Button, Form } from 'react-bootstrap';

import { DATA_MD_CITATION_EXTRACT_REGEX, DATA_MD_ABOUT_EXTRACT_REGEX } from '../constants/regex';
import { status_published, status_draft, permission_admin } from '../constants/omp_constant.js';
import { input_msg_ds_title, input_msg_ds_title_abbr, input_msg_ds_publish_date, input_msg_ds_description } from '../constants/omp_ui_text.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { contextIsAdminPortal, contextPortalUser } from '../App.js'


import "./datasetInfo.css"

const DatasetInfo = ({ dataset, isEditing, editdsid, handleSaveDataset, handleEditDataset, handleCancelEditDataset, handleDeleteDataset }) => {
    const [isAdminPortal] = useContext(contextIsAdminPortal);
    const [portalUser] = useContext(contextPortalUser);

    //const [dsInfo, setDsInfo] = useState();

    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [ds_status, set_ds_status] = useState();

    const ds_title_input_element = useRef();
    const ds_title_abbr_input_element = useRef();
    const ds_publish_date_input_element = useRef();
    const ds_description_input_element = useRef();
    const ds_contact_input_element = useRef();

    const ds_permission_input_element = useRef();
    const ds_tag_input_element = useRef();

    const validateForm = () => {
        const newErrors = {}
        if (!ds_title_input_element.current?.value || ds_title_input_element.current?.value === '') newErrors.title = input_msg_ds_title
        if (!ds_title_abbr_input_element.current?.value || ds_title_abbr_input_element.current?.value === '') newErrors.title_abbr = input_msg_ds_title_abbr
        if (!ds_publish_date_input_element.current?.value || ds_publish_date_input_element.current?.value === '') newErrors.publish_date = input_msg_ds_publish_date
        if (!ds_description_input_element.current?.value || ds_description_input_element.current?.value === '') newErrors.description = input_msg_ds_description

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
            setValidated(false)
        } else {
            handleSaveDataset(dataset.dataset_id, ds_title_input_element.current?.value, ds_title_abbr_input_element.current?.value.toLowerCase(),
                ds_description_input_element.current?.value, ds_publish_date_input_element.current?.value, ds_contact_input_element.current?.value,
                dataset.data_id, ds_permission_input_element.current?.value, ds_status,
                ds_tag_input_element.current?.value)

        }
    }

    const clearError = () => {
        setErrors("")
        setValidated()
    }

    const handleStatusChange = (e) => {
        clearError()
        set_ds_status(e.target.value)
    }

    const setTodayasPublishDate = () => {
        const today = new Date(Date.now());
        const current_date_time =
            today.getFullYear().toString() + "-" +
            (today.getMonth() + 1).toString().padStart(2, '0') + "-" +
            today.getDate().toString().padStart(2, '0');
        ds_publish_date_input_element.current.value = current_date_time
    }

    useEffect(() => {
        //Set status when loading
        if (!!dataset) set_ds_status(dataset?.status?.trim())
    }, [])

    if (!!dataset === true) {
        //Extract contact info from description
        //const contactPattern = /## Contact([\s\S]*?)(?=$)/;
        //console.log(mdDsDescription.match(contactPattern))
        return (
            <>
                <div className="ms-1 me-1 mt-2 mb-0">
                    <Row className='p-2 d-flex align-items-center'>
                        {
                            isAdminPortal && portalUser?.role.split(",")?.includes(permission_admin)
                                ?
                                (isEditing && (dataset?.dataset_id === editdsid))
                                    ?
                                    <>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className='mt-2 d-flex justify-content-end '>
                                            <Button className='om-admin-button border-0 me-1' size="sm" onClick={validateForm}>
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'floppy-disk' })} />&nbsp;Save
                                            </Button>
                                            <Button className='om-admin-button border-0 ms-1' size="sm" onClick={() => handleCancelEditDataset(dataset.dataset_id)}>
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'circle-xmark' })} />&nbsp;Cancel
                                            </Button>
                                        </Col>
                                    </>
                                    : <Col xs={12} sm={12} md={12} lg={12} xl={12} className='mt-2 mb-1 d-flex justify-content-end'>
                                        <Button disabled={isEditing} className='om-admin-button border-0' size="sm" onClick={() => handleEditDataset(dataset.dataset_id)}>
                                            <FontAwesomeIcon size="1x" icon={icon({ name: 'pen' })} />&nbsp;Edit
                                        </Button>
                                    </Col>
                                : <></>
                        }
                    </Row>

                    <Row className='d-flex align-items-center mb-2'>
                        {isAdminPortal && portalUser?.role?.split(",")?.includes(permission_admin)
                            ?
                            isEditing && (editdsid === dataset?.dataset_id)
                                ?
                                <>
                                    <Col xs={12} sm={12} md={6} lg={6} xl={6} className='mt-1'>
                                        <Form.Control
                                            required
                                            ref={ds_title_input_element}
                                            size="sm"
                                            type="text"
                                            placeholder="Dataset Name"
                                            name="dataset_name"
                                            isInvalid={!!errors.title}
                                            onFocus={clearError}
                                            defaultValue={!!dataset ? dataset?.title : ""}
                                        />
                                        <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.title}</Form.Control.Feedback>
                                    </Col>

                                    <Col xs={12} sm={12} md={6} lg={"auto"} xl={"auto"} className='mt-1'>
                                        <Form.Control
                                            required
                                            ref={ds_title_abbr_input_element}
                                            size="sm"
                                            type="text"
                                            placeholder="Dataset Abbreviation"
                                            name="dataset_abbr"
                                            isInvalid={!!errors.title_abbr}
                                            onFocus={clearError}
                                            defaultValue={!!dataset ? dataset.title_abbr.toUpperCase() : ""}
                                        />
                                        <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }} >{errors.title_abbr}</Form.Control.Feedback>
                                    </Col>
                                    <Col>
                                        <Form.Select
                                            onChange={e => handleStatusChange(e)}
                                            isInvalid={!!errors.status}
                                            className="text-capitalize"
                                            size="sm"
                                            defaultValue={!!dataset ? dataset?.status?.trim() : ds_status.trim()}
                                        >
                                            <option value="status">status</option>
                                            <option value="published">published</option>
                                            <option value="draft">draft</option>
                                        </Form.Select>
                                    </Col>

                                </>
                                :
                                <>
                                    <Col xs={10} sm={10} md={10} lg={10} xl={10} className='d-flex align-items-center justify-content-start datasetInfo-title' >
                                        {!!dataset ? dataset?.title : ""} ({!!dataset ? dataset?.title_abbr?.toUpperCase() : ""})
                                    </Col>
                                    <Col xs={2} sm={2} md={2} lg={2} xl={2} className='d-flex font-monospace align-items-center justify-content-end' >
                                        <span className='font-monospace text-capitalize om-red-text' style={{ fontSize: "1rem" }}>
                                            {!!dataset ? dataset?.status : ""}&nbsp;
                                        </span>
                                    </Col>
                                </>
                            : <>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex align-items-center justify-content-start datasetInfo-title' >
                                    {!!dataset ? dataset?.title : ""} ({!!dataset ? dataset?.title_abbr?.toUpperCase() : ""})
                                </Col>
                            </>
                        }
                    </Row>

                    <Row className='d-flex align-items-center mb-2'>
                        {isAdminPortal && portalUser?.role.split(",")?.includes(permission_admin) && (isEditing && (editdsid === dataset?.dataset_id))
                            ? <>
                                <Col xs={3} sm={3} md={3} lg={3} xl={3} className=''>
                                    <div className='d-flex align-items-center'>
                                        <FontAwesomeIcon size="1x" icon={icon({ name: 'calendar', style: 'regular' })} />&nbsp;&nbsp;
                                        <Form.Control
                                            required
                                            ref={ds_publish_date_input_element}
                                            size="sm"
                                            type="text"
                                            placeholder="Publish Date"
                                            name="publish_date"
                                            isInvalid={!!errors.publish_date}
                                            onFocus={clearError}
                                            defaultValue={!!dataset ? dataset?.publish_date?.slice(0, 10) : ""}
                                        />
                                        <Form.Control.Feedback className="ms-2" type='invalid'>{errors.publish_date}</Form.Control.Feedback>
                                    </div>
                                </Col>
                                <Col xs={3} sm={3} md={3} lg={3} xl={3} className='d-flex justify-content-center'>
                                    <Button className='om-admin-button border-0' size='sm' onFocus={clearError} onClick={setTodayasPublishDate}>Today</Button>
                                </Col>
                                <Col xs={1} sm={1} md={1} lg={1} xl={1} className='d-flex justify-content-end'>
                                    <FontAwesomeIcon size="1x" icon={icon({ name: "envelope", style: 'regular' })} />
                                </Col>
                                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                                    <Form.Control
                                        required
                                        ref={ds_contact_input_element}
                                        size="sm"
                                        type="text"
                                        placeholder="Contact"
                                        name="contact"
                                        isInvalid={!!errors.contact}
                                        onFocus={clearError}
                                        defaultValue={!!dataset ? dataset?.contact : ""}
                                    />
                                    <Form.Control.Feedback className="ms-2" type='invalid'>{errors.contact}</Form.Control.Feedback>
                                </Col>
                                <Col xs={3} sm={3} md={3} lg={3} xl={3} >
                                    <div className="">
                                        Please separated permission by comma
                                    </div>
                                </Col>
                                <Col xs={9} sm={9} md={9} lg={9} xl={9} >
                                    <div className="mt-2">
                                        <Form.Control
                                            required
                                            as="textarea"
                                            rows={3}
                                            ref={ds_permission_input_element}
                                            size="sm"
                                            type="text"
                                            placeholder="Dataset Permission"
                                            name="data_permission"
                                            isInvalid={!!errors.permission}
                                            onFocus={clearError}
                                            defaultValue={!!dataset ? dataset?.permission : ""}
                                        />
                                        <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.permission}</Form.Control.Feedback>
                                    </div>
                                </Col>

                            </>
                            : <>
                                <Col xs={12} sm={12} md={3} lg={3} xl={3} className='d-flex align-items-center justify-content-start datasetInfo-publish-date' >
                                    <div>
                                        <FontAwesomeIcon size="1x" icon={icon({ name: 'calendar', style: 'regular' })} />
                                        <span> {!!dataset ? dataset?.publish_date?.slice(0, 10) : ""}</span>
                                    </div>
                                </Col>

                                <Col xs={12} sm={12} md={5} lg={5} xl={5} className='d-flex align-items-center justify-content-start' >
                                    <a href={`mailto:${!!dataset ? dataset?.contact : ""}`} className='datasetInfo-contact'>
                                        <FontAwesomeIcon size="1x" icon={icon({ name: "envelope", style: 'regular' })} />
                                        <span> {!!dataset ? dataset?.contact : ""}</span>
                                    </a>
                                </Col>
                            </>
                        }
                    </Row>

                    <Row className='d-flex align-items-center pb-2 pt-2'>
                        {isAdminPortal && portalUser?.role.split(",")?.includes(permission_admin) && (isEditing && editdsid === dataset.dataset_id)
                            ? <>
                                <Form.Control
                                    as="textarea"
                                    required
                                    ref={ds_description_input_element}
                                    size="sm"
                                    rows={15}
                                    placeholder="Description"
                                    name="dataset_desc"
                                    isInvalid={!!errors.description}
                                    onFocus={clearError}
                                    defaultValue={
                                        dataset.dataset_desc
                                            ? dataset.dataset_desc?.match(DATA_MD_ABOUT_EXTRACT_REGEX)
                                                ? dataset.dataset_desc.match(DATA_MD_ABOUT_EXTRACT_REGEX)[1]
                                                : dataset.dataset_desc
                                            : ""
                                    }
                                />
                                <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
                            </>
                            : <>
                                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <ReactMarkdown
                                        className='datasetInfo-md-content'
                                        remarkPlugins={[remarkGfm]}
                                        children={
                                            dataset?.dataset_desc?.match(DATA_MD_ABOUT_EXTRACT_REGEX)
                                                ? dataset?.dataset_desc?.match(DATA_MD_ABOUT_EXTRACT_REGEX)[0].trim()
                                                : dataset?.dataset_desc
                                        }
                                    />
                                </Col>
                            </>
                        }
                    </Row>
                    <Row className='d-flex align-items-center pb-2 pt-2'>
                        {isAdminPortal && portalUser?.role.split(",")?.includes(permission_admin)
                            ?
                            isEditing && (editdsid === dataset.dataset_id)
                                ? <>
                                    <Form.Control
                                        as="textarea"
                                        required
                                        ref={ds_tag_input_element}
                                        size="sm"
                                        rows={2}
                                        placeholder="tag"
                                        name="dataset_tag"
                                        isInvalid={!!errors.description}
                                        onFocus={clearError}
                                        defaultValue={dataset?.tag}
                                    />
                                    <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
                                </>
                                :
                                <>
                                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className='mt-1 mb-1'>
                                        {
                                            dataset?.tag
                                                ?
                                                dataset?.tag?.split(",")?.map((t, index) => {
                                                    return (<Badge className="me-2 mt-1 ps-2 pe-2" key={t + "_" + index} bg="dark">{t}</Badge>)
                                                })
                                                : <>
                                                    <Badge className="me-2 mt-1 ps-2 pe-2" bg="danger">No Tag</Badge>
                                                </>
                                        }
                                    </Col>
                                </>
                            : <></>
                        }

                    </Row>

                    {/*<Row className='d-flex align-items-center pb-2 pt-2'>

                        {
                            isAdminPortal && portalUser?.role.split(",")?.includes(permission_admin)
                                ? isEditing && (editdsid === dataset?.dataset_id)
                                    ? <>
                                        <Col xs={3} sm={2} md={2} lg={2} xl={2}>
                                            <div>Data in this collection:</div>
                                        </Col>
                                        <Col xs={9} sm={10} md={10} lg={10} xl={10}>
                                            < Form.Control
                                                required
                                                disabled
                                                size="sm"
                                                placeholder="data ID(s)"
                                                name="dataset_data_id"
                                                defaultValue={dataset[0]?.data_id}
                                            />
                                        </Col>
                                    </>
                                    : <>
                                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                            <div className='om-red-text'>
                                                <strong>Data in this collection:&nbsp;</strong>
                                                {
                                                    dataset?.data_id === null || dataset?.data_id === ""
                                                        ? <i>{"No data in this data collection"}</i>
                                                        : dataset?.data_id
                                                }                                                
                                            </div>
                                        </Col>
                                    </>
                                : <></>
                                            }
                                        </Row>*/}
                </div >
            </>
        )
    }
    else {
        return (
            <>
            </>
        )
    }
}

export default DatasetInfo