/* login.jsx */
import React, { useState, useRef, useEffect, useContext } from "react";
import { useCustomNavigate } from '../hooks/useCustomNavigate';
import { Col, Row, Form, Button, InputGroup } from "react-bootstrap";

import MainLogoStatic from '../components/MainLogoStatic'

import { api_config } from "../components/api_url";

import TsParticles from "../components/ParticlesBackground";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { contextPortalUser } from "../App";

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Login = () => {
    const signin_button_text = "Login"
    const submit_button_text = "Submit"
    const api_url = api_config.url.API_URL

    const navigate = useCustomNavigate();

    const [message, setMessage] = useState("");
    const [validated, setValidated] = useState(false);
    const [authenicated, setAuthenicated] = useState(false);
    const [buttonText, setButtonText] = useState(signin_button_text);
    const [ResendVemailButton, setResendVemailButton] = useState("hidden");
    const [form, setForm] = useState({ email: "", password: "", otp: "" });

    const [portalUser, setPortalUser] = useContext(contextPortalUser)

    const emailInputElement = useRef();
    const passwordInputElement = useRef();
    const passwordShowHideElement = useRef();
    const passwordShowHideElementMobile = useRef();
    const otpInputElement = useRef();

    const passwordShowIcon = useRef();
    const passwordHideIcon = useRef();

    const passwordShowIconMobile = useRef();
    const passwordHideIconMobile = useRef();

    const [errors, setErrors] = useState({ email: "", password: "", otp: "" });

    const handleShowPassword = () => {
        passwordInputElement.current.setAttribute('type', 'text');
        passwordHideIcon.current.style.display = 'none';
        passwordShowIcon.current.style.display = 'block';
    }

    const handleHidePassword = () => {
        passwordInputElement.current.setAttribute('type', 'password');
        passwordHideIcon.current.style.display = 'block';
        passwordShowIcon.current.style.display = 'none';
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (buttonText === signin_button_text) {
                processSignin(e)
            }
            else if (buttonText === submit_button_text) {
                processSubmit(e)
            }
            else {

            }
        }
    }

    const resetForm = () => {
        emailInputElement.current.value = '';
        passwordInputElement.current.value = '';
        //otpInputElement.current.value = '';

        setForm({ email: "", password: "", otp: "" });
        setErrors({});
        setValidated(false)
        validateForm()
    }

    const clearError = () => {
        setErrors("")
    }

    const resendVerificationEmail = () => {
        fetch(api_url + "/user/resendaccountverifyemail", {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                //email: form.email,
                //password: form.password
                email: emailInputElement.current?.value,
                //password : passwordInputElement.current?.value 
            }),
        })
            .then((res) => res.json())
            .then(async (resend_result) => {
                //console.log(resend_result.info.message)            
                resetForm()
                setResendVemailButton("hidden")
                setMessage(resend_result.info.message)

            })
            .catch((err) => {
                setMessage("Login fail " + err)
            });
    }

    const validateForm = (submit_type) => {
        const { email, password, otp } = form
        const newErrors = {}
        if (submit_type === signin_button_text) {
            if (!emailInputElement.current?.value || emailInputElement.current?.value === "") newErrors.email = 'Please enter email'
            if (!passwordInputElement.current?.value || passwordInputElement.current?.value === "") newErrors.password = 'Please enter password'
        }
        else if (submit_type === submit_button_text) {
            //if (!otp || otp === '') newErrors.otp = 'Please enter One time passcode'
            if (!otpInputElement.current?.value || otpInputElement.current?.value === '') newErrors.otp = 'Please enter One time passcode'
        }
        else {

        }

        return newErrors
    }

    const handleRegisterClick = (e) => {
        navigate("/Register")
    }

    const handleButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setMessage("")
        const formsErrors = validateForm(e.target.textContent)

        if (Object.keys(formsErrors).length > 0) {
            setErrors(formsErrors)
        }
        else {
            setValidated(true)
            if (e.target.textContent === signin_button_text) {
                processSignin(e)
            }
            else if (e.target.textContent === submit_button_text) {

                processSubmit(e)
            }
            else {

            }
        }
    }

    const processSignin = e => {
        fetch(api_url + "/user/signin", {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                email: emailInputElement.current?.value,
                password: passwordInputElement.current?.value
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    if (res.status === 429) {
                        throw new Error('Too Many Requests');
                    } else {
                        throw new Error('Request failed with status code ' + res.status);

                    }
                }
                return res.json();
            })
            .then(async (user_auth) => {
                if ((Object.keys(user_auth.info).includes("ac_status")) && (user_auth.info.ac_status === 'pending')) {
                    setMessage(user_auth.info.message)
                    //Show re-send verification email link
                    setResendVemailButton("visible")
                }

                else if (user_auth.auth) {
                    setMessage(user_auth.info.message)
                    setButtonText(submit_button_text)
                    setAuthenicated(user_auth.auth)
                }
                else {
                    setMessage(user_auth.info.message)
                    setValidated(false)
                }
            })
            .catch((err) => {
                setMessage("Login fail " + err)
            });
        //}
    }

    const getUser = () => {
        return new Promise((resolve, reject) => {
            try {
                fetch(api_url + "/user/getUser", {
                    method: 'GET',
                    credentials: 'include'
                })
                    .then((res) => res.text()) //return res.text for getUser() to avoid JSON parsing error; the passport.js return null when there is no user in session
                    .then((data) => {
                        resolve(data.length == 0 ? null : JSON.parse(data)) //If there is user, parse the response to JSON
                    })
                    .catch((err) => {
                        reject(err)
                    });
            }
            catch (err) {
                reject(err)
            }
        })
    }

    const processSubmit = e => {
        e.preventDefault();
        setAuthenicated(false)
        setMessage("")
        fetch(api_url + "/user/verify-otp", {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                email: emailInputElement.current?.value,
                password: passwordInputElement.current?.value,
                otp: otpInputElement.current?.value
            }),
        })
            .then((res) => {
                if (!res.ok) {
                    if (res.status === 429) {
                        throw new Error('Too Many Requests');
                    } else {
                        throw new Error('Request failed with status code ' + res.status);
                    }
                }
                return res.json();
            })
            .then(async (data) => {
                if (data.auth) {
                    setAuthenicated(false)
                    resetForm()
                    const usr = await getUser();
                    setPortalUser(usr)
                    //redirect to home page
                    navigate("/")
                }
                else {
                    setMessage(data.message)
                    setButtonText(signin_button_text)
                    setAuthenicated(false)
                    resetForm()
                }
            })
            .catch((err) => {
                setMessage("Login fail - " + err)
            });
    }

    const continueAsGuest = (e) => {
        localStorage.clear();
        setPortalUser(null)
        navigate("/dataset")
    }

    const gotoForgotPassword = (e) => {
        navigate("/forgetpassword")
    }

    const gotoRegister = (e) => {
        navigate("/register")
    }

    useEffect(() => {
        //Include Google Analytics Tag
        const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
        // Google Analytics tracking code
        const head = document.querySelector("head");
        const script1 = document.createElement("script");
        script1.async = true;
        script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
        head.appendChild(script1);

        const script2 = document.createElement("script");
        script2.innerText =
            `window.dataLayer = window.dataLayer || [];\
            function gtag(){dataLayer.push(arguments);}\
            gtag('js', new Date()); \
            gtag('config', '${trackingID}');\
            gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
        head.appendChild(script2);

        // Update the document's meta tags when the component mounts
        document.title = 'Open Mortality | Login';
        document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - Login');

        // Clean up the meta tag modifications when the component unmounts
        return () => {
            document.title = '';
            document.querySelector('meta[name="description"]').setAttribute('content', '');
            head.removeChild(script1);
            head.removeChild(script2);
        };
    }, []);

    return (
        <>
            <TsParticles />

            <div className="d-flex justify-content-center" style={{ touchAction: "pan-y", height: "100%" }}>
                <Form as={Row} className="justify-content-center align-items-center" onKeyDown={handleKeyDown} style={{ zIndex: 1, width: "100%" }}>
                    <Col>
                        <Row >
                            <Col>
                                <MainLogoStatic />
                            </Col>
                        </Row>

                        <Form.Group as={Row} className="p-1 mt-1 d-flex justify-content-center" controlId="formEmail" >
                            <Form.Label column={true} xs={10} sm={10} md={2} lg={1} xl={1} className="text-sm-start text-md-end">Email</Form.Label>
                            <Col xs={10} sm={10} md={5} lg={4} xl={3}>
                                <Form.Control
                                    ref={emailInputElement}
                                    size="sm"
                                    type="email"
                                    placeholder=""
                                    name="email"
                                    isInvalid={!!errors.email}
                                    onFocus={clearError}
                                />
                                <Form.Control.Feedback type='invalid'>{errors.email}</Form.Control.Feedback>
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} column={true} className="p-1 d-flex justify-content-center" controlId="formPassword" >
                            <Form.Label column={true} xs={10} sm={10} md={2} lg={1} xl={1} className="text-sm-start text-md-end">Password</Form.Label>
                            <Col className="" xs={10} sm={10} md={5} lg={4} xl={3}>
                                <InputGroup>
                                    <Form.Control
                                        ref={passwordInputElement}
                                        size="sm"
                                        type="password"
                                        onFocus={clearError}
                                        isInvalid={!!errors.password}
                                    />
                                    {/*Device with mouse*/}
                                    <InputGroup.Text
                                        className="d-none d-sm-block"
                                        ref={passwordShowHideElement}
                                        size="sm"
                                        onMouseDown={handleShowPassword}
                                        onMouseUp={handleHidePassword}
                                        style={{ borderTopRightRadius: 3, borderBottomRightRadius: 3, cursor: 'pointer' }}
                                    >
                                        <FontAwesomeIcon ref={passwordHideIcon} icon={icon({ name: 'eye-slash' })} size="sm" style={{ display: "block", color: "black" }} />
                                        <FontAwesomeIcon ref={passwordShowIcon} icon={icon({ name: 'eye' })} size="sm" style={{ display: "none", color: "black" }} />
                                    </InputGroup.Text>
                                    {/*Device without mouse - mobile*/}
                                    <InputGroup.Text
                                        className="d-block d-sm-none"
                                        ref={passwordShowHideElementMobile}
                                        size="sm"
                                        onTouchStart={handleShowPassword}
                                        onTouchEnd={handleHidePassword}
                                        style={{ borderTopRightRadius: 3, borderBottomRightRadius: 3, cursor: 'pointer' }}
                                    >
                                        <FontAwesomeIcon ref={passwordHideIconMobile} icon={icon({ name: 'eye-slash' })} size="sm" style={{ display: "block" }} />
                                        <FontAwesomeIcon ref={passwordShowIconMobile} icon={icon({ name: 'eye' })} size="sm" style={{ display: "none" }} />
                                    </InputGroup.Text>

                                    <Form.Control.Feedback type='invalid'>{errors.password}</Form.Control.Feedback>
                                </InputGroup>
                            </Col>
                        </Form.Group>

                        {authenicated
                            ? <>
                                <Form.Group as={Row} className="p-1 d-flex justify-content-center align-items-center" controlId="formOTP" >
                                    <Form.Label column xs={10} sm={10} md={2} lg={1} xl={1} className="text-sm-start text-md-end">One time passcode</Form.Label>
                                    <Col xs={10} sm={10} md={5} lg={4} xl={3}>
                                        <Form.Control
                                            ref={otpInputElement}
                                            size="sm"
                                            type="text"
                                            name="otp"
                                            isInvalid={!!errors.email}
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.otp}</Form.Control.Feedback>
                                    </Col>
                                </Form.Group>
                            </>
                            : <><Row className=""><Col></Col></Row></>}

                        {/* Login Message */}
                        <Form.Text as={Row} className="d-flex align-items-center justify-content-center text-center" id="responseText" >
                            <Col xs={11} sm={11} md={8} lg={6} xl={6} className="mt-3">
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>{message}</ReactMarkdown>
                            </Col>
                        </Form.Text>

                        {/* Resend verification email */}
                        <Form.Text as={Row} className="mb-3 p-0 d-flex justify-content-center" style={{ visibility: ResendVemailButton }}>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="d-flex align-item-center justify-content-md-end justify-content-center">
                                <span className="d-flex align-items-center">Can't receive verification email?</span>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} className="d-flex align-item-center justify-content-md-start justify-content-center">
                                <Button size="sm" variant="link" style={{ textDecoration: "none" }} onClick={resendVerificationEmail}>Resend verification email</Button>
                            </Col>
                        </Form.Text>

                        <Form.Group as={Row} className="d-flex align-items-center justify-content-center">
                            <Col xs={10} sm={10} md={5} lg={4} xl={3} className="d-flex align-items-center justify-content-center">
                                <Button variant="dark" className="om-button black-gradient-hover-effect" onClick={handleButtonClick} >
                                    {buttonText}
                                </Button>
                            </Col>
                        </Form.Group>

                        <Row style={{ margin: "0 auto", fontSize: "0.9em" }} className="d-flex align-items-center justify-content-center">
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="mt-lg-4 mt-sm-2 d-flex justify-content-center text-center align-item-center" >
                                <a onClick={continueAsGuest} className="mt-3" style={{ textDecoration: "none", cursor: "pointer" }}>Continue as Guest</a>
                            </Col>
                        </Row >

                        <Row style={{ margin: "0 auto" }} className="d-flex align-items-center justify-content-center">
                            <Col xs={12} sm={12} md={10} lg={8} xl={8} className="mt-3 mt-md-5 p-0 ms-0 me-0" >
                                <hr className="m-1" />
                            </Col>
                        </Row>

                        <Row style={{ margin: "0 auto", fontSize: "0.9em" }} className="d-flex justify-content-center">
                            <Col xs={12} sm={12} md={5} lg={4} xl={4} className="d-flex justify-content-md-start justify-content-center">
                                <a onClick={gotoForgotPassword} className="m-1" style={{ textDecoration: "none", cursor: "pointer" }}>Reset Password</a>
                            </Col>

                            <Col xs={12} sm={12} md={5} lg={4} xl={4} className="d-flex justify-content-md-end justify-content-center">
                                <span className="d-flex align-items-center" >
                                    Need an account? <a onClick={gotoRegister} className="m-1" style={{ textDecoration: "none", cursor: "pointer" }} >Register</a>
                                </span>
                            </Col>
                        </Row>

                    </Col>
                </Form>
            </div>
        </>
    );
}

export default Login