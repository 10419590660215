import React, { useEffect } from 'react'
import { Row, Col, Container, Button, Stack } from 'react-bootstrap'

import { useCustomNavigate } from '../hooks/useCustomNavigate';

import MainLogoStatic from '../components/MainLogoStatic'
import TsParticles from "../components/ParticlesBackground";

import { useSpring, animated } from '@react-spring/web';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import "./Team.css"
import teamMembersData from '../Assets/teammember.json'; // Import the JSON file

const About = () => {

    const navigate = useCustomNavigate();

    const handleGoBack = () => {
        navigate('/')
    }

    //Set metadata
    useEffect(() => {
        //Include Google Analytics Tag
        const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
        // Google Analytics tracking code
        const head = document.querySelector("head");
        const script1 = document.createElement("script");
        script1.async = true;
        script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
        head.appendChild(script1);
        const script2 = document.createElement("script");
        script2.innerText =
            `window.dataLayer = window.dataLayer || [];\
          function gtag(){dataLayer.push(arguments);}\
          gtag('js', new Date()); \
          gtag('config', '${trackingID}');\
          gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
        head.appendChild(script2);

        // Update the document's meta tags when the component mounts
        document.title = 'Open Mortality | Our Team';
        document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - Our Team');

        // Clean up the meta tag modifications when the component unmounts
        return () => {
            document.title = '';
            document.querySelector('meta[name="description"]').setAttribute('content', '');
            head.removeChild(script1);
            head.removeChild(script2);
        };
    }, []);

    const TeamMember = ({ name, role, affiliation, photo, email, x, ig, linkedin, github, website }) => {
        return (
            <>
                <Stack direction='vertical' className='mt-2 mb-2 d-flex justify-content-center align-items-center'>
                    <div className='m-1'>
                        {
                            !!photo ? <img className="profile-picture m-1" alt={`Profile picture of ${name}`} src={`/profile_pic/${photo}`} style={{ width: "150px", height: "150px", borderRadius: "50%", objectFit: 'cover', objectPosition: 'top' }} />
                                : <img className="profile-picture m-1" alt={`Profile picture is not available for ${name}`} src={`/apple-touch-icon-180.png`} style={{ width: "150px", height: "150px", borderRadius: "50%", objectFit: 'cover', objectPosition: 'top', opacity: '5%' }} />
                        }
                    </div>
                    <h5 className='d-flex justify-content-center text-center' >{name}</h5>
                    <div className="font-weight-bold text-center">{role}</div>
                    <div className="pt-2 text-muted text-center">{affiliation}</div>

                    <div className='m-1'>
                        <Stack direction='horizontal' className='d-flex align-items-center justify-content-center'>
                            {
                                !!email ? <Button className="footer-icon p-1" variant='link' href={`mailto:${email}`} target="_blank" >
                                    <FontAwesomeIcon size="1x" icon={icon({ name: "envelope", style: 'regular' })} style={{ color: "grey" }} />
                                </Button>
                                    : ""
                            }
                            {
                                !!x ? <Button className="footer-icon p-1" variant='link' href={x} target="_blank" >
                                    <FontAwesomeIcon size="1x" icon={icon({ name: 'x-twitter', style: "brands" })} style={{ color: "grey" }} />
                                </Button>
                                    : ""
                            }
                            {
                                !!ig ? <Button className="footer-icon p-1" variant='link' href={ig} target="_blank" >
                                    <FontAwesomeIcon size="1x" icon={icon({ name: 'instagram', style: "brands" })} style={{ color: "grey" }} />
                                </Button>
                                    : ""
                            }

                            {
                                !!linkedin ? <Button className="footer-icon p-1" variant='link' href={linkedin} target="_blank" >
                                    <FontAwesomeIcon size="1x" icon={icon({ name: 'linkedin', style: "brands" })} style={{ color: "grey" }} />
                                </Button>
                                    : ""
                            }
                            {
                                !!github ? <Button className="footer-icon p-1" variant='link' href={github} target="_blank" >
                                    <FontAwesomeIcon size="1x" icon={icon({ name: 'github', style: "brands" })} style={{ color: "grey" }} />
                                </Button>
                                    : ""
                            }
                            {
                                !!website ? <Button className="footer-icon p-1" variant='link' href={website} target="_blank" >
                                    <FontAwesomeIcon size="1x" icon={icon({ name: 'globe' })} style={{ color: "grey" }} />
                                </Button>
                                    : ""
                            }



                        </Stack>
                    </div>
                </Stack>
            </>
        );
    };


    const props = useSpring({
        from: { x: -50, y: 0, opacity: 0.1 },
        to: { x: 0, y: 0, opacity: 1 },
        config: {
            mass: 5,
            friction: 70,
            tension: 270,
            precision: 0.0001,
            delay: 1
        },
    })

    return (

        <>
            <TsParticles />

            <Container className='ps-sm-1 pe-sm-1' style={{
                height: 'auto',
                width: 'auto',
                margin: '0 auto',
                touchAction: "pan-y",
            }}>

                <Row className="m-2 d-flex justify-content-center">
                    <Col style={{ zIndex: 1 }}>
                        <MainLogoStatic />
                    </Col>
                </Row>

                <Row className="m-2 d-flex justify-content-center">
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex justify-content-center" style={{ zIndex: 1 }} >
                        <h2>Meet Our Team</h2>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12} className="d-flex justify-content-center" style={{ zIndex: 1 }}>
                        <span>The Open Mortality project is led by the Centre for Global Health Research (CGHR) at Unity Health Toronto and the University of Toronto. </span>
                    </Col>
                </Row>

                <animated.div style={{ ...props }}>
                    <Row className='d-flex justify-content-center mt-2 mb-2 ms-5 me-5'>
                        {
                            teamMembersData.map((member, index) => (
                                <Col key={index} xs={12} sm={6} md={4} lg={3} xl={3} className="" style={{ zIndex: 1 }}>
                                    <TeamMember
                                        name={member.name}
                                        role={member.role}
                                        affiliation={member.affiliation}
                                        email={member.email}
                                        photo={member.photo}
                                        x={member.x}
                                        ig={member.ig}
                                        linkedin={member.linkedin}
                                        github={member.github}
                                        website={member.website}
                                    />
                                </Col>))
                        }
                    </Row>
                </animated.div>

                <Row className='m-2 d-flex align-items-center justify-content-center'>
                    <Col xs={11} sm={11} md={12} lg={12} xl={12} className="d-flex justify-content-center" style={{ zIndex: 1 }}>
                        <Button variant="secondary" className='om-button' onClick={() => handleGoBack()}>Back</Button>
                    </Col>
                </Row>
            </Container>

        </>
    );

}

export default About