import React, { useContext } from 'react'
import './AdminPortalOverlayIndicator.css'
import { contextIsAdminPortal, contextPortalUser } from '../App.js'

const AdminPortalOverlayIndicator = () => {
    const [isAdminPortal] = useContext(contextIsAdminPortal);
    const [portalUser] = useContext(contextPortalUser);

    return (
        isAdminPortal && portalUser?.role?.includes("admin") && window.location.pathname.includes('/admin')
            ? <>
                <div className='admin-portal-overlay-indicator'>
                    <span >Administration Portal</span>
                </div>

            </>
            : <></>

    )
}

export default AdminPortalOverlayIndicator