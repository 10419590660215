import React, { useState, useEffect, Profiler } from 'react';
/* react-table v7 */
import { useTable, useSortBy, useFilters, usePagination } from 'react-table';
/* to be upgraded to v8 */
import { useReactTable } from '@tanstack/react-table'

import { useParams } from 'react-router-dom';

import { Table, Form, Button, Col, Row, Card, Stack } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { useCustomNavigate } from '../hooks/useCustomNavigate';

import { api_config } from "./api_url";

const api_url = api_config.url.API_URL

const DisplayDatadict = ({ dataDict }) => {

    //const location = useLocation();
    const [data, setData] = useState([]);
    const [ColumnName, setColumnName] = useState([]);
    const [sessionStatus, setsessionStatus] = useState(false);
    //const [user, setUser] = useState(null);
    //const [askforlogintext, setLoginText] = useState([]);
    const [auth, setUserAuth] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [filterInputWithField, setFilterInputWithField] = useState({});
    const [filteredData, setFilteredData] = useState([]);

    const [filterExactMatch, setfilterExactMatch] = useState(false);

    const [filteredInput, setFilteredInput] = useState("");

    const params = useParams();
    const navigate = useCustomNavigate();

    const handleReLogin = () => {
        navigate('/Login')
    }

    //Call refresh token before access to any resources
    const refreshToken = () => {
        //console.log("refresh token")
        var cur_user = JSON.parse(localStorage.getItem('user'));
        return new Promise(async (resolve, reject) => {
            //const accessToken = loggedInUser ? JSON.parse(loggedInUser).token : "";
            fetch(api_url + '/user/refresh', {
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
            )
                .then(function (response) {
                    return response.json();
                })
                .then(function (myJson) {
                    resolve(myJson.token)
                });
        })
    }

    const handleFilterChange = (e) => {
        setFilteredInput(e.target.value)
    }

    function onRenderCallback(id, phase, actualDuration, baseDuration, startTime, commitTime) {
        if (phase === 'update' && id === 'inputProfiler') {
            // This callback will be called when your component updates
            console.log('Input interaction took', actualDuration, 'ms');
        }
    }

    useEffect(() => {
        let cols = Object.keys(dataDict.data[0])
            .map((key) => {
                return {
                    //Header: key.toUpperCase().replace("_", " "),
                    Header: key,
                    accessor: key
                };
            })
        setColumnName(cols);
        setData(dataDict.data);
        setIsLoading(false)
    }, [])

    useEffect(() => {
        if (data.length > 0) {
            const filteredData = data.filter((row) =>
                Object.entries(filterInputWithField).every(([column, value]) => {
                    if (filterExactMatch) {
                        const regex = new RegExp(`^${value}$`, 'i'); // for exact match
                        return !value || regex.test(row[column]?.toString());
                    }
                    else {
                        const partialRegex = new RegExp(`${value}`, 'i'); // for partial match
                        return !value || partialRegex.test(row[column]?.toString());
                    }
                })
            );
            setFilteredData(filteredData);
        }
    }, [data, filterInputWithField, filterExactMatch]);

    const handleColSearch = (column) => (e) => {
        setFilterInputWithField((prev) => (
            {
                ...prev,
                [column]: e.target.value
            })
        );
    }

    useEffect(() => {
        //Data filerting
        const filterkeyword = filteredInput.trim()?.toUpperCase();
        if (data?.length > 0) {
            const filteredData = data?.filter((item) => {
                return (
                    (item.column?.toUpperCase().includes(filterkeyword)) ||
                    (item.description?.toUpperCase().includes(filterkeyword)) ||
                    (item.type?.toUpperCase().includes(filterkeyword))
                )
            })
            setFilteredData(filteredData);
        }
    }, [data, filteredInput]);

    const columns = React.useMemo(() => ColumnName, [ColumnName])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        setPageSize, //Pagination
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state: { pageIndex, pageSize },
    } = useTable({
        columns,
        data: filteredData,
        initialState: { pageIndex: 0, pageSize: 10 },
    },
        useFilters, useSortBy, usePagination)
        ;

    if (auth && !isLoading) {
        return (
            <>
                {/* Select No. of entries in page */}
                <Row className='mt-3 d-flex align-items-center'>
                    {/* page navigation */}
                    <Col xs={6} sm={6} md={3} lg={3} xl={3} className="d-flex ps-3 align-items-center justify-content-sm-start justify-content-center">
                        {
                            canPreviousPage
                                ? <>
                                    <span className="me-1 justify-content-center icon-button" onClick={() => previousPage()} >
                                        <span className="me-1">
                                            <FontAwesomeIcon className='icon-container' size="lg" icon={icon({ name: 'angle-left' })} />
                                        </span>
                                        <span className='me-1 button-text'>Previous</span>
                                    </span>
                                </>
                                : <>
                                    <span className="me-1 justify-content-sm-start justify-content-center icon-button">
                                        <span className='me-1'>
                                            <FontAwesomeIcon size="lg" icon={icon({ name: "angle-left" })} style={{ color: "grey" }} />
                                        </span>
                                        <span className='me-1 text-secondary'>Previous</span>
                                    </span>
                                </>
                        }
                        {
                            canNextPage
                                ? <>
                                    <span className="d-flex align-items-center justify-content-center icon-button" onClick={() => nextPage()} >
                                        <span className='ms-1 button-text'>Next</span>
                                        <span className="ms-1">
                                            <FontAwesomeIcon className='icon-container' size="lg" icon={icon({ name: 'angle-right' })} />
                                        </span>
                                    </span>
                                </>
                                : <>
                                    <span className='ms-1 d-flex align-items-center justify-content-center icon-button'>
                                        <span className='ms-1 text-secondary'>Next</span>
                                        <span className="ms-1">
                                            <FontAwesomeIcon size="lg" icon={icon({ name: "angle-right" })} style={{ color: "grey" }} />
                                        </span>
                                    </span>
                                </>
                        }
                    </Col>

                    {/*Search Data Dictionary*/}
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} className="justify-content-start">
                        <div className='d-flex flex-1'>
                            <FontAwesomeIcon className="ms-0 pe-2 mt-2 mb-2" size="sm" icon={icon({ name: 'magnifying-glass' })} />
                            <Form.Control
                                className="shadow-none"
                                size="sm"
                                type="text"
                                placeholder="Search..."
                                onChange={handleFilterChange}
                                value={filteredInput}
                                style={{ borderRadius: '0px', border: 'none', borderBottom: '1px gray solid' }}
                            />
                        </div>
                    </Col>

                    {/* Row and Column count */}
                    <Col xs={12} sm={12} md={3} lg={3} xl={3} className="d-flex pt-sm-2 justify-content-center justify-content-sm-center justify-content-md-end">
                        <span className='totalColumnCount d-flex align-items-center'>
                            {`${Object.keys(data[0])?.length?.toLocaleString('en-US')} columns`}&nbsp;/&nbsp;
                        </span >
                        <span className='totalRecordCount d-flex align-items-center'>
                            {`${Object.entries(data)?.length?.toLocaleString('en-US')} rows`}
                        </span>
                    </Col>
                </Row>

                {
                    filteredData.length > 0
                        ? <>
                            <div style={{ overflowX: 'auto' }} className="ms-2 me-2">
                                <Table borderless {...getTableProps()} className="table-data-viewing table-data-dict-viewing">
                                    <thead >
                                        {headerGroups.map((headerGroup) => (
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                {
                                                    headerGroup.headers.map((column) => (
                                                        <th {...column.getHeaderProps()} >
                                                            {column.render('Header')}
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                        ))}
                                    </thead>

                                    <tbody {...getTableBodyProps()} >
                                        {
                                            page.map(row => {
                                                prepareRow(row)
                                                return (
                                                    <tr {...row.getRowProps()} >
                                                        {row.cells.map((cell) => {
                                                            return <td {...cell.getCellProps()} >{cell.render('Cell')}</td>
                                                        })}

                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </>
                        : <>
                            <div className="d-flex justify-content-center">
                                <Card key="no-data-in-dataset" className='info-display-card'>
                                    <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                                        <Stack>
                                            <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} />
                                            <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>{`No entry found with this keyword in the data dictionary - ***_${filteredInput}_***`}</ReactMarkdown>
                                        </Stack>
                                    </Card.Body>
                                </Card>
                            </div>
                        </>
                }



            </>
        )
    }
    else if ((data.token) && (data.login) && (!data.permission)) {
        return (
            <>
                <Row className='m-1 align-items-center justify-content-center text-center' >
                    <Card style={{ width: '58rem', marginTop: "100px" }}>
                        <Card.Body>
                            <Card.Text>
                                <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} /><br /><br />
                                <span className=''>Permission denied. Please contact the Open Mortality Portal Administrator at support@openmortality.org.</span><br /><br />
                                <Button variant='link' href='/login' style={{ textDecoration: "none" }}>Login</Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </>

        )
    }
    else if ((!data.token) && (data.login)) {
        return (
            <>
                <Row className='m-1 align-items-center justify-content-center text-center' >
                    <Card style={{ width: '58rem', marginTop: "100px" }}>
                        <Card.Body>
                            <Card.Text>
                                <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} /><br /><br />
                                <span className=''> {data.message}</span><br /><br />
                                <Button variant='link' href='/login' style={{ textDecoration: "none" }}>Login</Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </>
        )
    }
    else if ((data.token) && (!data.login)) {
        return (
            <div>
                <h5>
                    {data.message}
                </h5>
            </div>
        )
    }
    else if (isLoading) {
        return (
            <>
                <div className="d-flex justify-content-center" style={{ marginTop: "50px" }}>
                    <Spinner animation="border" role="status" >
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            </>
        )
    }
    else {
        return (
            <>
                <Row className='m-1 align-items-center justify-content-center text-center' >
                    <Card style={{ width: '58rem', marginTop: "20px" }}>
                        <Card.Body>
                            <Card.Text>
                                <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} /><br /><br />
                                <span className=''>There is problem in retrieving data, please refresh the page</span><br /><br />
                                <Button variant='link' href='/login' style={{ textDecoration: "none" }}>Login</Button>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </>

        )
    }
};

export default DisplayDatadict;