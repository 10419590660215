import React, { useState, useContext, useRef } from 'react';

import { api_config } from "./api_url";

import { Col, Row, Card, Button, Form, Stack } from 'react-bootstrap';

import { contextIsAdminPortal, contextPortalUser } from '../App.js'

import { useCustomNavigate } from '../hooks/useCustomNavigate';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { DATA_MD_ABOUT_EXTRACT_REGEX, DATA_MD_EXTRACT_ITALIC_REGEX, DATA_MD_EXTRACT_LINK_REGEX } from '../constants/regex';

import { status_draft, permission_admin } from '../constants/omp_constant.js';

import { input_msg_ds_title, input_msg_ds_title_abbr, input_msg_ds_publish_date, input_msg_ds_description, status_error_msg } from '../constants/omp_ui_text.js';

//import ReactMarkdown from 'react-markdown';
//import remarkGfm from 'remark-gfm';

const DatasetCard = ({ dataset, dataset_meta, isEditing, editdsid, handleSaveDataset, handleEditDataset, handleCancelEditDataset, handleDeleteDataset }) => {
    const [isAdminPortal] = useContext(contextIsAdminPortal);
    const [portalUser] = useContext(contextPortalUser);

    //const [keyword, setKeyword] = useState('');    
    let api_url = api_config.url.API_URL
    const [errors, setErrors] = useState({});
    const [validated, setValidated] = useState(false);
    const [ds_status, set_ds_status] = useState(dataset?.status || "status");
    const [isLoading, setIsLoading] = useState(false);

    const ds_title_input_element = useRef();
    const ds_title_abbr_input_element = useRef();
    const ds_publish_date_input_element = useRef();
    const ds_description_input_element = useRef();

    const navigate = useCustomNavigate();

    const validateForm = (e) => {
        const newErrors = {}
        if (!ds_title_input_element.current?.value || ds_title_input_element.current?.value === '') newErrors.title = input_msg_ds_title
        if (!ds_title_abbr_input_element.current?.value || ds_title_abbr_input_element.current?.value === '') newErrors.title_abbr = input_msg_ds_title_abbr
        if (!ds_publish_date_input_element.current?.value || ds_publish_date_input_element.current?.value === '') newErrors.publish_date = input_msg_ds_publish_date
        if (!ds_description_input_element.current?.value || ds_description_input_element.current?.value === '') newErrors.description = input_msg_ds_description
        if (ds_status === 'status') newErrors.status = status_error_msg

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors)
            setValidated(false)
        } else {
            e.preventDefault()
            handleSaveDataset(dataset.dataset_id, ds_title_input_element.current?.value, ds_title_abbr_input_element.current?.value.toLowerCase(),
                ds_description_input_element.current?.value, ds_publish_date_input_element.current?.value, ds_status)
        }
    }

    const clearError = () => {
        setErrors("")
        setValidated()
    }

    const handleStatusChange = (e) => {
        clearError()
        set_ds_status(e.target.value)
    }

    const setTodayasPublishDate = () => {
        const today = new Date(Date.now());
        const current_date_time =
            today.getFullYear().toString() + "-" +
            (today.getMonth() + 1).toString().padStart(2, '0') + "-" +
            today.getDate().toString().padStart(2, '0') + " " +
            today.getHours().toString().padStart(2, '0') + ":" +
            today.getMinutes().toString().padStart(2, '0') + ":" +
            today.getSeconds().toString().padStart(2, '0');
        ds_publish_date_input_element.current.value = current_date_time
    }

    const getCurrentDateTime = () => {
        const today = new Date(Date.now());

        const current_date_time =
            today.getFullYear().toString() + "-" +
            (today.getMonth() + 1).toString().padStart(2, '0') + "-" +
            today.getDate().toString().padStart(2, '0') + " " +
            today.getHours().toString().padStart(2, '0') + ":" +
            today.getMinutes().toString().padStart(2, '0') + ":" +
            today.getSeconds().toString().padStart(2, '0');

        return current_date_time
    }

    const handleViewDataset = (dataset) => {
        //force to convert dataset info to a array in order to cater a data may exist in multiple dataset when doing a reverse query
        const ds = [];
        ds.push(dataset)

        //Passing dataset name using state
        navigate("/dataset/" + dataset.title_abbr.toLowerCase(), {
            state: {
                dataset: ds
            }
        });

    };

    return (
        <>
            <Card className="mt-0 data-listing-card shadow-sm" >
                <Card.Body className="pt-3 data-listing-card-body" >
                    <Card.Title className='m-0 pb-2 data-listing-card-title d-flex align-items-center '>
                        <div className='flex-grow-1 justify-content-start'>
                            {
                                isAdminPortal && portalUser?.role?.split(",")?.includes(permission_admin) &&
                                    (isEditing && editdsid === dataset.dataset_id) &&
                                    Object.entries(dataset).length > 0
                                    ? <>
                                        <Row className='d-flex align-items-center'>
                                            {isAdminPortal && portalUser?.role?.split(",")?.includes(permission_admin)
                                                ? dataset.dataset_id.toString().match(/[A-Za-z]/g)?.length > 0
                                                    ? <>
                                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className='mt-1 mb-1'>
                                                            New Data Collection
                                                        </Col>
                                                        <Col xs={8} sm={8} md={8} lg={8} xl={8} className='mt-1 mb-1'>
                                                            <code className='ms-0 mt-2 d-flex justify-content-end om-red-text text-sm' style={{ fontSize: "0.9rem" }}>
                                                                <span className='text-muted'>Dataset ID (Temp): {dataset.dataset_id}</span>
                                                            </code></Col>
                                                    </>
                                                    : <>
                                                        <div className='ms-0 mt-2 mb-1 d-flex text-capitalize justify-content-end font-monospace om-red-text text-sm' style={{ fontSize: "1rem" }}>
                                                            Dataset ID:{dataset.dataset_id}&nbsp;({dataset.status.trim()})
                                                        </div>
                                                    </>
                                                : <></>
                                            }
                                        </Row>

                                        <Row className='d-flex align-items-center'>
                                            <Col xs={6} sm={6} md={6} lg={6} xl={6} className='mt-1'>
                                                <Form.Control
                                                    required
                                                    ref={ds_title_input_element}
                                                    size="sm"
                                                    type="text"
                                                    placeholder="Dataset Name"
                                                    name="dataset_name"
                                                    isInvalid={!!errors.title}
                                                    onFocus={clearError}
                                                    defaultValue={dataset.title ? dataset.title : ""}
                                                />
                                                <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.title}</Form.Control.Feedback>
                                            </Col>

                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='mt-1'>
                                                <Form.Control
                                                    required
                                                    ref={ds_title_abbr_input_element}
                                                    size="sm"
                                                    type="text"
                                                    placeholder="Dataset Abbreviation"
                                                    name="dataset_abbr"
                                                    isInvalid={!!errors.title_abbr}
                                                    onFocus={clearError}
                                                    defaultValue={dataset.title_abbr ? dataset.title_abbr.toUpperCase() : ""}
                                                />
                                                <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }} >{errors.title_abbr}</Form.Control.Feedback>
                                            </Col>

                                            <Col xs={3} sm={3} md={3} lg={3} xl={3} className='d-flex justify-content-end'>
                                                <Form.Select
                                                    onChange={e => handleStatusChange(e)}
                                                    isInvalid={!!errors.status}
                                                    className="text-capitalize"
                                                    size="sm"
                                                    defaultValue={dataset.status ? dataset?.status?.trim() : ds_status}
                                                >
                                                    <option value="status">status</option>
                                                    <option value="published">published</option>
                                                    <option value="draft">draft</option>

                                                </Form.Select>
                                                <Form.Control.Feedback type='invalid' style={{ fontSize: '0.9rem', fontWeight: 'normal' }}>{errors.status}</Form.Control.Feedback>
                                            </Col>

                                        </Row>
                                    </>
                                    : <>
                                        <Row className='d-flex align-items-center '>
                                            <Col xs={12} sm={12} md={12} lg={7} xl={7} className='text-start'>
                                                <span>{dataset.title ? dataset.title : ""} {dataset.title_abbr ? `(${dataset.title_abbr.toUpperCase()})` : ""}</span>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={5} xl={5} className='text-sm-start text-lg-end'>
                                                {
                                                    isAdminPortal && portalUser?.role.split(",")?.includes(permission_admin)
                                                        ? <>
                                                            <code className='ms-0 mt-2 text-capitalize om-red-text text-sm' style={{ fontSize: "0.9rem" }}>
                                                                Dataset ID:{dataset.dataset_id}&nbsp;({dataset.status.trim()})
                                                            </code>
                                                        </>
                                                        : <></>
                                                }
                                            </Col>
                                        </Row>
                                    </>
                            }
                        </div>
                    </Card.Title>

                    <Card.Text className='m-0 pb-2' style={{ color: "#6c757d" }}>
                        {(isEditing && editdsid === dataset.dataset_id)
                            ?
                            <>
                                <Row className='d-flex align-items-center'>
                                    <Col xs={9} sm={9} md={9} lg={9} xl={9} className=''>
                                        <div className='d-flex align-items-center'>
                                            <FontAwesomeIcon size="1x" icon={icon({ name: 'calendar', style: 'regular' })} />&nbsp;&nbsp;
                                            <Form.Control
                                                required
                                                ref={ds_publish_date_input_element}
                                                size="sm"
                                                type="text"
                                                placeholder="Publish Date"
                                                name="publish_date"
                                                isInvalid={!!errors.publish_date}
                                                onFocus={clearError}
                                                defaultValue={dataset.publish_date ? dataset.publish_date.slice(0, 19).replace('T', ' ') : getCurrentDateTime()}
                                            />
                                            <Form.Control.Feedback className="ms-2" type='invalid'>{errors.publish_date}</Form.Control.Feedback>
                                        </div>
                                    </Col>
                                    <Col xs={3} sm={3} md={3} lg={3} xl={3} className='d-flex justify-content-center'>
                                        <Button
                                            className='om-admin-button border-0'
                                            size='sm'
                                            onFocus={clearError}
                                            onClick={setTodayasPublishDate}>Now</Button>
                                    </Col>
                                </Row>
                            </>
                            : dataset.publish_date
                                ? <>
                                    <span>
                                        <FontAwesomeIcon
                                            size="1x"
                                            icon={icon({ name: 'calendar', style: 'regular' })}
                                        /> {dataset.publish_date.slice(0, 10)}
                                    </span>
                                </>
                                : ""
                        }
                    </Card.Text>

                    <Card.Text className='custom-card-text mb-3'>
                        <span>
                            {
                                (isEditing && editdsid === dataset.dataset_id)
                                    ? <>
                                        <Form.Control
                                            as="textarea"
                                            required
                                            ref={ds_description_input_element}
                                            size="sm"
                                            rows={5}
                                            placeholder="Description"
                                            name="dataset_desc"
                                            isInvalid={!!errors.description}
                                            onFocus={clearError}
                                            defaultValue={
                                                dataset.dataset_desc
                                                    ? dataset.dataset_desc?.match(DATA_MD_ABOUT_EXTRACT_REGEX)
                                                        ? dataset.dataset_desc.match(DATA_MD_ABOUT_EXTRACT_REGEX)[1]
                                                        : dataset.dataset_desc
                                                    : ""
                                            }
                                        />
                                        <Form.Control.Feedback type='invalid'>{errors.description}</Form.Control.Feedback>
                                    </>
                                    : dataset.dataset_desc?.match(DATA_MD_ABOUT_EXTRACT_REGEX)
                                        ? dataset.dataset_desc.match(DATA_MD_ABOUT_EXTRACT_REGEX)[1]
                                        : dataset.dataset_desc
                            }
                        </span>
                    </Card.Text>

                    {/*View dataset Button*/}
                    <Col xs={12} sm={12} md={3} lg={4} xl={3}>
                        <div className="d-grid">
                            {
                                (isEditing && editdsid === dataset.dataset_id)
                                    ? ""
                                    : <Button className="om-button black-gradient-hover-effect" variant="dark" onClick={() => handleViewDataset(dataset)}>
                                        <span className="ps-1 pe-1 ms-1">View Dataset </span>
                                        <FontAwesomeIcon size="1x" icon={icon({ name: 'arrow-right' })} />
                                    </Button>
                            }

                        </div>
                    </Col>


                    {/*Render content management control in Admin portal - dataset editing tool*/}
                    {
                        //Admin portal, User has Admin right, not editing, non new dataset
                        isAdminPortal && portalUser?.role.split(",")?.includes(permission_admin)
                            ? dataset.dataset_id !== editdsid
                                ?
                                <>
                                    {/* Dataset Update Information*/}
                                    <Row className='pt-1 align-items-center justify-content-sm-start justify-content-md-end'>
                                        <Col xs={5} sm={3} md={3} lg={3} xl={3} className='ps-sm-0 pe-0 text-sm-start text-md-end'>
                                            <code className='om-red-text'>Last Updated By: </code>
                                        </Col>
                                        <Col xs={7} sm={9} md={"auto"} lg={"auto"} xl={"auto"} className='ps-0 pe-3 text-sm-start text-md-end'>
                                            <code className='om-red-text'><strong>
                                                {
                                                    dataset.last_updated_by && dataset_meta[dataset.last_updated_by] !== undefined && dataset_meta[dataset.last_updated_by] !== null
                                                        ? <a href={`mailto:${dataset_meta[dataset.last_updated_by]?.email}`}>{`${dataset_meta[dataset.last_updated_by].firstname} ${dataset_meta[dataset.last_updated_by].lastname}`}</a>
                                                        : dataset.last_updated_by !== null && dataset.last_updated_by !== undefined && dataset.last_updated_by !== ""
                                                            ? `${dataset.last_updated_by} (uid)`
                                                            : "--"
                                                }
                                            </strong></code>
                                        </Col>

                                        <Col xs={5} sm={3} md={"auto"} lg={"auto"} xl={"auto"} className='ps-sm-0 pe-0 text-sm-start text-md-end'>
                                            <code className='om-red-text'>Date: </code>
                                        </Col>
                                        <Col xs={7} sm={9} md={"auto"} lg={"auto"} xl={"auto"} className='ps-0 text-sm-start text-md-end'>
                                            <code className='om-red-text'>
                                                <strong>{dataset.last_updated_by ? dataset.last_updated_date?.slice(0, 19)?.replace("T", " ") : "--"}</strong>
                                            </code>
                                        </Col>
                                    </Row>

                                    <Row className='pt-1 align-items-center justify-content-sm-start justify-content-md-end'>
                                        <Col xs={5} sm={3} md={3} lg={3} xl={3} className='ps-sm-0 pe-0 text-sm-start text-md-end'>
                                            <code className='om-red-text'>Created By: </code>
                                        </Col>
                                        <Col xs={7} sm={9} md={"auto"} lg={"auto"} xl={"auto"} className='ps-0 pe-3 text-sm-start text-md-end'>
                                            <code className='om-red-text'><strong>{
                                                dataset?.created_by && dataset_meta[dataset.created_by] !== undefined && dataset_meta[dataset.created_by] !== null
                                                    ? <a href={`mailto:${dataset_meta[dataset.created_by]?.email}`}>{`${dataset_meta[dataset?.created_by]?.firstname} ${dataset_meta[dataset.created_by]?.lastname}`}</a>
                                                    : dataset.created_by !== null && dataset.created_by !== undefined && dataset.created_by !== ""
                                                        ? `${dataset.created_by} (uid)`
                                                        : "--"
                                            }</strong></code>
                                        </Col>
                                        <Col xs={5} sm={3} md={"auto"} lg={"auto"} xl={"auto"} className='ps-sm-0 pe-0 text-sm-start text-md-end'>
                                            <code className='om-red-text'>Date: </code>
                                        </Col>
                                        <Col xs={7} sm={9} md={"auto"} lg={"auto"} xl={"auto"} className='ps-0 text-sm-start text-md-end'>
                                            <code className='om-red-text'><strong>{dataset.create_date ? dataset.create_date?.slice(0, 19)?.replace("T", " ") : "--"}</strong></code>
                                        </Col>
                                    </Row>




                                    <Row className='p-2 d-flex align-items-center justify-content-end'>
                                        <Col xs={12} sm={12} md={3} lg={2} xl={2} className='mt-2 d-flex justify-content-end'>
                                            <Button className='om-admin-button border-0' size="sm" onClick={() => handleEditDataset(dataset.dataset_id)}>
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'pen' })} />&nbsp;Edit
                                            </Button>
                                        </Col>
                                        <Col xs={12} sm={12} md={3} lg={2} xl={2} className='mt-2 d-flex justify-content-end'>
                                            <Button className='om-admin-button border-0' disabled={!!editdsid} size="sm" onClick={() => handleDeleteDataset(dataset.dataset_id)}>
                                                <FontAwesomeIcon size="1x" icon={icon({ name: 'trash' })} />&nbsp;Delete
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                                : <></>
                            : <></>
                    }
                    {
                        //Admin portal, User has Admin right, show when a new/exisiting dataset is editing
                        isAdminPortal && portalUser?.role.split(",")?.includes(permission_admin) && isEditing && dataset.dataset_id === editdsid
                            ?
                            <Row className='p-2 d-flex align-items-center justify-content-end'>
                                <Col xs={12} sm={12} md={3} lg={2} xl={2} className='mt-2 d-flex justify-content-end'>
                                    <Button className='om-admin-button border-0' size="sm" onClick={e => validateForm(e)}>
                                        <FontAwesomeIcon size="1x" icon={icon({ name: 'floppy-disk' })} />&nbsp;Save
                                    </Button>
                                </Col>
                                <Col xs={12} sm={12} md={3} lg={2} xl={2} className='mt-2 d-flex justify-content-end'>
                                    <Button className='om-admin-button border-0' size="sm" onClick={() => handleCancelEditDataset(dataset.dataset_id)}>
                                        <FontAwesomeIcon size="1x" icon={icon({ name: 'circle-xmark' })} />&nbsp;Cancel
                                    </Button>
                                </Col>
                            </Row>
                            : <></>
                    }

                    {/*<Col xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex align-items-center justify-content-end'>
                        <Row>
                            {
                                isAdminPortal && loggedInUser?.role.split(",")?.includes(permission_admin)
                                    ? dataset.dataset_id.toString().match(/[A-Za-z]/g)?.length > 0
                                        ? <div className='ms-0 mt-2 d-flex justify-content-end font-monospace om-red-text text-sm'>
                                            <span className='text-muted'>Temporary Dataset ID: {dataset.dataset_id}</span>
                                        </div>
                                        : <div className='ms-0 mt-2 d-flex justify-content-end font-monospace om-red-text text-sm'>Dataset ID: {dataset.dataset_id}</div>
                                    : <></>
                            }
                        </Row>
                        </Col>*/}
                </Card.Body >
            </Card >
        </>
    )
}

export default DatasetCard