import React, { useState, useEffect, useContext } from "react";
import HeaderNew from "../components/HeaderNew";
import { v4 as uuidv4 } from 'uuid';

import { Col, Row, Stack, Button, Form, Modal, Card, Spinner } from 'react-bootstrap';
import { useCustomNavigate } from '../hooks/useCustomNavigate';
import { api_config } from "../components/api_url";
import { KEYWORDS_REGEX } from '../constants/regex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { contextPortalUser, contextIsAdminPortal } from "../App";

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const UserAccount = () => {
  const navigate = useCustomNavigate();
  const api_url = api_config.url.API_URL

  const [show, setShow] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [userInfoUpdMsg, setUserInfoUpdMsg] = useState("");
  const [updateDisable, setUpdateDisable] = useState(true);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState();
  const [refreshAccessTokenResult, setRefreshAccessTokenResult] = useState({});

  const [keyword, setKeyword] = useState('');

  const [portalUser, setPortalUser] = useContext(contextPortalUser);
  const [isAdminPortal] = useContext(contextIsAdminPortal);


  //Set metadata
  useEffect(() => {
    //Include Google Analytics Tag
    const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
    // Google Analytics tracking code
    const head = document.querySelector("head");
    const script1 = document.createElement("script");
    script1.async = true;
    script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
    head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerText =
      `window.dataLayer = window.dataLayer || [];\
        function gtag(){dataLayer.push(arguments);}\
        gtag('js', new Date()); \
        gtag('config', '${trackingID}');\
        gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
    head.appendChild(script2);

    // Update the document's meta tags when the component mounts
    document.title = 'Open Mortality | User Information';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - User Information');

    // Clean up the meta tag modifications when the component unmounts
    return () => {
      document.title = '';
      document.querySelector('meta[name="description"]').setAttribute('content', '');
      head.removeChild(script1);
      head.removeChild(script2);
    };
  }, []);

  useEffect(() => {
    const checkUserSession = async () => {
      const result = await refreshAccessToken();
      setRefreshAccessTokenResult(result);
      setForm(portalUser)
    }

    checkUserSession()
  }, [isAdminPortal]);

  const labels = {
    email: 'Email',
    firstname: 'First Name',
    lastname: 'Last Name',
    research_keywords: 'Research Keywords',
    research_purpose: 'Research Purpose'
  };

  const validateUserInfo = () => {
    const newErrors = {}

    Object.keys(form)
      .filter(key => !/(username|research_purpose)/.test(key))
      .map(field => {
        if (form[field] === "" || form[field] === null) newErrors[field] = 'Please enter ' + field.replaceAll("_", " ")
      })

    if (!KEYWORDS_REGEX.test(form.research_keywords)) newErrors['research_keywords'] = 'Please input at least 5 keywords separated by space'

    return newErrors
  }

  const displayFields = ['email', 'firstname', 'lastname', 'research_keywords', 'research_purpose'];

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null
      })

  }

  const handleInput = (e, key) => {
    setErrors({})
    setUserInfoUpdMsg("")
    setUpdateDisable(false)
    setField(key, e.target.value)
  }

  const handleCancel = (e) => {
    setUpdateDisable(true)
    //const UserInfo = JSON.parse(loggedInUser);
    //setForm(UserInfo);
    setForm(portalUser);
    setErrors({})
  }

  const handleLogin = () => {
    navigate("/Login")
  }

  const handleClose = () => setShow(false);

  //Call refresh token before access to any resources  
  const refreshAccessToken = () => {
    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/user/refresh", {
        method: "GET",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(res => res.json())
        .then(res_json => {
          resolve(res_json)
        });
    })
  }

  const updateInfo = (updating_form) => {
    //User Info Update API
    delete updating_form['token'];
    fetch(api_url + '/user/' + updating_form.uid, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(updating_form),
    })
      .then(res => res.json())
      .then(res_json => {
        setUserInfoUpdMsg("User information updated")
        setForm(res_json.data)
        //setLoggedInUser(res_json.data)
        setPortalUser(res_json.data)
        setUpdateDisable(true)
        setShow(true)
      })
      .catch((err) => {
        setUserInfoUpdMsg("Error on user information updating - " + err)
      });
  }

  const processUserInfoUpdate = (e) => {
    setUserInfoUpdMsg("")
    e.preventDefault();
    e.stopPropagation();

    const formsErrors = validateUserInfo()
    if (Object.keys(formsErrors).length > 0) {
      setErrors(formsErrors)
    }
    else {
      updateInfo(form)
      setValidated(false);
    }
  }

  return (
    <>
      <HeaderNew
        isLoading={loading}
        CartCount={JSON.parse(localStorage?.getItem("cart")) ? JSON.parse(localStorage.getItem("cart"))?.length : 0}
        dataset={null}
        setDatasets={null}
        data={null}
        keyword={keyword}
        setKeyword={setKeyword}
      />
      {!!refreshAccessTokenResult?.auth === true
        ?
        <>
          <Modal centered show={show} onHide={handleClose} size="sm" >
            <Modal.Body className="d-flex justify-content-center">{userInfoUpdMsg}</Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button variant="dark" className="om-button black-gradient-hover-effect" onClick={handleClose}>Close</Button>
            </Modal.Footer>
          </Modal>
          <h3 className="mt-5 mb-3 d-flex align-items-center justify-content-center" >User Information</h3>
          <Form noValidate validated={validated} >
            {
              form
                ? Object.keys(form)
                  .filter(key => !/(role|username|uid|password|register_date_time|status|toc_agree|last_update_date)/.test(key))
                  .map((key, index) => {

                    return (
                      <Form.Group
                        key={uuidv4 + index}
                        as={Row}
                        controlId={"UserInfoForm_" + key}
                        className="d-flex justify-content-center align-items-center pb-2"
                      >
                        <Form.Label column xs={10} sm={10} md={3} lg={2} xl={2} className="d-flex justify-content-sm-start justify-content-md-end" >{labels[key]}</Form.Label>

                        <Col xs={10} sm={10} md={5} lg={5} xl={5}>
                          <Form.Control
                            rows={(key === 'research_keywords') || (key === 'research_purpose') ? "6" : ""}
                            disabled={(key === 'uid') || (key === 'email') || (key === 'register_date_time') ? true : false}
                            required
                            as={(key === 'research_keywords') || (key === 'research_purpose') ? "textarea" : "input"}
                            type="text"
                            placeholder={labels[key]}
                            name={form[key]}
                            value={form[key] ? form[key] : ""}
                            isInvalid={!!errors[key]}
                            onChange={e => handleInput(e, key)}
                          />

                          <Form.Control.Feedback type='invalid'>{errors[key]}</Form.Control.Feedback>
                        </Col>

                      </Form.Group>
                    )
                  })
                : (() => {
                  return (<><div>Error on loading user information</div></>)
                })
            }


            <Row className="d-flex justify-content-center">
              <Col xs={10} sm={10} md={5} lg={5} xl={5} className="d-flex justify-content-center justify-content-md-end m-1">
                <Button className="om-button black-gradient-hover-effect" variant="dark" disabled={updateDisable} onClick={e => processUserInfoUpdate(e)}>Update</Button>
              </Col>

              <Col xs={10} sm={10} md={5} lg={5} xl={5} className="d-flex justify-content-center justify-content-md-start m-1">
                <Button className="om-button black-gradient-hover-effect" variant="dark" disabled={updateDisable} onClick={e => handleCancel(e)}>Cancel</Button>
              </Col>
            </Row>
          </Form>
        </>
        :
        refreshAccessTokenResult?.auth === false
          ?
          <>
            <div className="d-flex justify-content-center">
              <Card className='info-display-card'>
                <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                  <Stack direction="vertical" gap={1}>
                    <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} />
                    <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>{refreshAccessTokenResult?.message}</ReactMarkdown>
                  </Stack>
                  <Button className="om-button" size="sm" variant='dark' style={{ textDecoration: "none" }} onClick={handleLogin}>Login</Button>
                </Card.Body>
              </Card>
            </div>
          </>
          : <></>
      }
    </>

  )
}

export default UserAccount