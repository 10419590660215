import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { Col, Row, Card, Stack, Button, Spinner, Form, Dropdown, ButtonGroup } from 'react-bootstrap';

import { api_config } from "./api_url";

import { useSpring, animated } from '@react-spring/web';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { useCustomNavigate } from '../hooks/useCustomNavigate';

import { contextIsAdminPortal, contextPortalUser } from '../App.js'

//import { DATA_MD_ABOUT_EXTRACT_REGEX, DATA_MD_EXTRACT_ITALIC_REGEX, DATA_MD_EXTRACT_LINK_REGEX } from '../constants/regex';
import { permission_admin, permission_user, status_draft } from '../constants/omp_constant.js';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import DatasetCard from './DatasetCard';
import HeaderNew from './HeaderNew';

import "./ListDataset.css"
import { toast } from 'react-toastify';

const ListDatasets = () => {
    const [isAdminPortal] = useContext(contextIsAdminPortal);
    const [portalUser] = useContext(contextPortalUser);

    let api_url = api_config.url.API_URL
    //const [api_url, setApiUrl] = useState(api_config.url.API_URL)

    const navigate = useCustomNavigate();

    const [datasets, setDatasets] = useState([]);
    const [dataset_meta, setDatasetMeta] = useState({});
    const [showOnlyMyCreatedDs, setShowOnlyMyCreatedDs] = useState();
    const [originalDataset, setOriginalDataset] = useState([]);
    const [sort_ds_config, set_sort_ds_config] = useState({});
    const [keyword, setKeyword] = useState('');

    const [isEditing, SetIsEditing] = useState();
    const [editdsid, setEditDsId] = useState();

    const [isLoading, setIsLoading] = useState(true);

    const [cart, setCart] = useState([]);

    const location = useLocation();
    let temp_new_dataset_id = '';

    let localCart;

    /* ----------------- */
    /*  Admin Functions  */
    /* ----------------- */
    let new_dataset = {
        contact: "",
        data_id: "",
        dataset_desc: "",
        dataset_id: "",
        last_updated_date: "",
        permission: "",
        publish_date: "",
        status: status_draft,
        tag: "",
        title: "",
        title_abbr: "",
        created_by: "",
        create_date: "",
        last_updated_by: ""
    }

    const refreshToken = () => {
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/user/refresh", {
                method: "GET",
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((res_json) => {
                    resolve(res_json)
                });
        })
    }

    /*******************/
    /* Admin Functions */
    /*******************/
    const addDataset = async (new_dataset) => {
        //Refresh access token
        const accessToken = await refreshToken();
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/admin/dataset", {
                method: 'POST',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                },
                body: JSON.stringify(new_dataset)
            })
                .then((res) => res.json())
                .then((myJson) => {
                    if (myJson?.result)
                        resolve(myJson.data)
                    else
                        reject(myJson)
                });
        });
    }

    const updateDataset = async (update_dataset) => {
        //Refresh access token
        const accessToken = await refreshToken();
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/admin/dataset/" + update_dataset.dataset_id, {
                method: 'PATCH',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                },
                body: JSON.stringify(update_dataset)
            })
                .then((res) => res.json())
                .then((myJson) => {
                    if (myJson.result)
                        resolve(myJson)
                    else
                        reject(myJson)
                });
        });
    }

    const deleteDataset = async (dataset_id) => {
        //Refresh access token
        const accessToken = await refreshToken();
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/admin/dataset/" + dataset_id, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                }
            })
                .then((res) => res.json())
                .then((myJson) => {
                    if (myJson.result)
                        resolve(myJson)
                    else
                        reject(myJson)
                });
        });
    }

    const deleteData = async (data_id) => {
        //Refresh access token
        const accessToken = await refreshToken();

        return new Promise(async (resolve, reject) => {
            fetch(`${api_url}/admin/data/${data_id}`, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                }
            })
                .then((res) => res.json())
                .then((myJson) => {
                    if (myJson?.status) {
                        resolve(myJson)
                    } else
                        reject(myJson)
                });
        });
    }

    const dropDataTable = async (data_table) => {
        //Refresh access token
        const accessToken = await refreshToken();
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/admin/datatable/" + data_table, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                }
            })
                .then((res) => res.json())
                .then((myJson) => {
                    if (myJson?.status)
                        resolve(myJson)
                    else
                        reject(myJson)
                });
        });
    }

    const deleteDataInFolder = async (data_name) => {
        //Refresh access token
        const accessToken = await refreshToken();
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/admin/folderdata/" + data_name, {
                method: 'DELETE',
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                }
            })
                .then((res) => res.json())
                .then((myJson) => {
                    if (myJson?.status)
                        resolve(myJson)
                    else
                        reject(myJson)
                });
        });
    }

    const handleDatasetSort = async (unsorted_dataset, desired_sort_by_field, desired_sort_order, showOnlyMyCreatedDs) => {
        return new Promise(async (resolve, reject) => {
            const default_sort_by_field = 'title'
            const default_sort_order = 'asc'

            let filteredDatasets;

            //Initialze sort config to default values i.e. sort by `title` and sort in ascending order            
            if (desired_sort_order === '' || desired_sort_order === null || desired_sort_order === undefined || (sort_ds_config.sort_by_field !== desired_sort_by_field))
                desired_sort_order = default_sort_order

            if (desired_sort_by_field === '' || desired_sort_by_field === null || desired_sort_by_field === undefined)
                desired_sort_by_field = default_sort_by_field


            set_sort_ds_config({ sort_by_field: desired_sort_by_field, sort_order: desired_sort_order })

            if (showOnlyMyCreatedDs === true && unsorted_dataset?.length > 0) {
                //Handle sorting in admin portal when showOnlyMyCreatedDs is set as true
                filteredDatasets = [...unsorted_dataset]?.filter(ds => parseInt(ds.created_by) === portalUser?.uid);
            } else if ((showOnlyMyCreatedDs === false || isAdminPortal === false) && unsorted_dataset?.length > 0) {
                //Handle sorting in user portal or show only my created datasets is set as false in admin portal 
                filteredDatasets = [...unsorted_dataset];
            } else {
                //Handle sorting on null dataset retuned
                setDatasets({ status: true, data: [], message: "No dataset found." });
            }

            if (Array.isArray(filteredDatasets) && filteredDatasets?.length > 0) {
                let sortedDataset = [...filteredDatasets]?.sort((a, b) => {
                    //Handle text field sorting
                    if (desired_sort_by_field === "title" || desired_sort_by_field === "status") {
                        let textA = a[desired_sort_by_field]?.toLowerCase();
                        let textB = b[desired_sort_by_field]?.toLowerCase();
                        return desired_sort_order === 'asc' ? (textA < textB ? -1 : 1) : (textA > textB ? -1 : 1);
                        //Handle date field sorting
                    } else if (desired_sort_by_field === "create_date" || desired_sort_by_field === "last_updated_date" || desired_sort_by_field === "publish_date") {
                        let dateA = new Date(a[desired_sort_by_field]);
                        let dateB = new Date(b[desired_sort_by_field]);
                        return desired_sort_order === 'asc' ? dateA - dateB : dateB - dateA;
                        //Handle numeric field sorting
                    } else if (desired_sort_by_field === "dataset_id") {
                        let numA = Number(a[desired_sort_by_field]);
                        let numB = Number(b[desired_sort_by_field]);
                        return desired_sort_order === 'asc' ? numA - numB : numB - numA;
                    } else {
                        return 0;
                    }
                })

                setDatasets(sortedDataset)
                resolve()
            } else {
                setDatasets(filteredDatasets)
                resolve()
            }

        })

    }

    const handleAddDataset = async () => {
        //setKeyword("")
        //If there is NO new dataset (i.e. no dataset_id contains letters) on screen
        if (Array.isArray(datasets) && datasets?.filter((ds) => ds.dataset_id.toString().match(/[A-Za-z]/g))?.length === 0) {
            SetIsEditing(true)
            temp_new_dataset_id = Math.random().toString(36).slice(2, 12);
            setEditDsId(temp_new_dataset_id)
            let temp_datasets = [...datasets]
            new_dataset.dataset_id = temp_new_dataset_id
            temp_datasets.unshift(new_dataset)
            setDatasets(temp_datasets)
        } //Special handle for new dataset when no dataset return on a search
        else if (!Array.isArray(datasets) && !!datasets?.data) {
            SetIsEditing(true)
            temp_new_dataset_id = Math.random().toString(36).slice(2, 12);
            setEditDsId(temp_new_dataset_id)
            let temp_datasets = []
            new_dataset.dataset_id = temp_new_dataset_id
            temp_datasets.unshift(new_dataset)
            setDatasets(temp_datasets)
        }
        else {
            window.confirm("There is an new dataset is currently editing")
        }
    }

    const handleEditDataset = (dataset_id) => {
        setKeyword("")
        SetIsEditing(true)
        setEditDsId(dataset_id)
    }

    const handleSaveDataset = async (ds_id, ds_title, ds_title_abbr, ds_description, ds_publish_date, ds_status) => {
        if (ds_id.toString().match(/[A-Za-z]/g)?.length > 0) {
            try {
                //New dataset
                //Set dataset access permission to `admin` by default
                new_dataset.permission = permission_admin;
                //Set dataset publish status to `drafted` by default
                new_dataset.status = status_draft;
                //Insert update information to dataset                
                new_dataset.created_by = portalUser?.uid
                //new_dataset.create_date = current_date_time

                //Remove temp dataset ID
                delete new_dataset['dataset_id']

                new_dataset.title = ds_title
                new_dataset.title_abbr = ds_title_abbr.toLowerCase()
                new_dataset.dataset_desc = ds_description
                new_dataset.publish_date = ds_publish_date
                new_dataset.status = ds_status

                addDataset(new_dataset)
                    .then(add_dataset_result => {
                        setKeyword("")

                        setDatasets(undefined)
                        setEditDsId()

                        //Remove temp dataset from array
                        datasets.shift();

                        toast(<div className="text-center align-items-center"><span><strong>{add_dataset_result?.title}</strong>&nbsp;Added</span></div>)

                        listAlldatasetAdmin()
                            .then(async datasets_admin => {
                                //setIsLoading(true)
                                if (datasets_admin?.data?.length > 0) {
                                    setOriginalDataset(datasets_admin.data)
                                } else {
                                    setDatasets(datasets_admin)
                                    setDatasetMeta({})
                                }
                                SetIsEditing(false)
                            })
                            .catch(err => {
                                console.log("listAlldatasetAdmin", err)
                                //setIsLoading(false)
                            })
                    })
                    .catch(err => {
                        //setIsLoading(false)
                        toast(<div className="text-center align-items-center"><span className='text-danger'>There is problem on adding dataset - <strong>&nbsp;{err?.message?.detail}</strong></span></div>)
                    })


            } catch (err) {
                console.log(err)
                //setIsLoading(false)
                toast(<div className="text-center align-items-center"><span className='text-danger'>There is problem on adding dataset - <strong>&nbsp;{err?.message?.detail}</strong></span></div>)
            }
        } else {
            setDatasets(undefined)

            SetIsEditing(false)
            setEditDsId()
            //Exisiting dataset            
            let updated_dataset = datasets.filter((ds) => ds.dataset_id === editdsid)[0]

            updated_dataset.title = ds_title
            updated_dataset.title_abbr = ds_title_abbr.toLowerCase()
            updated_dataset.dataset_desc = ds_description
            updated_dataset.publish_date = ds_publish_date
            updated_dataset.status = ds_status?.trim()

            //Insert update information to dataset            
            updated_dataset.last_updated_by = portalUser?.uid

            try {
                const update_dataset_result = await updateDataset(updated_dataset)


                toast(<div className="text-center align-items-center"><span><strong>{update_dataset_result.data.title}</strong>&nbsp;Updated</span></div>)
                if (isAdminPortal) {
                    const datasets_admin = await listAlldatasetAdmin()

                    if (datasets_admin?.data?.length > 0) {
                        //setDatasets(datasets_admin.data)
                        setOriginalDataset(datasets_admin.data)

                        //Keep the view i.e. sorting, sorting field when edit an individual dataset
                        getUniqueUserIds(datasets)
                            .then(userIdsString => {
                                getUserByIds(userIdsString)
                                    .then(dataset_meta_users_info => {
                                        setDatasetMeta(dataset_meta_users_info)
                                        //showOnlyMyCreatedDatasets(datasets_admin.data)
                                        handleDatasetSort(datasets_admin.data, sort_ds_config.sort_by_field, sort_ds_config.sort_order, showOnlyMyCreatedDs)

                                    })
                            })

                    } else {
                        setOriginalDataset(datasets_admin)
                        setDatasets(datasets_admin)
                        setDatasetMeta({})
                    }
                } else {
                    const dataset_user = await listAlldataset()
                    setOriginalDataset(dataset_user)
                    setDatasets(dataset_user)
                    setDatasetMeta({})
                }

            } catch (err) {
                toast(<div className="text-center align-items-center"><span className='text-danger'>Fail to update dataset - &nbsp;<strong>{err?.message}</strong></span></div>)
            }
        }
    }

    const handleCancelEditDataset = async (dataset_id) => {
        SetIsEditing(false)
        setEditDsId()
        if (dataset_id.toString().match(/[A-Za-z]/g)?.length > 0) {
            datasets.shift()
        }

        //If there is no original dataset set when cancel; i.e. the search is made outside the listdataset component, then redirect to dataset page so that it can fetch the dataset again
        if (datasets?.length <= 0) {
            navigate("/dataset")
        } else {
            //Pass the original dataset to the sort function to keep the view
            await handleDatasetSort(datasets || originalDataset, sort_ds_config.sort_by_field, sort_ds_config.sort_order, showOnlyMyCreatedDs)
        }

        setIsLoading(false)
    }

    const handleDeleteDataset = async (dataset_id) => {
        //New dataset
        const ds_to_be_removed = datasets.filter(ds => ds.dataset_id === dataset_id)[0]

        const confirmDelete = window.confirm(`Are you sure you want to delete '${ds_to_be_removed.title}' and the data associated with this dataset?`);
        if (confirmDelete) {
            try {
                setKeyword("")
                const result = await deleteDataset(ds_to_be_removed.dataset_id)

                //remove data from dataset also
                /*if (result?.data?.data_id !== undefined && result?.data?.data_id !== null && result?.data?.data_id !== "") {
                    result?.data?.data_id.split(",").forEach(async data_id => {
                        const data_deleted = await deleteData(data_id)
                        //console.log(data_deleted)
                        await dropDataTable(data_deleted?.data?.data_name)
                        await deleteDataInFolder(data_deleted?.data?.data_name)
                    })
                }*/

                if (result?.data?.data_id !== undefined && result?.data?.data_id !== null && result?.data?.data_id !== "") {
                    toast(<div className="text-center align-items-center"><span><strong>{result?.data?.title}</strong>&nbsp;deleted; Please check and delete the data associated with this dataset</span></div>)
                } else {
                    toast(<div className="text-center align-items-center"><span><strong>{result?.data?.title}</strong>&nbsp;deleted</span></div>)
                }


            } catch (err) {
                toast(<div className="text-center align-items-center"><span className='text-danger'>There is problem in deleting - <strong>{ds_to_be_removed?.title}</strong>&nbsp;deleted</span></div>)
            }

            listAlldatasetAdmin()
                .then(async datasets_admin => {
                    setOriginalDataset(datasets_admin.data)
                    if (datasets_admin?.data?.length > 0) {
                        getUniqueUserIds(datasets_admin?.data)
                            .then(userIdsString => {
                                getUserByIds(userIdsString)
                                    .then(dataset_meta_users_info => {
                                        setDatasetMeta(dataset_meta_users_info)
                                        handleDatasetSort(datasets_admin.data, sort_ds_config.sort_by_field, sort_ds_config.sort_order, showOnlyMyCreatedDs)
                                    })
                            })
                    } else {
                        setOriginalDataset(datasets_admin)
                        setDatasets(datasets_admin)
                        setDatasetMeta({})
                    }
                })
                .catch(err => {
                    console.log(err)
                })

        } else {
            toast(<div className="text-center align-items-center"><span className='text-danger'>Cancel Deleting&nbsp;<strong>{ds_to_be_removed.title}</strong></span></div>)
        }
    }

    const handleClearSort = async () => {
        //console.log("handleClearSort...", originalDataset)
        await handleDatasetSort(originalDataset, "title", "asc", showOnlyMyCreatedDs)
    }

    const handleChangeShowOnlyMyCreatedDs = async (e) => {
        setShowOnlyMyCreatedDs(e.target.checked)
    }

    const listAlldatasetAdmin = async () => {
        const accessToken = await refreshToken();
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/admin/dataset", {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then(async (res_json) => {
                    resolve(res_json);
                });
        });
    }

    const listAlldataset = async () => {
        const accessToken = await refreshToken();
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/dataset", {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((res_json) => {
                    resolve(res_json);
                });
        });
    }

    const datasetSearch2 = async (keyword) => {
        const accessToken = await refreshToken();
        return new Promise(async (resolve, reject) => {
            setIsLoading(true)
            const search_api = isAdminPortal
                ? api_url + "/admin/dataset/search?keyword=" + encodeURIComponent(keyword)
                : api_url + "/dataset/search?keyword=" + encodeURIComponent(keyword)
            fetch(search_api, {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((res_json) => {
                    resolve(res_json);
                });
        });

    };

    const getUserByIds = async (uids) => {
        const accessToken = await refreshToken();
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/admin/users?user_ids=" + encodeURIComponent(uids), {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((res_json) => {
                    setIsLoading(false);
                    if (res_json?.data?.length > 0) {
                        const usersById = res_json?.data?.reduce((acc, user) => {
                            acc[user.uid] = user;
                            return acc;
                        }, {});

                        resolve(usersById);
                    }
                    resolve({});
                });
        });
    };

    const getUniqueUserIds = async (datasets) => {
        return new Promise(async (resolve, reject) => {
            let userIdsSet = new Set();
            if (datasets?.length > 0) {
                await Promise.all(datasets?.map(async (ds) => {
                    if (!userIdsSet.has(ds.created_by) && ds.created_by != "" && ds.created_by != null && ds.created_by != undefined) {
                        userIdsSet.add(ds.created_by)
                    }

                    if (!userIdsSet.has(ds.last_updated_by) && ds.last_updated_by != "" && ds.last_updated_by != null && ds.last_updated_by != undefined) {
                        userIdsSet.add(ds.last_updated_by)
                    }
                }));

                resolve(Array.from(userIdsSet).join(","));
            } else {
                resolve("");
            }
        })
    };

    const getDataWithNoDatasetBinded = async () => {
        const accessToken = await refreshToken();
        return new Promise(async (resolve, reject) => {
            fetch(api_url + "/admin/data/unbinded_data", {
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: "Bearer " + accessToken.token
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((res_json) => {
                    resolve(res_json);
                })
                .catch((err) => {
                    console.log(err);
                    reject(err);
                });
        })
    };

    /*******************/
    /* useEffect hooks */
    /*******************/
    useEffect(() => {
        localCart = localStorage.getItem("cart")
        if (localCart) {
            //turn cart content from locat storage into js
            localCart = JSON.parse(localCart);
            //load persisted cart into state if it exists
            setCart(localCart)
        }
    }, [])

    useEffect(() => {
        if (!!datasets === false) {
            setIsLoading(true)
        } else {
            setIsLoading(false)
        }
    }, [datasets])

    useEffect(() => {
        handleDatasetSort(originalDataset, sort_ds_config.sort_by_field, sort_ds_config.sort_order, showOnlyMyCreatedDs)
            .then(() => {
                //setIsLoading(false)
            })

    }, [originalDataset, showOnlyMyCreatedDs])

    useEffect(() => {
        //Search dataset
        const goSearch = async (keyword) => {
            if (keyword !== null && keyword !== undefined && keyword !== "") {
                setShowOnlyMyCreatedDs(false)
                datasetSearch2(keyword)
                    .then(async (search_result) => {
                        if (search_result?.data?.length > 1) {
                            //result dataset is more than 1
                            await handleDatasetSort(search_result.data, sort_ds_config.sort_by_field, sort_ds_config.sort_order, false)
                        } else if (search_result?.data?.length === 1) {
                            //result dataset is 1
                            setIsLoading(false)
                            setDatasets(search_result.data)
                        } else {
                            //No dataset found
                            setIsLoading(false)
                            setDatasets(search_result)
                        }
                    })
            }
            else {
                setDatasets(originalDataset)
            }
        }

        //Get all dataset with status = published
        const getAlldataset = async () => {
            listAlldataset()
                .then((datasets) => {
                    setOriginalDataset(datasets.data)
                })

        }

        const getAlldatasetWithUserInfo = async () => {
            //setIsLoading(true)
            listAlldatasetAdmin()
                .then((datasets) => {
                    getUniqueUserIds(datasets)
                        .then(userIdsString => {
                            getUserByIds(userIdsString)
                                .then(dataset_meta_users_info => {
                                    setDatasetMeta(dataset_meta_users_info)
                                    setOriginalDataset(datasets.data)
                                })
                        })
                })
        }

        //Entry point of the component
        const searchParams = new URLSearchParams(location.search);
        const keyword = searchParams.get("keyword");

        set_sort_ds_config({ sort_by_field: "title", sort_order: "asc" })

        if (keyword) {
            //Handle dataset listing based on search
            setKeyword(keyword)
            SetIsEditing(false)
            goSearch(keyword)
        } else if ((keyword === null || keyword === undefined || keyword === "") && isAdminPortal) {
            //Handle dataset listing in admin portal                        
            setDatasets(undefined)
            setShowOnlyMyCreatedDs(false)
            getAlldatasetWithUserInfo()
        }
        else {
            //Handle dataset listing in user portal                        
            setDatasets(undefined)
            getAlldataset()
        }
    }, [location.search, isAdminPortal])

    const props = useSpring({
        from: { x: 0, y: -100, opacity: 0.1 },
        to: { x: 0, y: 0, opacity: 1 },
        config: {
            mass: 5,
            friction: 70,
            tension: 270,
            precision: 0.0001,
            delay: 1
        },
    })

    /*console.log("@UI Render - \n",
        isLoading ? "loading...\n" : "???\n",
        "\nkeyword=" + keyword ? keyword : "No keyword input",
        "\nshow only my created dataset=" + showOnlyMyCreatedDs,
        "\nsort by field=" + sort_ds_config.sort_by_field + ", sort order=" + sort_ds_config.sort_order,
        "\n", datasets,
        "\n", originalDataset)*/

    return (
        <>
            <HeaderNew
                isLoading={isLoading}
                CartCount={cart.length}
                dataset={datasets}
                setDatasets={setDatasets}
                data={null}
                keyword={keyword}
                setKeyword={setKeyword}
            />

            <Row className="ms-1 me-1 mt-2 mb-3 justify-content-center align-items-center" >
                {
                    isAdminPortal && portalUser?.role.split(",").includes(permission_admin)
                        ? <>
                            <Col xs={11} sm={11} md={10} lg={8} xl={7} className='text-center'>
                                {/* Add Dataset Button */}
                                <Button disabled={isEditing} className='om-admin-button border-0 mt-2' onClick={handleAddDataset}>
                                    <FontAwesomeIcon size="1x" icon={icon({ name: 'circle-plus' })} />
                                    <span>&nbsp;Add Dataset</span>
                                </Button>
                                {/* Show only My Created Datasets */}
                                <Form.Check
                                    className='m-0 pt-3 d-flex justify-content-center form-check-my-dataset'
                                    type={'checkbox'}
                                    id={`checkbox-Show-only-My-Created-Datasets`}
                                    label={" Show only My Created Datasets"}
                                    defaultChecked={showOnlyMyCreatedDs}
                                    checked={showOnlyMyCreatedDs && keyword === ""}
                                    onChange={(e) => { handleChangeShowOnlyMyCreatedDs(e) }}
                                    disabled={keyword !== ""}
                                >
                                </Form.Check>
                            </Col >
                        </>
                        : <></>
                }

                {/* Sorting button */}
                <Col xs={11} sm={11} md={10} lg={8} xl={7} className='mb-1 align-items-center text-center'>
                    <Dropdown as={ButtonGroup} className='mt-3 mb-3 align-items-center'>
                        <Button
                            variant='dark'
                            className='text-capitalize om-dataset-sort-order-button pt-1 pb-1 ps-3 pe-3'
                            //onClick={() => sort_order === 'asc' && sort_by_field ? set_Sort_order('desc') : set_Sort_order('asc')}
                            onClick={() => handleDatasetSort(datasets, sort_ds_config.sort_by_field, sort_ds_config.sort_order === 'asc' ? 'desc' : 'asc', showOnlyMyCreatedDs)}
                            disabled={sort_ds_config.sort_by_field === undefined || sort_ds_config.sort_by_field === null || sort_ds_config.sort_by_field === "" || datasets?.length <= 1 || !!datasets?.message == true}
                        >
                            {sort_ds_config.sort_order === 'asc'
                                ? <>
                                    <span style={sort_ds_config.sort_by_field === undefined || sort_ds_config.sort_by_field === null || sort_ds_config.sort_by_field === ""
                                        ? { color: "grey" }
                                        : {}}
                                    >
                                        <FontAwesomeIcon className="p-0 m-0" icon={icon({ name: 'arrow-down-short-wide', style: 'solid' })} />
                                    </span>
                                </>
                                : <><FontAwesomeIcon className="p-0 m-0" icon={icon({ name: 'arrow-down-wide-short', style: 'solid' })} /></>
                            }
                            {
                                sort_ds_config.sort_by_field !== undefined && sort_ds_config.sort_by_field !== null && sort_ds_config.sort_by_field !== ""
                                    ? <>&nbsp;&nbsp;{sort_ds_config.sort_by_field?.replace("_", " ")}</>
                                    : <>&nbsp;&nbsp;<span style={{ color: "grey" }}>Sort dataset</span></>
                            }

                        </Button>

                        {/*om-header-sort-order-button om-header-sort-button */}
                        <Dropdown.Toggle
                            split className='om-dataset-sort-button'
                            variant="dark"
                            id="dropdown-sort-by-field"
                            disabled={sort_ds_config.sort_by_field === undefined || sort_ds_config.sort_by_field === null || sort_ds_config.sort_by_field === "" || datasets?.length <= 1 || !!datasets?.message == true}
                        />
                        <Dropdown.Menu className="text-start" >
                            <Dropdown.Item className="om-dataset-sorting-options-user" size="sm" onClick={() => handleDatasetSort(datasets, "title", sort_ds_config.sort_order, showOnlyMyCreatedDs)} disabled={sort_ds_config.sort_by_field === "title"} >Title</Dropdown.Item>
                            <Dropdown.Item className="om-dataset-sorting-options-user" size="sm" onClick={() => handleDatasetSort(datasets, "publish_date", sort_ds_config.sort_order, showOnlyMyCreatedDs)} disabled={sort_ds_config.sort_by_field === "publish_date"}>Publish Date</Dropdown.Item>
                            {
                                isAdminPortal ? <>
                                    <Dropdown.Divider />
                                    <Dropdown.ItemText className="om-dataset-sorting-options-admin">
                                        <span><strong>Admin ONLY options</strong></span>
                                    </Dropdown.ItemText>
                                    <Dropdown.Item className="om-dataset-sorting-options-admin" size="sm" onClick={() => handleDatasetSort(datasets, "create_date", sort_ds_config.sort_order, showOnlyMyCreatedDs)} >Create Date</Dropdown.Item>
                                    <Dropdown.Item className="om-dataset-sorting-options-admin" size="sm" onClick={() => handleDatasetSort(datasets, "last_updated_date", sort_ds_config.sort_order, showOnlyMyCreatedDs)} >Last Updated Date</Dropdown.Item>
                                    <Dropdown.Item className="om-dataset-sorting-options-admin" size="sm" onClick={() => handleDatasetSort(datasets, "status", sort_ds_config.sort_order, showOnlyMyCreatedDs)} >Status</Dropdown.Item>
                                </>
                                    : <></>
                            }

                            <Dropdown.Divider />
                            <Dropdown.Item className="om-dataset-sorting-options-user" size="sm" onClick={() => handleClearSort()} disabled={sort_ds_config.sort_by_field === undefined || sort_ds_config.sort_by_field === null || sort_ds_config.sort_by_field === ""}>Clear</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>

                {/* Dataset Cards */}
                <Col xs={11} sm={11} md={10} lg={8} xl={7}>
                    {
                        isLoading
                            ? <>
                                <>
                                    <div className="d-flex justify-content-center" style={{ marginTop: "50px" }}>
                                        <Spinner animation="border" role="status" >
                                            <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                    </div>
                                </>
                            </>
                            : datasets?.data?.length === 0 && !!datasets?.message === true
                                ?
                                <>
                                    <div className="d-flex justify-content-center">
                                        <Card className='info-display-card'>
                                            <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                                                <Stack>
                                                    <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} />
                                                    <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>{datasets ? datasets.message : "Error on retrieving dataset"}</ReactMarkdown>
                                                </Stack>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </>
                                :
                                datasets?.map((dataset, index) => {
                                    return (
                                        <DatasetCard
                                            key={index}
                                            dataset={dataset}
                                            dataset_meta={dataset_meta}
                                            isEditing={isEditing}
                                            editdsid={editdsid}
                                            handleSaveDataset={handleSaveDataset}
                                            handleEditDataset={handleEditDataset}
                                            handleCancelEditDataset={handleCancelEditDataset}
                                            handleDeleteDataset={handleDeleteDataset}
                                        />
                                    )
                                })
                    }
                </Col>
            </Row >
        </>
    );
}

export default ListDatasets;