import React, { useEffect } from 'react'
import { Row, Col, Container, Button } from 'react-bootstrap'

import { useCustomNavigate } from '../hooks/useCustomNavigate';

import MainLogoStatic from '../components/MainLogoStatic'
import TsParticles from "../components/ParticlesBackground";

import { useSpring, animated } from '@react-spring/web';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import { about_text } from '../constants/omp_ui_text';

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import "./About.css"

const About = () => {

    const navigate = useCustomNavigate();

    const handleGoBack = () => {
        navigate('/')
    }

    //Set metadata
    useEffect(() => {
        //Include Google Analytics Tag
        const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
        // Google Analytics tracking code
        const head = document.querySelector("head");
        const script1 = document.createElement("script");
        script1.async = true;
        script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
        head.appendChild(script1);
        const script2 = document.createElement("script");
        script2.innerText =
            `window.dataLayer = window.dataLayer || [];\
          function gtag(){dataLayer.push(arguments);}\
          gtag('js', new Date()); \
          gtag('config', '${trackingID}');\
          gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
        head.appendChild(script2);

        // Update the document's meta tags when the component mounts
        document.title = 'Open Mortality | About';
        document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - About');

        // Clean up the meta tag modifications when the component unmounts
        return () => {
            document.title = '';
            document.querySelector('meta[name="description"]').setAttribute('content', '');
            head.removeChild(script1);
            head.removeChild(script2);
        };
    }, []);

    const props = useSpring({
        from: { x: -50, y: 0, opacity: 0.1 },
        to: { x: 0, y: 0, opacity: 1 },
        config: {
            mass: 5,
            friction: 70,
            tension: 270,
            precision: 0.0001,
            delay: 1
        },
    })

    return (

        <>
            <TsParticles />

            <Container className='ps-5 pe-5' style={{ height: "100%", width: "100%", zIndex: 1 }} >

                <Row className="m-2 d-flex justify-content-center">
                    <Col style={{ zIndex: 1 }}>
                        <MainLogoStatic />
                    </Col>
                </Row>

                <Row className="m-2 d-flex justify-content-center">
                    <Col xs={12} sm={12} md={10} lg={8} xl={8} className="d-flex justify-content-center" style={{ zIndex: 1 }} >
                        <h2>About</h2>
                    </Col>
                </Row>

                <Row className='d-flex justify-content-center m-auto mb-2'>
                    <Col xs={12} sm={12} md={10} lg={8} xl={8} className="" style={{ zIndex: 1 }}>
                        <animated.div style={{ ...props }}>
                            <ReactMarkdown remarkPlugins={[remarkGfm]} children={about_text} />
                        </animated.div>
                    </Col>
                </Row>

                <Row className='d-flex align-items-center justify-content-center'>
                    <Col xs={11} sm={11} md={12} lg={12} xl={12} className="d-flex justify-content-center" style={{ zIndex: 1 }}>
                        <Button variant="secondary" className='om-button' onClick={() => handleGoBack()}>Back</Button>
                    </Col>
                </Row>
            </Container>

        </>
    );

}

export default About