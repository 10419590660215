export const EMAIL_REGEX = /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*]{8,20}$/;
export const KEYWORDS_REGEX = /(?=.*\S)(?=(?:\S+\s){4,}\S+\s*$)[\w\d\s"#$%&'()*+\-./:_`\\{|}~]{2,}/;
export const FIRSTNAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const LASTNAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/;
export const KEYWORDSEARCH_REGEX = /^(?! +$)[a-zA-Z0-9\s]*/;

//Extract text/remove special format from markdown
export const DATA_MD_ABOUT_EXTRACT_REGEX = /([\s\S]*?)(?=## Citation|$)/; //Last update on 2023 June 16
//export const DATA_MD_CITATION_EXTRACT_REGEX = /## Citation([\s\S]*)/
export const DATA_MD_CITATION_EXTRACT_REGEX = /## Citation([\s\S]*?)(?=## Contact|$)/
export const DATA_MD_EXTRACT_ITALIC_REGEX = /\\*([^*]+)\*/;
export const DATA_MD_EXTRACT_LINK_REGEX = /\[[^\]]+\]/;

//Prepare to support different varation (sub-domain) for utoronto.ca email address
export const UTORONTO_EMAIL_REGEX = /[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)?utoronto\.ca/
export const TEMP_EDIT_ID_REGEX = /[A-Za-z]/