import React from 'react'
import { Button, Row, Col } from 'react-bootstrap';
import { api_config } from "../components/api_url";
import { useCustomNavigate } from '../hooks/useCustomNavigate';
import "./ExploreOurDataset.css"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const api_url = api_config.url.API_URL

const ExploreOurDataset = () => {
  const navigate = useCustomNavigate()

  const handleExplore = async () => {
    refreshToken()
      .then((refresh_token_result) => {
        if (refresh_token_result?.login)
          navigate("/dataset")
        else
          navigate("/login")
      }).catch((err) => {
        console.log(err)
        navigate("/login")
      })
  }

  //refresh token before access to any resources  
  const refreshToken = () => {
    return new Promise(async (resolve) => {
      fetch(api_url + "/user/refresh", {
        method: "GET",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((res_json) => {
          resolve(res_json)
        });
    })
  }

  return (
    <>
      <Row className='fade-in'>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} className='d-flex justify-content-center align-items-center'>
          <Button
            variant='dark'
            className="explore-dataset red-gradient-hover-effect"
            id="button-explore-dataset"
            onClick={handleExplore}
          >
            <div className='m-1 d-flex align-items-center explore-dataset-button'>
              <span className='m-2'>Explore Our Datasets </span>
              <FontAwesomeIcon className='fa-flip-horizontal me-2' size="1x" icon={icon({ name: 'magnifying-glass' })} />
            </div>
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default ExploreOurDataset