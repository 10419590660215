import React, { useEffect, createContext, useState } from "react";

import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';

import { Col, Row, Container } from 'react-bootstrap';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Home from "./pages/Home";
import DatasetDetails from "./pages/ViewDatasetDetails";
import ViewDataset from "./pages/ViewDataset";
import Login from "./pages/Login";
import ErrorPage from "./pages/ErrorPage";
import Register from "./pages/Register";
import AccountVerify from "./pages/AccountVerify";
import ViewDatasetByName from "./pages/ViewDatasetByName";
import DownloadCart from "./pages/DownloadCart";
import UserInfo from "./pages/UserInfo";
import ChangePassword from "./pages/ChangePassword";
import Welcome from "./pages/Welcome";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import About from "./pages/About";
import Team from "./pages/Team";
import { api_config } from "./components/api_url";
import Footer from './components/Footer';
import './App.css';

import "bootstrap/dist/css/bootstrap.min.css";
import AdminPortalOverlayIndicator from "./components/AdminPortalOverlayIndicator";

export const contextIsAdminPortal = createContext();
export const contextPortalUser = createContext();

const App = () => {
  const api_url = api_config.url.API_URL

  const [portalUser, setPortalUser] = useState(null)
  const [isAdminPortal, setIsAdminPortal] = useState()

  let user;

  //get login user
  const getUser = () => {
    return new Promise((resolve, reject) => {
      try {
        fetch(api_url + "/user/getUser", {
          method: 'GET',
          credentials: 'include'
        })
          .then((res) => res.text()) //return res.text for getUser() to avoid JSON parsing error; the passport.js return null when there is no user in session
          .then((res_json) => {
            if (res_json.length > 0) {
              resolve(JSON.parse(res_json)) //If there is user, parse the response to JSON
            } else {
              resolve(null)
            }
          })
          .catch((err) => {
            reject(err)
          });
      }
      catch (err) {
        reject(err)
      }
    })
  }

  useEffect(() => {
    if (portalUser === null || portalUser === undefined || portalUser === "") {
      getUser().then((user) => {
        setPortalUser(user)
        if (user?.role?.split(",")?.includes("admin") && window.location.href.includes('/admin')) {
          setIsAdminPortal(true)
        } else if (window.location.href.includes('/admin')) {
          const redirect_url = window.location.pathname.replace("/admin", "");
          window.location.href = redirect_url || "/";
        } else {
          setIsAdminPortal(false)
        }
      })
    }
  }, [portalUser])


  useEffect(() => {
    localStorage.getItem("cart");
  }, [])


  return (
    <Container fluid className="main-container d-flex flex-column" >
      <Row className="flex-grow-1">
        <Col className="p-0">
          {/*<FeedbackButton></FeedbackButton>*/}
          <Router>
            <contextIsAdminPortal.Provider value={[isAdminPortal, setIsAdminPortal]} >
              <contextPortalUser.Provider value={[portalUser, setPortalUser]} >
                <AdminPortalOverlayIndicator />
                <Routes>
                  {/* User portal routes */}
                  <Route index path="/" element={<Home />} ></Route>
                  <Route path="/dataset" element={<ViewDataset />}></Route>
                  <Route path="/dataset/:dataset" element={<ViewDatasetByName />}></Route>
                  <Route path="/data/:data_name/" element={<DatasetDetails />}></Route>
                  <Route path="/login" element={<Login />}></Route>
                  <Route path="/register" element={<Register />}></Route>
                  <Route path="/forgetpassword" element={<ForgetPassword />}></Route>
                  <Route path="/resetpassword" element={<ResetPassword />}></Route>
                  <Route path="/accountverify" element={<AccountVerify />}></Route>
                  <Route path="/changepassword" element={<ChangePassword />}></Route>
                  <Route path="/userinfo" element={<UserInfo />}></Route>
                  <Route path="/downloadcart" element={<DownloadCart />}></Route>
                  <Route path="/welcome" element={<Welcome />}></Route>
                  <Route path="/about" element={<About />}></Route>
                  <Route path="/team" element={<Team />}></Route>
                  <Route path="*" element={<ErrorPage />}></Route>
                  {/* Admin portal routes */}
                  <Route path="/admin/*" element={
                    <Routes>
                      <Route index path="/" element={<Home />} ></Route>
                      <Route path="/dataset" element={<ViewDataset />}></Route>
                      <Route path="/dataset/:dataset" element={<ViewDatasetByName />}></Route>
                      <Route path="/data/:data_name/" element={<DatasetDetails />}></Route>
                      <Route path="/login" element={<Login />}></Route>
                      <Route path="/register" element={<Register />}></Route>
                      <Route path="/forgetpassword" element={<ForgetPassword />}></Route>
                      <Route path="/resetpassword" element={<ResetPassword />}></Route>
                      <Route path="/accountverify" element={<AccountVerify />}></Route>
                      <Route path="/changepassword" element={<ChangePassword />}></Route>
                      <Route path="/userinfo" element={<UserInfo />}></Route>
                      <Route path="/downloadcart" element={<DownloadCart />}></Route>
                      <Route path="/welcome" element={<Welcome />}></Route>
                      <Route path="/about" element={<About />}></Route>
                      <Route path="/team" element={<Team />}></Route>
                      <Route path="*" element={<ErrorPage />}></Route>
                    </Routes>
                  }></Route>


                </Routes>
              </contextPortalUser.Provider>
            </contextIsAdminPortal.Provider>
          </Router>
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center text-center ms-auto me-auto mt-4 mb-1" style={{ zIndex: 1 }}>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} >
          <Footer />
        </Col>
      </Row>

      <ToastContainer
        position="bottom-right"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3}
        theme="light"
      />

    </Container>
  );
}

export default App;