module.exports = {
    USER: {
        login_reminder: "Please login to view the data"
    },

    ADMIN_PORTAL: {
        back_to_user_portal_draft_dataset_warning: "The dataset currently displayed is in 'draft' status, which is only accessible in the admin portal. To proceed, click 'OK' to be redirected to the dataset listing page.",
        back_to_user_portal_draft_data_warning: "The data/dataset currently displayed is in 'draft' status, which is only accessible in the admin portal. To proceed, click 'OK' to be redirected to the dataset listing page."
    },
    /* Message in Data Card */
    INPUT: {
        missing_tag: "Please enter tag",
        missing_title: "Please enter title",
        missing_description: "Please enter description",
        missing_permssion: "Please enter permission",
        missing_publish_date: "Please enter publish date",
        missing_contact: "Please enter contact",
        missing_file: "Please upload file",
        missing_status: "Please select status",
        missing_category: "Please select category"
    }
}