import React, { useEffect, useState, useMemo, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Tabs, Tab, Button, Col, Row, Card, Stack, Placeholder, Badge, Spinner } from 'react-bootstrap';

import { contextIsAdminPortal, contextPortalUser } from '../App.js';
import { toast } from "react-toastify";

import DataInfo from '../components/DataInfo.js';
import Displaydatadict from '../components/DisplayDatadict.js';
import DisplayDataPreview from '../components/DisplayDataPreview.js';
import DisplayMapDataPreview from '../components/DisplayMapDataPreview.js';
import HeaderNew from '../components/HeaderNew.js';

import { api_config } from "../components/api_url.js";

import { useCustomNavigate } from '../hooks/useCustomNavigate.js';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { useSpring, animated } from '@react-spring/web';
import { update } from 'react-querybuilder';

const ViewDatasetDetails = () => {
  const useMemoizedQueryParams = () => {
    const location = useLocation();
    // This will only be recalculated when the location object changes (query params change)
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    return queryParams;
  };

  const queryParams = useMemoizedQueryParams();

  const api_url = api_config.url.API_URL

  let localCart;

  const location = useLocation();
  const navigate = useCustomNavigate();
  const params = useParams();

  const page = parseInt(queryParams.get('page')) > 0 ? queryParams.get('page') : 1;
  const pageSize = parseInt(queryParams.get('pageSize')) > 0 ? queryParams.get('pageSize') : 10;
  const climit = parseInt(queryParams.get('climit')) > 0 ? queryParams.get('climit') : 5;
  const req_column_name = queryParams.getAll('column');
  const queryString = !!queryParams.get('query') ? queryParams.get('query') : "";

  const [cart, setCart] = useState([]);
  const [dsdInfo, setDsdInfo] = useState([]);
  const [dataDict, setDataDict] = useState([]);
  const [data, setData] = useState([]);
  const [geoJsonData, setGeoJsonData] = useState([]);
  const [accessTokenResult, setAccessTokenResult] = useState();
  const [hasMore, sethasMore] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [keyword, setKeyword] = useState('');
  const [hasInvalidSpatialRecord, setHasInvalidSpatialRecord] = useState();

  const [isAdminPortal] = useContext(contextIsAdminPortal);

  const [loggedInUser, setLoggedInUser] = useContext(contextPortalUser);

  const [versionDisplay, setVersionDisplay] = useState()

  /* Query Builder */
  const initialQuery = {
    combinator: 'and',
    rules: []
  }

  const [query, setQuery] = useState(initialQuery);
  const [isCaseSensitive, setIsCaseSensitive] = useState(false);

  //Go back to previous page when no record was found by the query
  const handleGoBack = () => {
    if (data.status) {
      navigate(-1, {
        state: {
          data: location.state ? location.state.data : "",
          dataset: location.state ? location.state.dataset : "",
          user: location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))
        },
      })
    }
    else {
      navigate(location.pathname + location.search.split("&query=")[0], {
        state: {
          data: location.state ? location.state.data : "",
          dataset: location.state ? location.state.dataset : "",
          user: location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))
        },
      })
    }
  }

  const updateCart = item => {
    //create a copy of our cart state, avoid overwritting existing state    
    let cartCopy = [...cart];
    //assuming we have an ID field in our item
    let { ID } = item;
    //look for item in cart array
    let existingItem = cartCopy.find(cartItem => cartItem.ID === ID);
    //if item already exists            
    if (existingItem) {
      cartCopy.splice(cartCopy.indexOf(existingItem), 1);
      toast(<div className="text-center align-items-center"><FontAwesomeIcon size="1x" icon={icon({ name: 'circle-minus' })} />
        <span> Removed <strong>{existingItem.supp_info.data_name}</strong></span></div>)
    }
    else if (cartCopy.length >= 10) {
      toast(<div className="text-center align-items-center" style={{ color: "red" }}><FontAwesomeIcon size="1x" icon={icon({ name: 'triangle-exclamation' })} />
        <span> Unable to add <strong>{item.supp_info.data_name}</strong><br />
          You have reached the maximum limit of 10 items per download.
          To proceed, please download the existing 10 items in your cart first.
          If you wish to add a new item, kindly remove an item from your cart and add the new one. </span></div>)
    }
    else { //if item doesn't exist, add it
      cartCopy.push(item);
      toast(<div className="text-center align-items-center" ><FontAwesomeIcon size="1x" icon={icon({ name: 'circle-plus' })} />
        <span> Added <strong>{item.supp_info.data_name}</strong> </span></div>)
    }
    //update app state
    setCart(cartCopy)
    //make cart a string and store in local space
    let stringCart = JSON.stringify(cartCopy);
    localStorage.setItem("cart", stringCart)
  }

  const handleReLogin = () => {
    setIsLoading(true)
    fetch(api_url + "/user/logout", {
      method: 'POST',
      credentials: "include",
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then((res) => res.json())
      .then(() => {
        setIsLoading(false)
        //redirect to login page
        navigate('/Login')
      })
      .catch((err) => {
        return;
      });
  }

  //Go back to view all the data in dataset
  const ViewDataset = (data_abbr) => {
    navigate("/dataset/" + data_abbr,
      {
        state:
        {
          dataset: (!!location.state?.dataset?.length > 0 && !!location.state?.data) ? location.state.dataset : dsdInfo.dataset,
          data: !!location.state?.data?.allowed_access ? location.state.data : dsdInfo.data,
          user: !!location.state?.user ? location.state.user : JSON.parse(localStorage.getItem("user"))
        }
      });
  }

  //get login user
  const getUser = () => {
    return new Promise((resolve, reject) => {
      try {
        fetch(api_url + "/user/getUser", {
          method: 'GET',
          credentials: 'include'
        })
          .then((res) => res.text()) //return res.text for getUser() to avoid JSON parsing error; the passport.js return null when there is no user in session
          .then((res_json) => {
            if (res_json.length > 0) {
              resolve(JSON.parse(res_json)) //If there is user, parse the response to JSON
            } else {
              resolve(null)
            }
          })
          .catch((err) => {
            reject(err)
          });
      }
      catch (err) {
        reject(err)
      }
    })
  }

  //NEW: Call refresh token before access to any resources  
  const refreshToken = () => {
    //var cur_user = JSON.parse(loggedInUser);
    return new Promise(async (resolve) => {
      fetch(api_url + "/user/refresh", {
        method: "GET",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((res_json) => {
          resolve(res_json)
        });
    })
  }

  /* Get Data Info*/
  const getDataInfoByDataName = async () => {
    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/data/" + params.data_name + "/metadata", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then(res_json => {
          resolve(res_json.data);
        });
    });
  };

  /* Get Dataset Info*/
  const getDatasetInfoByDataName = async () => {
    return new Promise(async (resolve, reject) => {
      setIsLoading(true)
      fetch(api_url + "/data/" + params.data_name + "/datasets", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
      })
        .then((res) => res.json())
        .then(function (myJson) {
          resolve(myJson.data);
        });
    });
  };

  const getDataInDatasetByDatasetAbbr = async () => {
    //setLoading(true)
    setIsLoading(true)
    //Refresh access token    
    const accessToken = await refreshToken();
    //get the all the data in dataset by dataset_abbr
    const dataset_abbr = await getDatasetInfoByDataName();

    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/dataset/" + encodeURIComponent(dataset_abbr[0]?.title_abbr) + "/data", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        },
      })
        .then(res => res.json())
        .then(res_json => {
          setIsLoading(false);
          resolve(res_json);
        })
        .catch(err => {
          setIsLoading(false);
          console.log(err)
        })
    });
  };

  const getDataInDatasetByDatasetAbbrAdmin = async () => {
    setIsLoading(true)
    //Refresh access token    
    const accessToken = await refreshToken();

    const dataset_abbr = await getDatasetInfoByDataName()

    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/admin/dataset/" + encodeURIComponent(dataset_abbr[0]?.title_abbr) + "/data", {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + accessToken.token
        },
      })
        .then(res => res.json())
        .then(res_json => {
          setIsLoading(false)
          resolve(res_json);
        })
        .catch(err => {
          console.log(err)
          setIsLoading(false)
        })
    });
  };

  //Get data
  const getData = async () => {
    const refreshAccessTokenResult = await refreshToken()
    setIsLoading(true)
    return new Promise(async (resolve) => {
      //Append the user query to the URL if it exists
      const user_query = !!queryString ? "&query=" + queryString : ""

      //console.log("column display mode: ", req_column_name ? "column(s)=" + req_column_name : climit ? "climit=" + climit : "Not defined")
      //console.log("query string: ", location.search)

      fetch(api_url + "/data/" + params.data_name + "?pageSize=" + pageSize + "&page=" + page + "&climit=" + climit + user_query, {
        signal: AbortSignal.timeout(8000),
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + refreshAccessTokenResult.token
        }
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (res_json) {
          if (res_json?.status === true) {
            Object.keys(res_json).includes('hasMore') ? sethasMore(res_json.hasMore) : sethasMore(false)
          } else {
            navigate('/ErrorPage', { replace: true })
          }
          setIsLoading(false)
          resolve(res_json)
        })
        .catch((err) => {
          //console.log( err.name )
          setIsLoading(false)
          resolve({ status: false })
        })
    })
  }

  //Get datadict
  const getDatadict = async () => {
    const refreshAccessTokenResult = await refreshToken()

    var responseClone;
    setIsLoading(true)
    return new Promise(async (resolve, reject) => {
      try {
        fetch(api_url + "/data/" + params.data_name + "/variables", {
          credentials: "include",
          method: "GET",
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            Authorization: "Bearer " + refreshAccessTokenResult.token
          }
        })
          .then((res) => {
            responseClone = res.clone();
            return res.json()
          })
          .then(function (myJson) {
            setIsLoading(false)
            resolve(myJson)
          }, function (rejectionReason) { //try reject handling
            responseClone.text()
              .then(function (bodyText) {
                console.log('Received the following instead of valid JSON:', bodyText);
              });
          });
      } catch (err) {
        reject(err)
      }
    })
  }

  /******************/
  /* useEffect Hook */
  /******************/
  //Set metadata
  useEffect(() => {
    //Include Google Analytics Tag
    const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
    // Google Analytics tracking code
    const head = document.querySelector("head");
    const script1 = document.createElement("script");
    script1.async = true;
    script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
    head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerText =
      `window.dataLayer = window.dataLayer || [];\
        function gtag(){dataLayer.push(arguments);}\
        gtag('js', new Date()); \
        gtag('config', '${trackingID}');\
        gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
    head.appendChild(script2);

    // Update the document's meta tags when the component mounts
    document.title = 'Open Mortality | ' + params.data_name;
    document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality | ' + params.data_name);

    // Clean up the meta tag modifications when the component unmounts
    return () => {
      document.title = '';
      document.querySelector('meta[name="description"]').setAttribute('content', '');
      head.removeChild(script1);
      head.removeChild(script2);
    };
  }, []);

  //Check User is logined in
  useEffect(() => {
    const getLoggedInUser = async () => {
      const user = await getUser()
      setLoggedInUser(user)
    }
    if (!loggedInUser) getLoggedInUser()
  }, [])

  //Convert cart content from local storage to state varible
  useEffect(() => {
    localCart = localStorage.getItem("cart");
    if (localCart) {
      //turn the cart content in local storage into js
      localCart = JSON.parse(localCart);
      //load persisted cart into state if it exists
      setCart(localCart)
    }

  }, []);

  //Get Dataset and Data Information
  useEffect(() => {
    const getDsDInfo = async () => {
      try {
        //const data_info = await getDataInfoByDataName();
        const dataset_info = await getDatasetInfoByDataName();

        /* Added data retireval for versioned data */
        let datainfo_versioned;
        if (isAdminPortal) {
          datainfo_versioned = await getDataInDatasetByDatasetAbbrAdmin();
        } else {
          datainfo_versioned = await getDataInDatasetByDatasetAbbr();
        }

        //Group data
        const groupedVersionedData = (datainfo_versioned?.data).reduce((acc, obj) => {
          const dataName = obj.data_name.replace(/_([^_]+)$/, ''); // Corrected regex to remove version number        
          const group = acc.find(group => group[0]?.data_name.replace(/_([^_]+)$/, '') === dataName); // Check if group already exists        
          if (group) {
            group.push(obj); // If group exists, push object to it
          } else {
            acc.push([obj]); // If group does not exist, create new group with object
          }
          return acc;
        }, []);

        //Find the data that is current viewing from the grouped data with version
        const extracted_versionDisplay = parseInt(params.data_name.split("_")[params.data_name.split("_").length - 1].replace("v", ""));

        // Flatten the nested arrays into a single array of objects for easier processing
        const flattenedData = [].concat(...groupedVersionedData);

        // Find all entries with 'data_name' containing the 'data_name' parameter
        const filteredData = flattenedData.filter(item => item.data_name.replace(/_v\d+/g, '') === (params.data_name.replace(/_v\d+/g, '')));

        setDsdInfo({ dataset: dataset_info, dataVersioned: filteredData })
        setVersionDisplay(extracted_versionDisplay)
      } catch (error) {
        // handle error here, e.g. display an error message to the user
        console.error("Error fetching data", error);
      }
    }

    if (!(!!location.state?.dataset === true && !!location.state?.dataVersioned === true && !!location.state?.versionDisplay === true) &&
      !(!!dsdInfo?.dataset === true && !!dsdInfo?.dataVersioned === true && !!dsdInfo?.versionDisplay === true)
    ) {
      getDsDInfo();
    } else {
      setDsdInfo({ dataset: location.state.dataset, data: location.state.data, dataVersioned: location.state.dataVersioned })
      setVersionDisplay(location.state?.versionDisplay)
    }
  }, []);

  //Get Data dict
  useEffect(() => {
    const getDatadictFromAPI = async () => {
      const dd = await getDatadict();
      setDataDict(dd)
    }

    getDatadictFromAPI()
  }, [versionDisplay]);

  //Get non-spatial data or spatial data
  useEffect(() => {
    const getDataFromAPI = async () => {
      setData([])
      const d = await getData();

      if (d.type === "json")
        setData(d)
      else if (d.type === "geojson")
        setGeoJsonData(d)
      else {
        setData(d)
        setDataDict(d)
      }
    }

    getDataFromAPI()
  }, [pageSize, page, climit, queryString, versionDisplay]);

  useEffect(() => {
    let tempJson = {}

    if (Object.keys(geoJsonData).includes("data") && Object.keys(geoJsonData).includes("totalRecordCount")) {
      if (geoJsonData.recordCount > 0) //If there is record returned
      {
        //converting geojson -> json"
        const nonSpatialData = geoJsonData?.data?.features?.map(feature => feature.properties);

        tempJson = { ...geoJsonData }
        tempJson.type = "json"
        tempJson.data = nonSpatialData
      }
      else //If there is no feature returned; return a json with no record
      {
        tempJson = { ...geoJsonData }
        tempJson.type = "json"
        tempJson.data = []
      }
      setData(tempJson)
    }
  }, [geoJsonData])

  const props = useSpring({
    from: { x: 50, y: 0, opacity: 0.5 },
    to: { x: 0, y: 0, opacity: 1 },
    config: {
      mass: 5,
      friction: 50,
      tension: 270,
      precision: 0.0001,
    },
  })

  const DataDetails = ({ dataset, data_viewing, versionDisplay }) => {
    if (!!data_viewing) {
      return (
        <>
          <div className='m-0 p-1'>
            <Row className='mt-0'>
              <Col xs={3} sm={3} md={6} lg={2} xl={2}>
                <strong>Published:</strong>
              </Col>
              <Col xs={9} sm={9} md={6} lg={10} xl={10}>
                {data_viewing[versionDisplay - 1]?.publish_date.slice(0, 10)}
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col xs={3} sm={3} md={6} lg={2} xl={2}>
                <strong>Contact:</strong>
              </Col>

              <Col xs={9} sm={9} md={6} lg={10} xl={10}>
                <span> <a href={`mailto:${data_viewing[versionDisplay - 1]?.contact}`} >
                  {data_viewing[versionDisplay - 1]?.contact}
                </a>
                </span>
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col xs={3} sm={3} md={6} lg={2} xl={2}>
                <strong>Dataset:</strong>
              </Col>
              <Col xs={9} sm={9} md={6} lg={10} xl={10}>
                {
                  dataset?.map((ds) => {
                    return (
                      <Button
                        size="sm"
                        key={ds.dataset_id}
                        className="pt-0 pb-0 ps-1 pe-1"
                        variant="outline-dark"
                        style={{ cursor: "pointer" }}
                        onClick={() => ViewDataset(ds ? ds.title_abbr : "Error")}
                      >
                        {ds ? ds.title : "Error"}
                      </Button>
                    )
                  })
                }
              </Col>
            </Row>

            <Row className='mt-1'>
              <Col xs={3} sm={3} md={6} lg={2} xl={2}>
                <strong>Tags:</strong>
              </Col>
              <Col xs={9} sm={9} md={6} lg={10} xl={10} className='d-flex justify-content-start'>
                {
                  data_viewing[versionDisplay - 1]?.tag?.split(",")?.map((t, index) => {
                    return (<Badge className="me-2 mt-1 ps-2 pe-2" key={t + "_" + index} bg="dark">{t}</Badge>)
                  })
                }
              </Col>
            </Row>
          </div >
        </>
      )
    } else {
      return (<><div>There is problem on retrieving data details</div></>)
    }
  }

  return (
    <>
      <HeaderNew
        isLoading={isLoading}
        CartCount={cart ? cart.length : 0}
        loggedInUser={loggedInUser}
        setLoggedInUser={setLoggedInUser}
        dataset={location.state ? location.state.dataset : dsdInfo?.dataset}
        data={location.state ? location.state.data : dsdInfo?.data}
        keyword={keyword}
        setKeyword={setKeyword}
      />
      {
        (!!dsdInfo?.dataset === true && !!dsdInfo?.dataVersioned === true) ||
          (!!location.state?.dataset === true && !!location.state?.dataVersioned === true)
          ? <>
            <Row className='justify-content-center'>
              <Col className='mt-4' xs={10} sm={10} md={10} lg={8} xl={7}>
                <DataInfo
                  isAdminPortal={isAdminPortal}
                  dsdInfo={dsdInfo}
                  dataDict={dataDict}
                  loggedInUser={loggedInUser}
                  updateCart={updateCart}
                  cart={cart}
                  versionDisplay={versionDisplay}
                  setVersionDisplay={setVersionDisplay}
                  isLoading={isLoading}
                />
              </Col>
            </Row>

            {/* Data content display */}
            <Row className='justify-content-center'>
              <Col className='p-0' xs={10} sm={10} md={10} lg={8} xl={7}>
                {(loggedInUser === null || loggedInUser === "" || !!loggedInUser === false) && !!accessTokenResult?.login //User not login
                  ? <>
                    <div className="d-flex justify-content-center">
                      <Card className='info-display-card'>
                        <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                          <Stack >
                            <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} />
                            <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-4 mb-3' children={accessTokenResult?.message}></ReactMarkdown>
                          </Stack>
                          <Button className="om-button black-gradient-hover-effect" variant='dark' onClick={handleReLogin}>Login</Button>
                        </Card.Body>
                      </Card>
                    </div></>
                  :
                  !isLoading && !data?.status && data?.data === false //Query timeout or query error
                    ? <>
                      <div className="d-flex justify-content-center">
                        <Card className='info-display-card'>
                          <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                            <Stack>
                              <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} />
                              <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>There is problem on the query or query timeout </ReactMarkdown>
                              <Row className="d-flex justify-content-center">
                                <Col>
                                  <Button variant='dark' className='om-button' onClick={handleGoBack}>Go back</Button>
                                </Col>
                              </Row>
                            </Stack>
                          </Card.Body>
                        </Card>
                      </div>
                    </>
                    : !isLoading && accessTokenResult?.status && !accessTokenResult.token //Session timeout
                      ? <>
                        <div className="d-flex justify-content-center">
                          <Card className='info-display-card'>
                            <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                              <Stack>
                                <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} />
                                <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2' children={accessTokenResult.message}></ReactMarkdown>
                              </Stack>
                              <Button className="om-button black-gradient-hover-effect" variant='dark' onClick={handleReLogin}>Login</Button>
                            </Card.Body>
                          </Card>
                        </div>
                      </>
                      //: !isLoading && dataDict?.status && !dataDict?.permission 
                      : !isLoading && dataDict?.status && loggedInUser && !dsdInfo?.dataVersioned[versionDisplay - 1]?.allowed_access && !isAdminPortal
                        ? <>
                          <div className="d-flex justify-content-center">
                            <Card className='info-display-card'>
                              <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                                <Stack>
                                  <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} />
                                  {/*<ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>{dataDict.message}</ReactMarkdown>*/}
                                  <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>{dsdInfo?.dataVersioned[versionDisplay - 1].message}</ReactMarkdown>
                                  <Button variant='link' href='mailto:support@openmortality.org' style={{ textDecoration: "none" }}>
                                    <FontAwesomeIcon size="lg" icon={icon({ name: 'envelope', style: "regular" })} /> support@openmortality.org
                                  </Button>
                                </Stack>
                              </Card.Body>
                            </Card>
                          </div>
                        </>
                        //: !isLoading && dataDict?.token && dataDict?.permission && data?.data?.length === 0
                        : !isLoading && data?.data?.length === 0 && loggedInUser && dsdInfo?.dataVersioned[versionDisplay - 1]?.allowed_access
                          ? <>
                            <div className="d-flex justify-content-center">
                              <Card className='info-display-card'>
                                <Card.Body className="d-flex flex-column align-items-center justify-content-center mt-1 mb-1">
                                  <Stack>
                                    <FontAwesomeIcon size="2x" icon={icon({ name: 'circle-info' })} />
                                    <ReactMarkdown remarkPlugins={[remarkGfm]} className='mt-2'>No record found for the query</ReactMarkdown>
                                    <Row className="d-flex justify-content-center">
                                      <Col>
                                        <Button variant='dark' className='om-button' onClick={handleGoBack}>Go back</Button>
                                      </Col>
                                    </Row>
                                  </Stack>
                                </Card.Body>
                              </Card>
                            </div>
                          </>
                          : <>
                            <Tabs
                              defaultActiveKey="preview"
                              id="uncontrolled-data-detail-tab"
                              className="m-1"
                              mountOnEnter={true}
                              animation="true"
                              unmountOnExit={true}
                            >
                              <Tab eventKey="preview" title="Preview">
                                <DisplayDataPreview
                                  jsonData={data}
                                  dataDict={dataDict}
                                  query={query}
                                  setQuery={setQuery}
                                  queryString={queryString}
                                  setIsCaseSensitive={setIsCaseSensitive}
                                  isCaseSensitive={isCaseSensitive}
                                />
                              </Tab>
                              <Tab eventKey="dictionary" title="Dictionary">
                                {isLoading ? (
                                  <Placeholder as="div" animation="glow">
                                    <Placeholder xs={12} />
                                  </Placeholder>
                                ) : (
                                  <>
                                    <Displaydatadict dataDict={dataDict} />
                                  </>
                                )}
                              </Tab>

                              {
                                Object.keys(geoJsonData).includes("data")
                                  ? (<Tab className='m-2' eventKey="map" title="Map">
                                    <DisplayMapDataPreview geoJSONData={geoJsonData} hasInvalidSpatialRecord={hasInvalidSpatialRecord} />
                                  </Tab>)
                                  : <></>
                              }

                              <Tab eventKey="details" title="Details">
                                {isLoading ? (
                                  <Placeholder as="div" animation="glow">
                                    <Placeholder xs={12} />
                                  </Placeholder>
                                ) : (
                                  <>
                                    <DataDetails
                                      dataset={!!location.state?.dataset ? location.state?.dataset : dsdInfo?.dataset}
                                      data_viewing={!!location.state?.dataVersioned ? location.state?.dataVersioned : dsdInfo?.dataVersioned}
                                      versionDisplay={versionDisplay}
                                    />
                                  </>
                                )}
                              </Tab>
                            </Tabs>
                          </>
                }
              </Col>
            </Row >

          </>
          //Issue with loading of content / user not logged in
          : <>
          </>
      }
    </>
  )
}

export default ViewDatasetDetails