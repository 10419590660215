import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';

import { Stack, Button, Col, Row, Form, Spinner } from 'react-bootstrap';

import { KEYWORDS_REGEX } from '../constants/regex';

import { DATA_DOWNLOAD } from '../constants/omp_ui_constant'

import { useCustomNavigate } from '../hooks/useCustomNavigate';

import HeaderNew from "../components/HeaderNew";
import downloadjs from "downloadjs";

import { api_config } from "../components/api_url";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

const DownloadCart = () => {

  const navigate = useCustomNavigate();
  const location = useLocation();

  const api_url = api_config.url.API_URL
  let arr_data_download = [];

  //let localCart = localStorage.getItem("cart");
  let localCart
  let [cart, setCart] = useState([]);

  const [downloadResult, setdownloadResult] = useState("");
  const [validated, setValidated] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [loggedInUser, setLoggedInUser] = useState();
  const prevDownloadInput = useRef("")
  const [isDownloading, setIsdownloading] = useState(localStorage.getItem('isDownloading') === 'true' ? true : false);



  const [form, setForm] = useState({
    'purpose': localStorage.getItem('purpose') || "",
    'keywords': localStorage.getItem('keywords') || ""
  });
  const [errors, setErrors] = useState({});
  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })

    //localStorage.setItem(field, value)

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null
      })
  }

  //Set metadata
  useEffect(() => {
    //Include Google Analytics Tag
    const trackingID = "G-4NXP18LQPT"; // Replace with your actual Tracking ID
    // Google Analytics tracking code
    const head = document.querySelector("head");
    const script1 = document.createElement("script");
    script1.async = true;
    script1.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-4NXP18LQPT');
    head.appendChild(script1);

    const script2 = document.createElement("script");
    script2.innerText =
      `window.dataLayer = window.dataLayer || [];\
       function gtag(){dataLayer.push(arguments);}\
       gtag('js', new Date()); \
       gtag('config', '${trackingID}');\
       gtag('event', 'page_view', { page_path: '${window.location.pathname}' });`
    head.appendChild(script2);

    // Update the document's meta tags when the component mounts
    document.title = 'Open Mortality | Download Cart';
    document.querySelector('meta[name="description"]').setAttribute('content', 'Open Mortality - Download Cart');

    // Clean up the meta tag modifications when the component unmounts
    return () => {
      document.title = '';
      document.querySelector('meta[name="description"]').setAttribute('content', '');
      head.removeChild(script1);
      head.removeChild(script2);
    };
  }, []);

  const handleCartItem = (item) => {
    //create a copy of our cart state, avoid overwritting existing state
    let cartCopy = [...cart];

    //assuming we have an ID field in our item
    let { ID } = item;

    //look for item in cart array
    let existingItem = cartCopy.find(cartItem => cartItem.ID === ID);

    //if item already exists
    if (existingItem) {
      cartCopy.splice(cartCopy.indexOf(existingItem), 1);
      toast(<div className="text-center align-items-center"><FontAwesomeIcon size="1x" icon={icon({ name: 'circle-minus' })} />
        <span> Removed <strong>{existingItem.supp_info.data_name}</strong></span></div>)
    } else { //if item doesn't exist, simply add it
      cartCopy.push(item)
      toast(<div className="text-center align-items-center"><FontAwesomeIcon size="1x" icon={icon({ name: 'circle-plus' })} />
        <span> Added <strong>{item.supp_info.data_name}</strong> </span></div>)
    }
    //update app state
    setCart(cartCopy)
    //make cart a string and store in local space
    let stringCart = JSON.stringify(cartCopy);
    localStorage.setItem("cart", stringCart)
  }

  const validateForm = () => {
    const { purpose, keywords } = form
    const newErrors = {}
    if (!purpose || purpose === '') newErrors.purpose = 'Please enter research purpose'

    if (!keywords || keywords === '') newErrors.keywords = 'Please enter some keywords about your research'
    else if (!KEYWORDS_REGEX.test(form.keywords)) newErrors['keywords'] = 'Please input at least 5 keywords separated by space'

    return newErrors
  }

  const refreshToken = () => {
    return new Promise(async (resolve, reject) => {
      fetch(api_url + "/user/refresh", {
        method: "GET",
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then((res) => res.json())
        .then((res_json) => {
          resolve(res_json)
        });
    })
  }

  //Get User
  useEffect(() => {
    const getLoggedInUser = async () => {
      const user = await getUser()
      setLoggedInUser(user)
    }

    if (!loggedInUser) getLoggedInUser()
  }, [])

  useEffect(() => {
    //Check load status every 2 second      
    if (localStorage.getItem('isDownloading') === "true") {
      const intervalId = setInterval(() => {
        if (localStorage.getItem('isDownloading') === "false" || localStorage.getItem('isDownloading') === null) {
          setdownloadResult({ message: "The file preparation has finished, and you can now begin another download." })
          clearInterval(intervalId);
          //setIsLoading(false)
          setIsdownloading(false)
        }
      }, DATA_DOWNLOAD.REFRESH_INTERVAL); //2 seconds
    }

    /*if (isLoading === false && localStorage.getItem('isLoading') === "true") {
      const intervalId = setInterval(() => {
        if (localStorage.getItem('isLoading') === 'false' || localStorage.getItem('isLoading') === null) {
          setdownloadResult({ message: "The file preparation has finished, and you can now begin another download." })
          clearInterval(intervalId);
          setIsLoading(false)
        }
      }, DATA_DOWNLOAD.REFRESH_INTERVAL); //2 seconds
    }*/
  }, [isDownloading]);


  useEffect(() => {
    //Get download status from localstorage    
    localStorage.getItem('isDownloading') === "true" ? setIsdownloading(true) : setIsdownloading(false)

    if (localStorage.getItem('isDownloading') === "true") {
      setdownloadResult({ message: "The file(s) from a previous download request are still being prepared. Kindly check your browser for the download prompt." })
    }
  }, []);

  useEffect(() => {
    localCart = localStorage.getItem("cart");

    if (localCart) {
      //turn it into js
      localCart = JSON.parse(localCart);
      setCart(localCart)
    }

  }, [])

  useEffect(() => {
    const savedDownloadInput = localStorage.getItem('downloadInput');
    if (savedDownloadInput) {
      setForm(JSON.parse(savedDownloadInput));
      prevDownloadInput.current = JSON.parse(savedDownloadInput);

    }
  }, [])

  useEffect(() => {
    if (form !== prevDownloadInput.current) {
      localStorage.setItem('downloadInput', JSON.stringify(form));
      prevDownloadInput.current = form;
    }
  }, [form]);

  //get login user
  const getUser = () => {
    return new Promise((resolve, reject) => {
      try {
        fetch(api_url + "/user/getUser", {
          method: 'GET',
          credentials: 'include'
        })
          .then((res) => res.text()) //return res.text for getUser() to avoid JSON parsing error; the passport.js return null when there is no user in session
          .then((res_json) => {
            if (res_json.length > 0) {
              resolve(JSON.parse(res_json)) //If there is user, parse the response to JSON
            } else {
              resolve(null)
            }
          })
          .catch((err) => {
            reject(err)
          });
      }
      catch (err) {
        reject(err)
      }
    })
  }

  const handleFileDownload = async (event) => {

    const formsErrors = validateForm()

    if (Object.keys(formsErrors).length > 0) {
      setErrors(formsErrors)
    }
    else if (!loggedInUser) {
      setdownloadResult({ message: "Please login to download file" })
    }
    else if (!cart.length > 0) {
      setdownloadResult({ message: "No file in download cart" })
    }
    else {
      setValidated(true);
      let filename = "";

      for (const downloadfile of cart) {
        if (downloadfile.supp_info.format.split(",").includes("csv")) {
          arr_data_download.push({ id: downloadfile.ID, file: downloadfile.supp_info.data_name + ".csv" })
        }
        if (downloadfile.supp_info.format.split(",").includes("geojson")) {
          arr_data_download.push({ id: downloadfile.ID, file: downloadfile.supp_info.data_name + ".geojson" })
        }
        arr_data_download.push({ id: downloadfile.ID, file: downloadfile.supp_info.data_name + "_datadict.csv" })
      }

      //Send download request
      return new Promise(async (resolve, reject) => {
        const refreshTokenResult = await refreshToken();
        localStorage.setItem('isDownloading', "true");
        setdownloadResult({ message: "Preparing the file(s)" })
        //setIsLoading(true)
        setIsdownloading(true)
        //Clear cart and textarea
        resetCart()
        let contentType;
        fetch(api_url + "/data/download2", {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': "application/json",
            Authorization: "Bearer " + refreshTokenResult.token
          },
          //responsetype: 'blob',
          body: JSON.stringify({
            downloadList: arr_data_download,
            downloadInfo: { email: loggedInUser.email, purpose: form.purpose, keywords: form.keywords },
            download_id: uuidv4()
          })
        })
          .then((response) => {
            contentType = response.headers.get("Content-Type");
            if ((contentType) && (contentType.split(";")[0] === "application/json")) {
              return response.json()
            }
            else if (contentType === "application/zip") {
              filename = response.headers.get('Content-Disposition').split('filename=')[1].replaceAll('"', '');
              return response.blob()
            }
          })
          .then((result) => {
            if ((contentType) && (contentType.split(";")[0] === "application/json")) {
              resolve(result)
              setdownloadResult(result)
              setIsdownloading(false)
              localStorage.setItem('isDownloading', false);
              setValidated(false)
            }
            else if (contentType === "application/zip") {
              downloadjs(result, filename, "application/zip"); // Using downloadjs library                

              //Handling the file download without using downloadjs library
              //const url = window.URL.createObjectURL(result);
              //const a = document.createElement('a');
              //a.href = url
              //a.download = filename;
              //a.click();

              setIsdownloading(false)
              localStorage.clear()
              setdownloadResult({ message: "Your files are ready. You have been prompted to download them as a single zip file." })
              resolve({ message: "Your files are ready. You have been prompted to download them as a single zip file." })
            }
          })
      });
    }
  }

  const handleViewData = (data) => {
    navigate("/data/" + data.supp_info.data_name + "?pageSize=10&climit=5&page=1", {
      state: {
        dataset: location.state ? location.state.dataset : "",
        data: data.supp_info,
        user: location.state ? location.state.user : JSON.parse(localStorage.getItem("user")),
        dataVerioned: location.state?.dataVerioned,
        versionDisplay: data.supp_info.data_version_display
      },
    })
  }

  const handleGoback = () => {
    navigate(-1, {
      state: {
        dataset: location.state ? location.state.dataset : "",
        data: location.state ? location.state.data : "",
        user: location.state ? location.state.user : JSON.parse(localStorage.getItem("user"))
      },
    })
  }

  const updateSearchKeyword = (keyword) => {
    setKeyword(keyword)
  }

  const resetCart = () => {

    //clear local cart
    setCart("")
    //Remove the cart storage in local storage
    localStorage.removeItem("cart")

    //Clear form and object
    setForm({ purpose: undefined, keywords: undefined });
    localStorage.removeItem('downloadInput')

    //Remove form validation
    setValidated(false);
  }

  return (
    <>
      <HeaderNew
        isLoading={false}
        CartCount={cart?.length || 0}
        loggedInUser={loggedInUser}
        setLoggedInUser={setLoggedInUser}
        dataset={null}
        data={null}
        keyword={keyword}
        setKeyword={setKeyword}
      />

      <Row className="mt-5 pt-2 justify-content-center" >

        <Col xs={10} sm={9} md={9} lg={8} xl={10} >

          <h3 className="d-flex justify-content-start m-0 p-2">{cart ? cart.length : 0} item(s) in download cart</h3>
          {

            cart && cart.length > 0
              ? <>
                <div className="p-2 align-items-center justify-content-center" style={{ width: "100%" }}>
                  {
                    cart.map((cartitem, index) => {
                      return (
                        <>
                          <Row key={uuidv4()}>
                            {/*<Col>*/}
                            {/*<Row >*/}
                            <Col xs={12} md={12} lg={12} className="m-0 p-0 ">
                              <Button className="m-0 d-flex justify-content-start om-link" variant="link" onClick={() => handleViewData(cartitem)} style={{ textDecoration: "none" }}>
                                <strong>{index + 1 + "."} {cartitem.supp_info.title ? cartitem.supp_info.title : cartitem.supp_info.data_name}</strong>
                              </Button>
                            </Col>
                            {/*</Row>*/}

                            {/*<Row >*/}
                            <Col xs={10} md={6} lg={5}>
                              <Stack direction="vertical" gap={1} >

                                <div className="text-start" style={{ wordBreak: "break-word", overflowY: "hidden", textOverflow: 'ellipsis' }}>
                                  {
                                    //cartitem.supp_info.data_name + ".csv"

                                    cartitem.supp_info.format.split(",").includes("csv")
                                      ? <><div className="text-start"
                                        style={{ wordBreak: "break-word", overflowY: "hidden", textOverflow: 'ellipsis' }} >{cartitem.supp_info.data_name + ".csv"}
                                      </div>

                                      </>
                                      : <></>
                                  }
                                  {
                                    cartitem.supp_info.format.split(",").includes("geojson")
                                      ? <>
                                        <div className="text-start"
                                          style={{ wordBreak: "break-word", overflowY: "hidden", textOverflow: 'ellipsis' }} >{cartitem.supp_info.data_name + ".geojson"}</div>
                                      </>
                                      : <></>
                                  }
                                  <div className="text-start" style={{ wordBreak: "break-word", overflowY: "hidden", textOverflow: 'ellipsis' }} >{cartitem.supp_info.data_name + "_datadict.csv"}</div>
                                </div>

                              </Stack>

                            </Col>

                            <Col xs={2} md={1} lg={1} className="d-flex justify-content-center">
                              <FontAwesomeIcon onClick={() => handleCartItem(cartitem)} style={{ cursor: "pointer" }} className='' size="lg" icon={icon({ name: 'trash-can' })} />
                            </Col>
                            {/*</Row>*/}
                            {/*</Col>*/}
                          </Row >
                        </>
                      )
                    })
                  }
                </div>
              </>
              : <></>
          }

          <Stack className="p-0 mt-3 ms-0 mb-0 d-flex justify-content-start" direction="vertical" gap={3}>
            {/*<textarea className="m-0 p-2" defaultValue="What is the purpose of downloading the data?" 
            onChange={(e) => handlePurposeChange(e.target.value)} style={{ height: '120px' }}></textarea>
          <textarea className="m-0 p-2" defaultValue="Research Keywords"  onChange={(e) => handleKeywordsChange(e.target.value)} style={{ height: '120px' }}></textarea>*/}
            <Form validated={validated} noValidate>
              <Form.Group as={Col} md="12" controlId="purpose">
                <Form.Label column="md" className="col-auto d-flex align-content-start " style={{ fontWeight: "bold" }} >Research Purpose</Form.Label>
                <Form.Control
                  required
                  name="purpose"
                  as="textarea"
                  type="text"
                  placeholder="Brief description on the purpose of the data in your research"
                  value={form.purpose || ""}
                  isInvalid={!!errors.purpose}
                  onChange={(e) => setField('purpose', e.target.value)}
                  disabled={isDownloading}
                  rows={4}
                />
                <Form.Control.Feedback type="invalid">{errors.purpose}</Form.Control.Feedback>

              </Form.Group>
              <Form.Group as={Col} md="12" controlId="keywords">
                <Form.Label column="md" className="col-auto d-flex align-content-start" style={{ fontWeight: "bold" }}>Research Keywords</Form.Label>
                <Form.Control
                  required
                  name="keywords"
                  as="textarea"
                  type="text"
                  placeholder="Set of Keywords for your research separated by commas"
                  value={form.keywords || ""}
                  isInvalid={!!errors.keywords}
                  onChange={(e) => setField('keywords', e.target.value)}
                  disabled={isDownloading}
                  rows={4}
                />
                <Form.Control.Feedback type="invalid">{errors.keywords}</Form.Control.Feedback>
              </Form.Group>
            </Form>
          </Stack>

          <Form.Group as={Row} className="mt-2 justify-content-center">
            <Col xs={11} sm={11} md={5} lg={5} xl={5} className="m-1 ps-1 pe-1 d-flex justify-content-center justify-content-md-end">
              <Button className="om-button black-gradient-hover-effect" variant="dark" id="button-browse" onClick={() => handleGoback()} >
                {isDownloading ? "Back" : "Browse more data"}
              </Button>
              {/*<Button className="om-button black-gradient-hover-effect" variant="dark" id="button-browse" disabled={isLoading} onClick={() => handleGoback()} >Browse more data</Button>*/}
            </Col>

            <Col xs={11} sm={11} md={5} lg={5} xl={5} className="m-1 ps-1 pe-1 d-flex justify-content-center justify-content-md-start">
              <Button className="om-button black-gradient-hover-effect" variant="dark" id="button-download-cart" disabled={isDownloading || !cart || cart.length <= 0} onClick={() => handleFileDownload()} >Download</Button>
              {/*<Button className="om-button black-gradient-hover-effect" variant="dark" id="button-download-cart" disabled={isLoading || !!(!cart)} onClick={() => handleFileDownload()} >Download</Button>*/}
            </Col>
          </Form.Group>

          {
            isDownloading === true
              ? <>
                <div className="d-flex align-items-center justify-content-center text-center m-3">
                  <span>
                    <Spinner size="sm" animation="border" role="">
                      <span className="visually-hidden">{downloadResult.message}</span>
                    </Spinner> {downloadResult.message}
                  </span>
                </div>
              </>
              : <>
                <div className="mt-3 text-center">
                  <span>{downloadResult.message}</span>
                </div>
              </>
          }


        </Col>
      </Row >
    </>
  )
}

export default DownloadCart